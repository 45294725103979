import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import PaymentIcon from "@material-ui/icons/Payment";
import ExitIcon from "@material-ui/icons/ExitToApp";
import LogOut from "../../utils/LogOut";

export const sideMenuUserDetails = context => (
  <div>
    <img
      alt={""}
      style={{
        width: 100,
        height: 100,
        marginTop: 10,
        borderRadius: 60,
        zIndex: 999,
        left: 41,
        position: "relative"
      }}
      src={
        context.state.producersImages !== null &&
        context.state.producersImages !== undefined
          ? typeof context.state.producersImages === "object"
            ? context.state.producersImages !== undefined &&
              context.state.producersImages.length >
                context.state.selectedProducer
              ? context.state.producersImages[context.state.selectedProducer]
              : ""
            : context.state.producersImages !== undefined
            ? context.state.producersImages
            : ""
          : ""
      }
    />

    <div style={{ marginLeft: 24 }}>
      <p>{context.state.userName}</p>
      <p>{context.state.userEmail}</p>
    </div>
  </div>
);

export const sideMenuNavigationList = (
  context,
  onPushOrdersScreen,
  onPushPaymentsScreen,
  onPushQuestionsScreen
) => (
  <div>
    <ListItem button>
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText
        onClick={() => onPushOrdersScreen(context)}
        primary="Поръчки"
      />
    </ListItem>

    <ListItem button>
      <ListItemIcon>
        <PaymentIcon />
      </ListItemIcon>
      <ListItemText
        onClick={() => onPushPaymentsScreen(context)}
        primary="Плащания"
      />
    </ListItem>

    <ListItem button>
      <ListItemIcon>
        <QuestionAnswerIcon />
      </ListItemIcon>
      <ListItemText
        onClick={() => onPushQuestionsScreen(context)}
        primary="Въпроси"
      />
    </ListItem>

    <ListItem button>
      <ListItemIcon>
        <ExitIcon />
      </ListItemIcon>
      <ListItemText onClick={() => LogOut.logOut(context)} primary="Изход" />
    </ListItem>
  </div>
);
