import React from "react";
import BulkEditProductActiveComponent from "./BulkEditProductActiveComponent";

export default class ProductsTableComponent extends React.Component {
  render() {
    const { state, onSave } = this.props;

    return state.bulkdDataSource !== undefined ? (
      state.bulkdDataSource.map(item => (
        <div
          key={item.id}
          style={{
            flex: 1,
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            margin: 2
          }}
        >
          <BulkEditProductActiveComponent
            state={state}
            item={item}
            checked={item.is_published}
            onSave={onSave}
          />
        </div>
      ))
    ) : (
      <div />
    );
  }
}
