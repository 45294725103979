import React from "react";

import {
  accessToken,
  unauthenticated,
  message,
  favIconLink,
  answers,
  questionsUrl
} from "../Constants";
import NetworkConfig from "../config/NetworkConfig";
import { read_cookie } from "sfcookies";
import AuthUtil from "../utils/AuthUtil";
import Favicon from "react-favicon";
import Hidden from "@material-ui/core/Hidden/Hidden";
import NavBarComponent from "../components/NavBarComponent";
import PermamantDrawerComponent from "../components/PermamantDrawerComponent";
import SaveIcon from "@material-ui/icons/Save";
import AnswersComponent from "../components/AnswersComponent";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import StateSaverUtil from "../utils/StateSaverUtil";
import BackButtonHandler from "../utils/BackButtonHandler";
import { store } from "../store";

export default class AnswersForQuestionsScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectFromList: this.props.location.state
        ? this.props.location.state.redirectFromList
        : undefined,
      isLoaded: false,
      questionId: this.props.location.state
        ? this.props.location.state.questionId
        : undefined,
      answersData: [],
      selectedProducer: store.getState().selectedProducer,
      producersImages: store.getState().producersImages,
      userName: store.getState().userName,
      userEmail: store.getState().userEmail,

      producerId: store.getState().producerId,

      navBarTitle: this.props.location.state
        ? this.props.location.state.navBarTitle
        : undefined,

      product_id: this.props.location.state
        ? this.props.location.state.product_id
        : undefined,
      productName: this.props.location.state
        ? this.props.location.state.productName
        : undefined,
      producerPercent:
        store.getState() !== null &&
        store.getState().producersAllData !== undefined &&
        store.getState().producersAllData[store.getState().selectedProducer] !==
          undefined
          ? store.getState().producersAllData[store.getState().selectedProducer]
              .percent
          : null,
      producersAllData: store.getState().producersAllData
    };

    AuthUtil.redirectUnauthenticated();
    this.propsToRestore = [
      "questionId",
      "navBarTitle",
      "product_id",
      "productName",
      "redirectFromList"
    ];

    this.getData = this.getData.bind(this);
    this.onSendAnswer = this.onSendAnswer.bind(this);
    this.onBackArrowClicked = this.onBackArrowClicked.bind(this);
    this.openDrawer = this.openDrawer.bind(this);
  }

  componentDidMount() {
    BackButtonHandler.handle(this, this.onBackArrowClicked);
    StateSaverUtil.save(this, this.propsToRestore);

    this.getData();
  }

  componentWillUnmount() {
    StateSaverUtil.deleteSavedProps(this, this.propsToRestore);
  }

  async getData() {
    await StateSaverUtil.restoreState(this, this.propsToRestore);
    this.getAnswers();
  }

  onBackArrowClicked() {
    this.setState({
      localRedirectBack: true
    });
  }

  async getAnswers() {
    let that = this;
    try {
      const value = read_cookie(accessToken);

      var answersUrl =
        questionsUrl + "/" + that.state.questionId + "/" + answers;

      console.log("requesting ", answersUrl);
      return fetch(answersUrl, {
        headers: {
          Accept: "application/json",
          Authorization: value
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log("response json answers ", responseJson);

          if (
            responseJson.hasOwnProperty(message) &&
            responseJson.message === unauthenticated
          ) {
            NetworkConfig.refreshRequest(this.getAnswers, that);
          } else {
            console.log("responseJson ", responseJson);
            that.setState({
              isLoaded: true,
              answersData: responseJson
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    } catch (e) {}
  }

  openDrawer() {
    console.log("old state ", this.state.isDrawerOpen);

    var isDrawerOpen = false;
    if (this.state.isDrawerOpen === false) {
      isDrawerOpen = true;
    } else {
      isDrawerOpen = false;
    }

    this.setState({
      isDrawerOpen: isDrawerOpen
    });

    console.log("toggle drawer; now should be ", this.state.isDrawerOpen);
  }

  isValid() {
    if (this.state.answer === undefined || this.state.answer === "") {
      this.setState({
        isSnackBarOpen: true,
        snackBarMessage: "Въведете отговор!"
      });
      return false;
    }

    return true;
  }

  onSendAnswer() {
    let that = this;

    if (this.isValid()) {
      try {
        const value = read_cookie(accessToken);

        let data = {
          method: "POST",
          body: JSON.stringify({
            description: this.state.answer
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: value
          }
        };
        let answersUrl =
          questionsUrl + "/" + that.state.questionId + "/" + answers;

        console.log("post request to ", answersUrl, " for answer sent ", data);

        return fetch(answersUrl, data)
          .then(response => response.json())
          .then(responseJson => {
            console.log(responseJson);

            if (
              responseJson.hasOwnProperty(message) &&
              responseJson.message === unauthenticated
            ) {
              console.log("refresh request");
              NetworkConfig.refreshRequest(this.onSendAnswer, that);
            } else {
              this.getAnswers();
              this.setState({
                isSnackBarOpen: true,
                snackBarMessage: "Добавено успешно!",
                answer: ""
              });
            }
          });
      } catch (e) {}
    }
  }

  render() {
    let context = this;

    return (
      <div>
        <Favicon url={favIconLink} />
        <Hidden smDown implementation="css">
          <div style={{ marginLeft: 210 }}>
            <NavBarComponent
              onClickAction={this.onSaveItem}
              onClickText={"Запази"}
              title={"Отговори"}
              onBackArrowClicked={this.onBackArrowClicked}
              isSideMenuNeeded={false}
              actionIcon={<SaveIcon />}
            />
          </div>
        </Hidden>

        <Hidden mdUp implementation="css">
          <div style={{ marginLeft: 0 }}>
            <NavBarComponent
              onClickAction={this.onSaveItem}
              title={"Отговори"}
              onBackArrowClicked={this.onBackArrowClicked}
              isSideMenuNeeded={true}
              onSideDrawerOpen={this.openDrawer}
            />
          </div>
        </Hidden>

        {!this.state.isLoaded && <LinearProgress />}

        <PermamantDrawerComponent context={context} />

        <Hidden smDown implementation="css">
          <div style={{ marginLeft: 230 }}>
            <AnswersComponent context={this} onSendAnswer={this.onSendAnswer} />
          </div>
        </Hidden>

        <Hidden mdUp implementation="css">
          <div style={{ marginLeft: 20 }}>
            <AnswersComponent context={this} onSendAnswer={this.onSendAnswer} />
          </div>
        </Hidden>
      </div>
    );
  }
}
