import React from "react";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { store } from "../store";
import setUser from "./redux/actions/setUser";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 160
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  }
});

export default class PaymentsProducerDropDownComponent extends React.Component {
  getProducerId(value, allProducers) {
    for (var i = 0; i < allProducers.length; i++) {
      if (allProducers[i].name === value) {
        return allProducers[i].id;
      }
    }
    return 1;
  }

  getProducerPercent(value, allProducers) {
    for (var i = 0; i < allProducers.length; i++) {
      if (allProducers[i].name === value) {
        return allProducers[i].percent;
      }
    }
    return 1;
  }

  getProducerIndex(value, allProducers) {
    for (var i = 0; i < allProducers.length; i++) {
      if (allProducers[i].name === value) {
        return i;
      }
    }
    return 1;
  }

  handleProducerSelector = event => {
    const { context } = this.props;

    //set years to empty array to manually update producer
    context.setState(
      {
        loadYears: true
      },
      () => {
        console.log(
          "event",
          this.getProducerIndex(
            event.target.value,
            context.state.producersAllData
          )
        );
        if (context.state.selectedProducer !== undefined) {
        }
        const user = {
          selectedProducer: this.getProducerIndex(
            event.target.value,
            store.getState().producersAllData
          ),
          producerId: this.getProducerId(
            event.target.value,
            store.getState().producersAllData
          ),
          producerPercent: this.getProducerPercent(
              event.target.value,
              context.state.producersAllData
          ),
          selectedProducerName: event.target.value
        };

        store.dispatch(setUser(user));
        context.setState(user, () => this.props.getData());
      }
    );
  };

  render() {
    const { context } = this.props;

    let allProducers;
    try {
      allProducers = context.state.producersAllData.map((s, i) => {
        let producerName = s.name;

        if (producerName !== undefined) {
          return (
            <MenuItem key={s.id} value={producerName} id={s.id}>
              {producerName}
            </MenuItem>
          );
        } else {
          return (
            <MenuItem key={1} value={"producer"} id={1}>
              Производител1
            </MenuItem>
          );
        }
      });

      if (allProducers.length === 0) {
        return (
          <MenuItem key={1} value={"producer"} id={1}>
            Производител2
          </MenuItem>
        );
      }
    } catch (e) {
      allProducers = (
        <MenuItem key={1} value={"producer"} id={1}>
          Производител3
        </MenuItem>
      );
    }

    return (
      <FormControl
        style={{ display: "inline-block", marginTop: 20, marginBottom: 15 }}
      >
        <InputLabel htmlFor="age-required">Производител</InputLabel>
        <Select
          value={
            context.state.selectedProducer === undefined
              ? context.state.producersAllData === undefined
                ? "Producer"
                : context.state.producersAllData[0].name
              : this.props.value == null
              ? "Prod"
              : context.state.producersAllData[this.props.value].name
          }
          displayEmpty
          onChange={this.handleProducerSelector}
          name="producer"
          inputProps={{
            name: "selectedProducer"
          }}
        >
          {allProducers}
        </Select>
      </FormControl>
    );
  }
}
