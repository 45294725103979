import React from "react";
import { withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField/TextField";
import SendIcon from "@material-ui/icons/Send";
import Grid from "@material-ui/core/Grid/Grid";
import SnackBarComponent from "./SnackBarComponent";
import BackRedirectComponent from "./redirects/BackRedirectComponent";
import LogOutRedirectComponent from "./redirects/LogOutRedirectComponent";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";

function answersComponent(props) {
  const { context, classes, onSendAnswer } = props;

  return (
    <div>
      <SnackBarComponent context={context} />

      <div className={classes.root}>
        <BackRedirectComponent
          context={context}
          whereTo={context.state.redirectFromList}
          state={{
            product_id: context.state.product_id,
            selectedProducer: context.state.selectedProducer,
            producersImages: context.state.producersImages,
            userName: context.state.userName,
            userEmail: context.state.userEmail,
            producerId: context.state.producerId,
            productName: context.state.productName,
            producerPercent: context.state.producerPercent
          }}
        />

        <LogOutRedirectComponent context={context} />

        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <h3>{context.state.navBarTitle}</h3>

            <React.Fragment>
              {context.state.answersData.map(item => (
                <div style={{ marginLeft: 10 }} key={item.id}>
                  <div style={{ marginBottom: 20, marginTop: 10 }}>
                    <Grid container direction={"row"} justify="space-between">
                      <Grid item>
                        <body style={{ fontSize: 12, marginTop: 5 }}>
                          От: {item.user_name}
                        </body>
                      </Grid>
                      {item.is_from_producer && (
                        <Grid item>
                          <div
                            style={{
                              borderRadius: 25,
                              background: "#73AD21",
                              padding: "4px 10px"
                            }}
                          >
                            {" "}
                            <body style={{ fontSize: 12, color: "white" }}>
                              Производител
                            </body>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </div>

                  <div>
                    <p>{item.description}</p>
                  </div>

                  <hr
                    style={{
                      border: 0,
                      height: 1,
                      background: "#333",
                      backgroundImage:
                        "linear-gradient(to right, #ccc, #333, #ccc)"
                    }}
                  />
                </div>
              ))}

              <Grid item xs={12} style={{ marginLeft: 10, marginRight: 10 }}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-end"
                >
                  <Grid item xs={11}>
                    <FormHelperText htmlFor="answer-input">
                      Отоговорете
                    </FormHelperText>

                    <TextField
                      id="answer-input"
                      multiline
                      rowsMax="4"
                      value={context.state.answer}
                      onChange={event =>
                        context.setState({ answer: event.target.value })
                      }
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <IconButton
                      onClick={() => onSendAnswer()}
                      className={"close-button"}
                    >
                      <SendIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          </Paper>
        </main>
      </div>
    </div>
  );
}
const styles = theme => ({
  layout: {
    width: "auto",
    display: "block" // Fix IE11 issue.
  },
  paper: {
    marginTop: 20,
    marginRight: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    maxWidth: 600,
    minWidth: 250
  },
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {
    margin: theme.spacing.unit
  },
  textField: {
    flexBasis: 200
  }
});

export default withStyles(styles, { withTheme: true })(answersComponent);
