import React from "react";

import ImagesHorizontalComponent from "./ImagesHorizontalComponent";
import CategoriesDisplay from "./CategoriesDisplay";
import DropDownComponent from "./DropDownComponent";
import VariantionsTableComponent from "./VariantionsTableComponent";
import CategoriesChooserComponent from "./CatageoriesChooserComponent";
import EditDescriptionComponent from "./EditDescriptionComponent";
import ArrowForward from "@material-ui/icons/ArrowForwardIos";
import Grid from "@material-ui/core/Grid/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Input from "@material-ui/core/Input/Input";
import Button from "@material-ui/core/Button/Button";
import ImageGalleryFullScreenComponent from "./ImageGalleryFullScreenComponent";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import DescriptionTextComponent from "./DescriptionTextComponent";
import Favicon from "react-favicon";
import { favIconLink } from "../Constants";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PermamantDrawerComponent from "./PermamantDrawerComponent";
import QuestionsTextComponent from "./QuestionsTextComponent";
import PriceAutoCalculationComponent from "../components/PriceAutoCalculationComponent";
import SnackBarComponent from "./SnackBarComponent";
import { Redirect } from "react-router-dom";
import LogOutRedirectComponent from "./redirects/LogOutRedirectComponent";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import Switch from "@material-ui/core/Switch";
import Divider from "@material-ui/core/es/Divider/Divider";
import Typography from "@material-ui/core/es/Typography/Typography";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import EditIcon from "@material-ui/icons/Edit";

function createProduct(props) {
  const {
    classes,
    context,
    onPictureTaken,
    onImageChosenAction,
    onVariantDelete,
    onPictureDeleted,
    onPicturesReordered,
    onLongClickAction,
    onOpenCategories,
    onDescriptionOpen,
    onGalleryClose,
    onCategoriesDialogClose,
    onFilterSearch,
    onBulkSave,
    onDescriptionSave,
    onDescriptionClose,
    onPictureClicked,
    onOpenQuestions
  } = props;

  const styles = {
    list: {
      width: 210
    },
    fullList: {
      width: "auto"
    }
  };

  return (
    <div>
      <Favicon url={favIconLink} />

      {context.state.localRedirectBack ? (
        <Redirect
          to={{
            pathname: process.env.PUBLIC_URL + "/products"
          }}
        />
      ) : (
        <div />
      )}

      {context.state.localRedirectQuestions ? (
        <Redirect
          to={{
            pathname: process.env.PUBLIC_URL + "/products/questions",
            state: {
              product_id: context.state.itemId,
              productName: context.state.name
            }
          }}
        />
      ) : (
        <div />
      )}

      {(!context.state.isLoaded || !context.state.isProductLoaded) && (
        <LinearProgress />
      )}

      <Dialog
        open={context.state.isReorderDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Преместване"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Наистина ли желаете да преместите снимката отпред?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={context.state.closeReorderDialog} color="primary">
            Не
          </Button>

          <Button
            onClick={context.state.onReorderAction}
            color="primary"
            autoFocus
          >
            Да
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={context.state.isDeleteDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Изтриване"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Наистина ли желаете да изтриете снимката?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={context.state.closeDeleteDialog} color="primary">
            Не
          </Button>

          <Button
            onClick={context.state.onDeleteAction}
            color="primary"
            autoFocus
          >
            Да
          </Button>
        </DialogActions>
      </Dialog>

      <SnackBarComponent context={context} />

      <ImageGalleryFullScreenComponent
        context={context}
        onPicturesReordered={onPicturesReordered}
        onPictureDeleted={onPictureDeleted}
        onCloseAction={onGalleryClose}
      />

      <CategoriesChooserComponent
        context={context}
        onDialogClose={onCategoriesDialogClose}
        onFilterSearch={onFilterSearch}
        onBulkSave={onBulkSave}
      />

      <EditDescriptionComponent
        context={context}
        onDescriptionSave={onDescriptionSave}
        onDialogClose={onDescriptionClose}
      />

      <PermamantDrawerComponent context={context} />

      <LogOutRedirectComponent context={context} />

      <main className={classes.layout}>
        <Grid container spacing={24}>
          <Grid item xs={12} md={8}>
            <Paper className={classes.paper}>
              <Grid item xs={12}>
                {!context.state.isLoaded || !context.state.isProductLoaded ? (
                  <LinearProgress />
                ) : (
                  <div>
                    <FormHelperText htmlFor="product-name-input">
                      Име на продукт *
                    </FormHelperText>

                    <Input
                      id="product-name-input"
                      required
                      label="Име на продукт"
                      fullWidth
                      onChange={event => {
                        if (event.target.value.length <= 60) {
                          context.setState({ name: event.target.value });
                          console.log("name changed to ", event.target.value);
                        }
                      }}
                      value={context.state.name}
                      maxLength={60}
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                {context.state.isEditing && (
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid style={{ marginTop: 15 }} item>
                      <label>
                        <span
                          onClick={() => {
                            context.setState({
                              isProductPublished: Number(
                                !context.state.isProductPublished
                              )
                            });
                          }}
                        >
                          Публикуван ли е продукта
                        </span>
                      </label>
                    </Grid>
                    <Grid style={{ marginTop: 15 }} item>
                      <Switch
                        checked={context.state.isProductPublished === 1}
                        onChange={() => {
                          context.setState({
                            isProductPublished: Number(
                              !context.state.isProductPublished
                            )
                          });
                          console.log(
                            "product published status is now " +
                              context.state.isProductPublished
                          );
                        }}
                        value="Публикуван ли е продукта"
                        color="primary"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider variant="middle" className={classes.divider} />
                    </Grid>
                  </Grid>
                )}
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <DescriptionTextComponent
                      context={context}
                      onDescriptionOpen={onDescriptionOpen}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      className={classes.button}
                      aria-label="Edit"
                      onClick={onDescriptionOpen}
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            {context.state.isEditing && (
              <Paper className={classes.paper}>
                <Grid container spacing={24}>
                  <Grid item xs={12}>
                    <div
                      style={{
                        marginRight: 16
                      }}
                    >
                      <div
                        className={"rows"}
                        style={{ marginTop: 20, marginBottom: 10 }}
                      >
                        <h3>Снимки</h3>
                        <ImagesHorizontalComponent
                          context={context}
                          className={"row"}
                          style={{ marginBottom: 10 }}
                          onPictureTaken={onPictureTaken}
                          onClick={onPictureClicked}
                          onImageChosenAction={onImageChosenAction}
                          onPictureDeleted={onPictureDeleted}
                          onPicturesReordered={onPicturesReordered}
                          onLongClickAction={onLongClickAction}
                          state={context.state}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            )}
            <Paper className={classes.paper}>
              <Grid item xs={12}>
                <Grid container spacing={24}>
                  <Grid item xs={12}>
                    {!context.state.isLoaded ? (
                      <LinearProgress />
                    ) : (
                      <div>
                        <Typography variant="h6" gutterBottom>
                          Мерна еденица и разфасовка на продукт
                        </Typography>
                        <Divider variant="middle" className={classes.divider} />
                        <Grid
                          container
                          spacing={24}
                          style={{ paddingTop: 20, paddingBottom: 20 }}
                        >
                          <Grid item xs={12} md={6}>
                            Мерна еденица на продукт Обичайно зависи от начина
                            по който предлагате вашият продукт - на бройка или
                            връзка, в пакет, на килограм или литър.
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <DropDownComponent
                              context={context}
                              data={context.state.unitsDropDown}
                              value={context.state.entity}
                              itemCount={6}
                              fontSize={15}
                              style={{ margiBottom: 10 }}
                              onChangeText={(value, index, data) => {
                                console.log("data selected ", data);
                                context.setState({
                                  entity: context.state.units[index].name
                                });
                                context.setState({
                                  entity_id: context.state.units[index].id
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Divider variant="middle" className={classes.divider} />
                  </Grid>
                  <Grid item>
                    <label>
                      <span
                        onClick={() => {
                          context.setState({
                            isPersonalisable: Number(
                              !context.state.isPersonalisable
                            )
                          });
                        }}
                      >
                        Персонализирана разфасовка
                      </span>
                    </label>
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={context.state.isPersonalisable === 1}
                      onChange={() => {
                        context.setState({
                          isPersonalisable: Number(
                            !context.state.isPersonalisable
                          )
                        });
                        console.log(
                          "is personalisable is now " +
                            context.state.isPersonalisable
                        );
                      }}
                      value="Персонализирана разфасовка"
                      color="primary"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider variant="middle" className={classes.divider} />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    {context.state.isPersonalisable === 1 ? (
                      <Grid container spacing={32} direction={"row"}>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <div>
                            <FormHelperText htmlFor="entity-input">
                              Единична цена
                            </FormHelperText>
                            <Input
                              id="entity-input"
                              onChange={event =>
                                context.setState({
                                  unitPrice: event.target.value
                                })
                              }
                              name="Единична цена"
                              style={{ width: "100%" }}
                              maxLength={6}
                              endAdornment={
                                <InputAdornment position="end">
                                  <p
                                    style={{
                                      color: "gray",
                                      minWidth: 110,
                                      textAlign: "right"
                                    }}
                                  >
                                    лв за 1 {context.state.entity}
                                  </p>
                                </InputAdornment>
                              }
                              value={
                                context.state.unitPrice !== null &&
                                context.state.unitPrice !== -1
                                  ? context.state.unitPrice !== undefined
                                    ? context.state.unitPrice.toString()
                                    : ""
                                  : ""
                              }
                            />
                          </div>
                        </Grid>

                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <div>
                            <FormHelperText htmlFor="entity-reduced-input">
                              Ед. намалена цена
                            </FormHelperText>
                            <Input
                              id="entity-reduced-input"
                              onChange={event =>
                                context.setState({
                                  unitSalePrice: event.target.value
                                })
                              }
                              name="Ед. намалена цена"
                              style={{ width: "100%" }}
                              maxLength={6}
                              endAdornment={
                                <InputAdornment position="end">
                                  <p
                                    style={{
                                      color: "gray",
                                      minWidth: 110,
                                      textAlign: "right"
                                    }}
                                  >
                                    лв за 1 {context.state.entity}
                                  </p>
                                </InputAdornment>
                              }
                              value={
                                context.state.unitSalePrice !== null &&
                                context.state.unitSalePrice !== -1
                                  ? context.state.unitSalePrice !== undefined
                                    ? context.state.unitSalePrice.toString()
                                    : ""
                                  : ""
                              }
                            />
                          </div>
                        </Grid>

                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <div>
                            <FormHelperText htmlFor="entity-delivery-input">
                              Доставна ед. цена
                            </FormHelperText>
                            <PriceAutoCalculationComponent
                              id="entity-delivery-input"
                              context={context}
                              style={{ width: "100%" }}
                              classes={styles}
                              item={{
                                price:
                                  context.state.unitSalePrice === "" ||
                                  context.state.unitSalePrice === null
                                    ? context.state.unitPrice !== null
                                      ? context.state.unitPrice
                                      : -1
                                    : context.state.unitSalePrice !== null
                                    ? context.state.unitSalePrice
                                    : -1
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    ) : (
                      <div />
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <h3>Разфасовки</h3>

                    <div style={{ marginTop: 5 }}>
                      <VariantionsTableComponent
                        state={context.state}
                        removeItem={onVariantDelete}
                        context={context}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{
                        marginTop: 10
                      }}
                      onClick={context.addListItem}
                    >
                      Добави разфасовка
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper className={classes.paper}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={11}>
                    <CategoriesDisplay
                      style={{ marginBottom: 10 }}
                      context={context}
                      onOpenCategories={onOpenCategories}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <div
                      style={{
                        verticalAlign: "middle"
                      }}
                    >
                      <IconButton
                        className={classes.button}
                        aria-label="Edit"
                        onClick={onOpenCategories}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            {context.state.isEditing && (
              <Paper className={classes.paper}>
                <Grid item xs={12}>
                  <QuestionsTextComponent
                    context={context}
                    onOpenQuestions={onOpenQuestions}
                  />
                </Grid>
              </Paper>
            )}
          </Grid>
        </Grid>
      </main>
    </div>
  );
}

const styles = theme => ({
  layout: {
    width: "auto",
    display: "block", // Fix IE11 issue.
    paddingLeft: 12,
    paddingRight: 12
  },
  paper: {
    marginTop: 20,
    // marginRight: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
    // maxWidth: 700,
    // minWidth: 250
  },
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {
    margin: theme.spacing.unit
  },
  textField: {
    flexBasis: 200
  },
  inputsFirst: {
    [theme.breakpoints.up("sm")]: {
      // marginRight: 48,
      width: 160
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 0
    }
  },
  inputsSecond: {
    [theme.breakpoints.up("sm")]: {
      // marginRight: 40,
      width: 160,
      marginLeft: 0
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 0
    }
  },
  inputsThird: {
    [theme.breakpoints.up("sm")]: {
      // marginRight: 40,
      width: 160,
      marginRight: 10
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 10
    }
  },
  inputs: {
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.between("sm", "md")]: {
      width: "100%"
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%"
    }
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 0,
    marginRight: 0
  }
});

export default withStyles(styles)(createProduct);
