import React from "react";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";

export default class YearsFilterDropDown extends React.Component {
  render() {
    const { context, getDates } = this.props;

    var mapYears = selectedYear => {
      for (var i = 0; i < context.state.orderYears.length; i++) {
        if (selectedYear === context.state.orderYears[i]) {
          return i;
        }
      }
      return 0;
    };

    let unitsItems = context.state.orderYears.map((s, i) => {
      if (s !== undefined) {
        return (
          <MenuItem value={s} id={i}>
            {s}
          </MenuItem>
        );
      } else {
        return (
          <MenuItem value={"кг"} id={1}>
            година
          </MenuItem>
        );
      }
    });

    if (unitsItems.length === 0) {
      return (
        <MenuItem value={"кг"} id={1}>
          година
        </MenuItem>
      );
    }

    return (
      <FormControl>
        <InputLabel style={{ width: "160px" }}>Година</InputLabel>
        <Select
          value={
            context.state.orderYears.length > context.state.selectedYear
              ? context.state.orderYears[context.state.selectedYear]
              : "Месец"
          }
          style={{ width: "160px" }}
          displayEmpty
          onChange={event => {
            console.log("selected year event ", event);
            context.setState({ selectedYear: mapYears(event.target.value) });
            context.setState({ selectedMonth: 0 });
            getDates(0, mapYears(event.target.value));
          }}
          name="unit"
          inputProps={{
            name: "year"
          }}
        >
          {unitsItems}
        </Select>
      </FormControl>
    );
  }
}
