import React from "react";
import Icon from "@material-ui/core/Icon/Icon";

export default class DescriptionTextComponent extends React.Component {
  render() {
    const { context, onDescriptionOpen } = this.props;

    return (
      <div
        onClick={() => {
          onDescriptionOpen();
        }}
        style={{ cursor: "pointer" }}
      >
        <div>
          <h3 style={{ marginTop: 10, marginBottom: -9 }}>Описание</h3>

          <div>
            <p style={{ marginRight: 6, flex: 0.95 }}>
              {context.state.description === "" ||
              context.state.description === undefined
                ? "Кликнете, за да добавите описание"
                : context.state.description}
            </p>

            <Icon style={{ flex: 0.05 }} name="keyboard-arrow-right" />
          </div>
        </div>
      </div>
    );
  }
}
