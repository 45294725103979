import { store } from "../store";
import setUser from "../components/redux/actions/setUser";

export default class StateSaverUtil {
  static save(context, propsToSave) {
    this.addRequiredProps(propsToSave);

    console.log("this context ", context.constructor.name);

    for (var i = 0; i < propsToSave.length; i++) {
      if (context.state[propsToSave[i]] !== undefined) {
        localStorage.setItem(
          context.constructor.name + "@" + propsToSave[i],
          JSON.stringify(context.state[propsToSave[i]])
        );
      }
    }
  }

  //returns true if all the properties are restored successfully
  static async restoreState(context, propsToRestore) {
    this.addRequiredProps(propsToRestore);

    var isSuccessful = true;
    console.log("propsToRestore.length ", propsToRestore.length);
    var user = {};
    for (var i = 0; i < propsToRestore.length; i++) {
      let propName = propsToRestore[i];

      if (
        context.state[propName] === undefined &&
        localStorage.getItem(
          context.constructor.name + "@" + propsToRestore[i]
        ) !== null
      ) {
        console.log(
          "restoring prop ",
          propName,
          " with value ",
          localStorage.getItem(
            context.constructor.name + "@" + propsToRestore[i]
          )
        );
        user[propName] = JSON.parse(
          localStorage.getItem(
            context.constructor.name + "@" + propsToRestore[i]
          )
        );
        context.setState({
          [propName]: JSON.parse(
            localStorage.getItem(
              context.constructor.name + "@" + propsToRestore[i]
            )
          )
        });
      } else {
        isSuccessful = false;
      }
    }
    store.dispatch(setUser(user));

    return isSuccessful;
  }

  //returns true if all the properties are deleted successfully
  static deleteSavedProps(context, propsToDelete) {
    this.addRequiredProps(propsToDelete);
    var isSuccessful = true;
    for (var i = 0; i < propsToDelete.length; i++) {
      if (context.state.hasOwnProperty(propsToDelete[i])) {
        localStorage.removeItem(
          context.constructor.name + "@" + propsToDelete[i]
        );
      } else {
        isSuccessful = false;
      }
    }

    return isSuccessful;
  }

  //adds the props which are used all over the application
  static addRequiredProps(props) {
    if (props === undefined || props === null) {
      props = [];
    }
    props.push("selectedProducer");
    props.push("producersImages");
    props.push("userName");
    props.push("userEmail");
    props.push("producerId");
    props.push("producersAllData");
  }
}
