import React, { Component } from "react";
import "./App.css";
import LogInScreen from "./screens/authentication/LogInScreen";
import { Route } from "react-router";
import ProductsDashboardScreen from "./screens/ProductsDashboardScreen";
import CreateProductScreen from "./screens/CreateProductScreen";
import EditItemScreen from "./screens/EditItemScreen";
import BulkEditScreen from "./screens/BulkEditScreen";
import OrdersScreen from "./screens/OrdersScreen";
import OrdersDatesScreen from "./screens/OrdersDatesScreen";
import QuestionsForProductScreen from "./screens/QuestionsForProductScreen";
import AnswersForQuestionsScreen from "./screens/AnswersForQuestionsScreen";
import PaymentsScreen from "./screens/PaymentsScreen";
import QuestionsScreen from "./screens/QuestionsScreen";
import { HashRouter, Switch } from "react-router-dom";
class App extends Component {
  render() {
    return (
      <HashRouter basename={"/farmapp"}>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={LogInScreen}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/products`}
            component={ProductsDashboardScreen}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/products/create`}
            component={CreateProductScreen}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/products/edit`}
            component={EditItemScreen}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/products/bulk`}
            component={BulkEditScreen}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/products/questions`}
            component={QuestionsForProductScreen}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/products/questions/answers`}
            component={AnswersForQuestionsScreen}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/orders/dates`}
            component={OrdersDatesScreen}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/orders`}
            component={OrdersScreen}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/payments`}
            component={PaymentsScreen}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/questions`}
            component={QuestionsScreen}
          />
        </Switch>
      </HashRouter>
    );
  }

  /* render() {
    return (


      <div className="App">

          <LogInScreen/>

      </div>
    );
  } */
}

export default App;
