import React from "react";

import {
  accessToken,
  unauthenticated,
  message,
  favIconLink,
  taxDiscount,
  ordersForDateUrl,
  autorefreshInterval
} from "../Constants";
import NetworkConfig from "../config/NetworkConfig";
import { read_cookie } from "sfcookies";
import Favicon from "react-favicon";
import history from "./../history";
import OrdersComponent from "../components/OrdersComponent";
import PermamantDrawerComponent from "../components/PermamantDrawerComponent";
import Hidden from "@material-ui/core/Hidden/Hidden";
import NavBarComponent from "../components/NavBarComponent";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import BackButtonHandler from "../utils/BackButtonHandler";
import StateSaverUtil from "../utils/StateSaverUtil";
import { store } from "../store";

export default class OrdersScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      producersImages: store.getState().producersImages,
      producerId: store.getState().producerId,
      producerPercent: store.getState().producerPercent,
      selectedProducer: store.getState().selectedProducer,
      producersAllData: store.getState().producersAllData,
      ordersData: [],
      selectedToDelete: [],
      distinctProducts: [],
      productsData: [],
      totalPrice: 0,
      tax: 0,
      totalToPay: 0,
      sameProductPricesAndQuanitities: [],
      orderMonths: [],
      orderYears: [],
      selectedMonth: 0,
      selectedYear: 0,
      locationId:
        this.props.location.state !== undefined
          ? this.props.location.state.location_id
          : undefined,
      locationName:
        this.props.location.state !== undefined
          ? this.props.location.state.locationName
          : undefined,
      locationDate:
        this.props.location.state !== undefined
          ? this.props.location.state.locationDate
          : undefined,
      delivery_start:
        this.props.location.state !== undefined
          ? this.props.location.state.delivery_start.toString()
          : undefined,
      delivery_end:
        this.props.location.state !== undefined
          ? this.props.location.state.delivery_end.toString()
          : undefined,
      isDrawerOpen: false,
      userName: store.getState().userName,
      userEmail: store.getState().userEmail
    };

    this.propsToRestore = [
      "locationId",
      "locationName",
      "locationDate",
      "delivery_start",
      "delivery_end"
    ];

    this.getData = this.getData.bind(this);
    this.getOrders = this.getOrders.bind(this);
    this.onBackArrowClicked = this.onBackArrowClicked.bind(this);
    this.openDrawer = this.openDrawer.bind(this);
    this.autoRefresh = this.autoRefresh.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    BackButtonHandler.handle(this, this.onBackArrowClicked);
    StateSaverUtil.save(this, this.propsToRestore);

    document.title = "Поръчки";

    this.getData();
  }

  componentWillUnmount() {
    StateSaverUtil.deleteSavedProps(this, this.propsToRestore);
  }

  async getData() {
    await StateSaverUtil.restoreState(this, this.propsToRestore);
    console.log("state after being restored ", this.state);

    await this.getOrders();
    this.autoRefresh();
  }

  openDrawer() {
    console.log("old state ", this.state.isDrawerOpen);

    var isDrawerOpen = false;
    if (this.state.isDrawerOpen === false) {
      isDrawerOpen = true;
    } else {
      isDrawerOpen = false;
    }

    this.setState({
      isDrawerOpen: isDrawerOpen
    });

    console.log("toggle drawer; now should be ", this.state.isDrawerOpen);
  }

  render() {
    return (
      <div>
        <Favicon url={favIconLink} />
        <PermamantDrawerComponent
          context={this}
          onPushOrdersScreen={this.onPushOrdersScreen}
        />
        <Hidden mdUp implementation="css">
          <div style={{ marginLeft: 0 }}>
            <NavBarComponent
              title={"Поръчки"}
              onSideDrawerOpen={this.openDrawer}
              onBackArrowClicked={this.onBackArrowClicked}
              isSideMenuNeeded={false}
            />
          </div>
        </Hidden>

        <Hidden smDown implementation="css">
          <div style={{ marginLeft: 210 }}>
            <NavBarComponent
              title={"Поръчки"}
              onBackArrowClicked={this.onBackArrowClicked}
              isSideMenuNeeded={false}
            />
          </div>
        </Hidden>

        {!this.state.isLoaded && <LinearProgress />}

        <Hidden smDown implementation="css">
          <div style={{ marginLeft: 230 }}>
            <OrdersComponent
              context={this}
              onDrawerOpen={this.openDrawer}
              onPushOrdersScreen={this.onPushOrdersScreen}
              onBackArrowClicked={this.onBackArrowClicked}
            />
          </div>
        </Hidden>

        <Hidden mdUp implementation="css">
          <div style={{ marginLeft: 20 }}>
            <OrdersComponent
              context={this}
              onDrawerOpen={this.openDrawer}
              onPushOrdersScreen={this.onPushOrdersScreen}
              onBackArrowClicked={this.onBackArrowClicked}
            />
          </div>
        </Hidden>
      </div>
    );
  }

  onPushOrdersScreen() {
    history.goBack();
  }

  async getOrders() {
    let that = this;
    try {
      const value = read_cookie(accessToken);

      console.log(
        "requesting ",
        ordersForDateUrl +
          "?producer_id=" +
          that.state.producerId +
          "&delivery_date=" +
          that.state.locationDate +
          "&location_id=" +
          that.state.locationId
      );
      console.log("token value ", value);

      return fetch(
        ordersForDateUrl +
          "?producer_id=" +
          that.state.producerId +
          "&delivery_date=" +
          that.state.locationDate +
          "&location_id=" +
          that.state.locationId,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: value
          }
        }
      )
        .then(response => response.json())
        .then(responseJson => {
          console.log("response json order ", responseJson);

          if (
            responseJson.hasOwnProperty(message) &&
            responseJson.message === unauthenticated
          ) {
            NetworkConfig.refreshRequest(this.getOrders, that);
          } else {
            console.log(responseJson);

            var distinctProducts = new Set();
            for (var i = 0; i < responseJson[0].length; i++) {
              distinctProducts.add(responseJson[0][i].productName);
            }

            //calucate total price
            var totalPrice = 0;
            for (i = 0; i < responseJson[0].length; i++) {
              totalPrice += Number(responseJson[0][i].total);
            }
            let tax = (totalPrice * this.state.producerPercent / 100).toFixed(2); //tax is 20%

            let totalToPay = (Number(totalPrice) - Number(tax)).toFixed(2);

            var productsData = [];

            var sameProductPricesAndQuanitities = [];
            //every product from the same type
            let distinctProductsArray = Array.from(distinctProducts);
            for (i = 0; i < distinctProductsArray.length; i++) {
              productsData[distinctProductsArray[i]] = [];
              sameProductPricesAndQuanitities[distinctProductsArray[i]] = {
                totalToDeliver: 0,
                quantity: 0
              };

              for (var j = 0; j < responseJson[0].length; j++) {
                if (
                  responseJson[0][j].productName === distinctProductsArray[i]
                ) {
                  sameProductPricesAndQuanitities[
                    distinctProductsArray[i]
                  ].totalToDeliver +=
                    Number(responseJson[0][j].measurement) *
                    Number(responseJson[0][j].quantity);
                  sameProductPricesAndQuanitities[
                    distinctProductsArray[i]
                  ].quantity += responseJson[0][j].quantity;

                  productsData[distinctProductsArray[i]].push(
                    responseJson[0][j]
                  );
                }
              }
            }

            var orderMonths = new Set();
            var orderYears = new Set();

            //get order dates
            for (i = 0; i < responseJson[0].length; i++) {
              var date = new Date(responseJson[0][i].delivery_date),
                locale = "bg-bg",
                month = date.toLocaleString(locale, { month: "long" }),
                year = date.toLocaleString(locale, { year: "numeric" });

              console.log("month now ", month);
              console.log("year now ", year);

              orderMonths.add(month);
              orderYears.add(year);
            }

            this.setState({
              isLoaded: true,
              ordersData: responseJson,
              distinctProducts: Array.from(distinctProductsArray),
              productsData: productsData,
              totalPrice: totalPrice,
              tax: tax,
              totalToPay: totalToPay,
              sameProductPricesAndQuanitities: sameProductPricesAndQuanitities,
              orderMonths: Array.from(orderMonths),
              orderYears: Array.from(orderYears)
            });

            console.log("my state ", this.state);
          }
        })
        .catch(error => {
          console.error(error);
        });
    } catch (e) {}
  }

  autoRefresh() {
    let that = this;
    setInterval(function() {
      that.setState({
        isLoaded: false
      });
      that.getOrders();
    }, autorefreshInterval);
  }

  onBackArrowClicked() {
    console.log("back");
    this.setState({
      localRedirectBack: true
    });
  }
}
