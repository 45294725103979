import {
  accessToken,
  loginUrl,
  clientId,
  clientSecret,
  allScope,
  grantTypeRefreshToken,
  bearer,
  refreshToken
} from "../Constants";
import { bake_cookie, read_cookie } from "sfcookies";
import history from "./../history";

export default class NetworkConfig {
  static async refreshRequest(refreshCallback, context) {
    console.log("refresh request...");
    try {
      const value = read_cookie(refreshToken);

      var details = {
        refresh_token: value,
        client_id: clientId,
        client_secret: clientSecret,
        grant_type: grantTypeRefreshToken,
        scope: allScope
      };

      var formBody = [];
      for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      console.log("refresh url ", loginUrl);
      console.log("body of refresh request ", details);

      return fetch(loginUrl, {
        method: "POST",
        body: formBody,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log("refresh response ", responseJson);
          if (responseJson.access_token) {
            var token = bearer + responseJson.access_token;
            this.processNewToken(
              token,
              responseJson.refresh_token,
              refreshCallback
            );
          } else {
            alert("Сесията Ви е изтекла! Моля, влезте отново!");
            history.push(process.env.PUBLIC_URL + "/");
          }
        })
        .catch(error => {
          console.error(error);
        });
    } catch (e) {
      console.log(e);
    }
  }

  static async processNewToken(token, refreshToken, refreshCallback) {
    await this.saveData(token);
    await this.saveRefresh(refreshToken);
    console.log("token saved: ", token);

    console.log("refresh callback...");

    refreshCallback();
  }

  static async saveData(token) {
    try {
      bake_cookie(accessToken, token);
      //  return await AsyncStorage.setItem(accessToken, token);
    } catch (error) {
      // Error saving data
    }
  }

  static async saveRefresh(token) {
    try {
      bake_cookie(refreshToken, token);
      // return await AsyncStorage.setItem(refreshToken, token);
    } catch (error) {
      // Error saving data
    }
  }
}
