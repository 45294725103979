import React from "react";

import BulkEditComponent from "../components/BulkEditComponents/BulkEditComponent";
import {
  productUrl,
  bulkEditUrl,
  accessToken,
  unauthenticated,
  message,
  userUrl,
  url,
  storageImages,
  favIconLink
} from "../Constants";
import NetworkConfig from "../config/NetworkConfig";
import { read_cookie } from "sfcookies";
import Favicon from "react-favicon";
import BackButtonHandler from "../utils/BackButtonHandler";
import StateSaverUtil from "../utils/StateSaverUtil";
import { store } from "../store";

export default class BulkEditScreen extends React.Component {
  originalProducts = [];

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isLoaded: false,
      isDrawerOpen: false,
      selectedProducer: store.getState().selectedProducer,
      producerId: store.getState().producerId,
      isSavedSnack: false
    };

    this.propsToRestore = [];

    this.getData = this.getData.bind(this);
    this.onBulkSave = this.onBulkSave.bind(this);
    this.loadProducts = this.loadProducts.bind(this);
    this.onFilterSearch = this.onFilterSearch.bind(this);
    this.onDefaultState = this.onDefaultState.bind(this);
    this.openDrawer = this.openDrawer.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
    this.onBackArrowClicked = this.onBackArrowClicked.bind(this);
    this.closeSavedSnack = this.closeSavedSnack.bind(this);
  }

  componentDidMount() {
    document.title = "Покажи продукти - КООП";

    this.setState({ isLoading: true });

    StateSaverUtil.save(this, this.propsToRestore);
    BackButtonHandler.handle(this, this.onBackArrowClicked);
    this.getData();
  }

  componentWillUnmount() {
    StateSaverUtil.deleteSavedProps(this, this.propsToRestore);
  }

  async getData() {
    await StateSaverUtil.restoreState(this, this.propsToRestore);
    console.log("state after being restored ", this.state);

    this.loadProducts();
    this.getUserInfo();
  }

  async loadProducts() {
    let that = this;

    console.log(
      "request to " + productUrl + "?producer_id=" + this.state.producerId
    );
    try {
      const value = read_cookie(accessToken);

      return fetch(productUrl + "?producer_id=" + this.state.producerId, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: value
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          if (
            responseJson.hasOwnProperty(message) &&
            responseJson.message === unauthenticated
          ) {
            NetworkConfig.refreshRequest(this.loadProducts, that);
          } else {
            this.setState(
              {
                isLoading: false,
                isLoaded: true,
                bulkdDataSource: responseJson
              },
              function() {}
            );

            this.originalProducts = responseJson;
          }
        })
        .catch(error => {
          console.error(error);
        });
    } catch (error) {}
  }

  async getUserInfo() {
    let that = this;
    try {
      const value = read_cookie(accessToken);

      console.log("requesting ", userUrl);
      return fetch(userUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: value
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          if (
            responseJson.hasOwnProperty(message) &&
            responseJson.message === unauthenticated
          ) {
            NetworkConfig.refreshRequest(this.getUserInfo, that);
          } else {
            console.log("is active? ", responseJson.is_active);
            that.setState({
              isActive: responseJson.is_active
            });

            //producers image paths
            var producersImages = [];

            if (
              responseJson.is_active === true ||
              responseJson.is_active === 1
            ) {
              var producers = [];
              for (var i = 0; i < responseJson.producers.length; i++) {
                console.log(responseJson.producers[i].name);
                producers.push(responseJson.producers[i].name);

                var image =
                  url +
                  storageImages +
                  responseJson.producers[i].logo_image_path;
                producersImages.push(image);
              }

              this.setState({
                userName: responseJson.name,
                userEmail: responseJson.email,
                producers: producers,
                producersImages: producersImages,
                producersAllData: responseJson.producers
              });
              this.loadProducts();
            } else {
              this.setState({
                isLoading: false
              });
            }
          }
        })
        .catch(error => {
          console.error(error);
        });
    } catch (e) {}
  }

  onFilterSearch(term) {
    //    console.log("this ", this);
    //   console.log("this.originalProducts ", this.originalProducts);

    var products = this.originalProducts;

    let newProducts = products.filter(product =>
      product.name.toLowerCase().includes(term.toLowerCase())
    );

    this.setState({
      bulkdDataSource: newProducts
    });
  }
  onDefaultState() {
    this.setState({
      bulkdDataSource: this.originalProducts
    });
  }

  openDrawer() {
    console.log("old state ", this.state.isDrawerOpen);

    var isDrawerOpen = false;
    if (this.state.isDrawerOpen === false) {
      isDrawerOpen = true;
    } else {
      isDrawerOpen = false;
    }

    this.setState({
      isDrawerOpen: isDrawerOpen
    });

    console.log("toggle drawer; now should be ", this.state.isDrawerOpen);
  }

  onBackArrowClicked() {
    console.log("back");
    this.setState({ localRedirectBack: true });
  }

  render() {
    return (
      <div>
        <Favicon url={favIconLink} />
        <BulkEditComponent
          context={this}
          headerText={"Изберете кои продукти желаете да са публикувани."}
          state={this.state}
          onFilterSearch={this.onFilterSearch}
          onDefaultState={this.onFilterSearch}
          onBackArrowClicked={this.onBackArrowClicked}
          onBulkSave={this.onBulkSave}
          onOpenDrawer={this.openDrawer}
          closeSavedSnack={this.closeSavedSnack}
        />
      </div>
    );
  }

  async onBulkSave() {
    let that = this;

    var ids = [];
    var published = [];
    for (var i = 0; i < this.state.bulkdDataSource.length; i++) {
      ids.push(this.state.bulkdDataSource[i]["id"]);
      published.push(this.state.bulkdDataSource[i]["is_published"]);
    }

    if (this.isValid(ids, published)) {
      try {
        const value = read_cookie(accessToken);

        let data = {
          method: "PUT",
          credentials: "same-origin",
          body: JSON.stringify({
            ids: ids,
            active_states: published
          }),
          headers: {
            Authorization: value,
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        };

        console.log("request to ", bulkEditUrl);

        return fetch(bulkEditUrl, data)
          .then(response => response.json())
          .then(responseJson => {
            console.log("refresh request");

            if (
              responseJson.hasOwnProperty(message) &&
              responseJson.message === unauthenticated
            ) {
              NetworkConfig.refreshRequest(this.onBulkSave, that);
            } else {
              this.setState({
                isSavedSnack: true
              });
            }
          });
      } catch (error) {
        console.log("bulk save error ", error);
      }
    }
  }

  closeSavedSnack() {
    this.setState({
      isSavedSnack: false
    });
  }

  isValid(ids, published) {
    if (ids.length !== published.length) {
      alert("Неочаквана грешка");
      return false;
    }
    return true;
  }
}
