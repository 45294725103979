import React from "react";

import {
  url,
  storageImages,
  accessToken,
  unauthenticated,
  message,
  userUrl,
  favIconLink,
  productV2Url
} from "../Constants";
import ProductsDashboardComponent from "../components/ProductsComponents/ProductsDashboardComponent";
import NetworkConfig from "../config/NetworkConfig";
import { read_cookie } from "sfcookies";
import AuthUtil from "../utils/AuthUtil";
import Favicon from "react-favicon";
import BackButtonHandler from "../utils/BackButtonHandler";
import StateSaverUtil from "../utils/StateSaverUtil";
import setUser from "../components/redux/actions/setUser";
import { store } from "./../store";

export default class ProductsDashboardScreen extends React.Component {
  _isMounted = false;
  originalProducts = [];

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isSideMenuOpen: false,
      dataSource: [],
      producers: [],
      producersAllData: [],
      selectedProducer: store.getState().selectedProducer,
      selectedProducerName: "",
      producerId: store.getState().producerId,
      producerPercent: store.getState().producerPercent,
      searchTerm: "",
      isActive: true,
      isDrawerOpen: false,
      isLoaded: false,
      mobileOpen: false,
      selectedTab: 0,
      productsApiCallShow: 2
    };
    this.openCreateProductScreen = this.openCreateProductScreen.bind(this);
    this.goToBulkEditScreen = this.goToBulkEditScreen.bind(this);
    this.onEditItem = this.onEditItem.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
    this.loadProducts = this.loadProducts.bind(this);
    this.openDrawer = this.openDrawer.bind(this);
    this.onFilterSearch = this.onFilterSearch.bind(this);
    this.onDefaultState = this.onDefaultState.bind(this);

    this.setState({
      isSideMenuOpen: false
    });

    this.propsToRestore = [];

    AuthUtil.redirectUnauthenticated();
  }

  openCreateProductScreen() {
    this.setState({
      localRedirectCreate: true
    });
  }

  componentWillMount() {
    BackButtonHandler.handle(this, () => {});
  }

  async getUserInfo() {
    let that = this;
    try {
      const value = read_cookie(accessToken);

      console.log("requesting ", userUrl);
      return fetch(userUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: value
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          // StateSaverUtil.restoreState(this, this.propsToRestore);
          // console.log("state after being restored! ", this.state);

          console.log("response json user ", responseJson);

          if (
            responseJson.hasOwnProperty(message) &&
            responseJson.message === unauthenticated
          ) {
            NetworkConfig.refreshRequest(this.getUserInfo, that);
          } else {
            console.log("is active? ", responseJson.is_active);
            that.setState({
              isActive: responseJson.is_active
            });

            //producers image paths
            var producersImages = [];

            if (
              responseJson.is_active === true ||
              responseJson.is_active === 1
            ) {
              var producers = [];
              for (var i = 0; i < responseJson.producers.length; i++) {
                console.log(responseJson.producers[i].name);
                producers.push(responseJson.producers[i].name);

                var image =
                  url +
                  storageImages +
                  responseJson.producers[i].logo_image_path;
                producersImages.push(image);
              }

              let that = this;
              console.log("producerId ds before", this.state.producerId);
              console.log(
                "selectedProducer ds before",
                this.state.selectedProducer
              );
              console.log(
                "selectedProducerName ds before",
                this.state.selectedProducerName
              );

              store.subscribe(() => console.log(store.getState()));

              const user = {
                userName: responseJson.name,
                userEmail: responseJson.email,
                producers: producers,
                producersImages: producersImages,
                producersAllData: responseJson.producers,
                producerId:
                  this.state.producerId === undefined ||
                  this.state.producerId === null
                    ? responseJson.producers[0].id
                    : this.state.producerId,
                producerPercent:
                    this.state.producerPercent === undefined ||
                    this.state.producerPercent === null
                        ? responseJson.producers[0].percent
                        : this.state.producerPercent,
                selectedProducer:
                  this.state.selectedProducer === undefined ||
                  this.state.producerId === null
                    ? 0
                    : this.state.selectedProducer,
                selectedProducerName:
                  this.state.selectedProducerName === ""
                    ? responseJson.producers[0].name
                    : this.state.selectedProducerName
              };

              // Dispatch some actions
              store.dispatch(setUser(user));
              console.log("redux updated ", store.getState());

              this.setState(user, () => {
                that.loadProducts(this.state.producerId);
              });
              console.log("producerId ds", this.state.producerId);
              console.log("selectedProducer ds", this.state.selectedProducer);
              console.log(
                "selectedProducerName ds",
                this.state.selectedProducerName
              );
            } else {
              this.setState({
                isLoading: false
              });
            }
          }
        })
        .catch(error => {
          console.error(error);
        });
    } catch (e) {}
  }

  componentDidMount() {
    this._isMounted = true;
    StateSaverUtil.save(this, this.propsToRestore);

    this.getUserInfo();
    document.title = "КООП";

    store.subscribe(() => {
      this.setState([[store.getState().user]]);
      console.log("redux updated ", store.getState());
    });
  }

  async loadProducts(id) {
    let that = this;
    this.setState({ isLoading: true, isLoaded: false });
    // let id = (this.state.producersAllData[this.state.selectedProducer]).producer_id;
    console.log("all producers ", this.state.producersAllData);
    console.log("selected index ", this.state.selectedProducer);

    console.log("request to " + productV2Url + "?producer_id=" + id);
    try {
      const value = read_cookie(accessToken);

      return fetch(productV2Url + "?producer_id=" + id, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: value
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          if (
            responseJson.hasOwnProperty(message) &&
            responseJson.message === unauthenticated
          ) {
            NetworkConfig.refreshRequest(this.loadProducts(id), that);
          } else {
            StateSaverUtil.restoreState(this, this.propsToRestore);
            this.setState(
              {
                isLoading: false,
                isLoaded: true,
                dataSource: responseJson
              },
              function() {}
            );

            this.originalProducts = responseJson;
            console.log("originalProducts ", this.originalProducts);
          }
        })
        .catch(error => {
          console.error(error);
        });
    } catch (e) {}
  }

  updateDimensions() {
    //  this.setState({width: $(window).width(), height: $(window).height()});
  }

  goToBulkEditScreen() {
    /*history.push({
            pathname: process.env.PUBLIC_URL +'/products/bulk',
            state: {producerId: this.state.producersAllData[this.state.selectedProducer].producer_id,
                selectedProducer: this.state.selectedProducer}
        });*/
    this.setState({
      localRedirectBulk: true
    });
  }

  openDrawer() {
    console.log("old state ", this.state.isDrawerOpen);

    var isDrawerOpen = false;
    if (this.state.isDrawerOpen === false) {
      isDrawerOpen = true;
    } else {
      isDrawerOpen = false;
    }

    this.setState({
      isDrawerOpen: isDrawerOpen
    });

    console.log("toggle drawer; now should be ", this.state.isDrawerOpen);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  onFilterSearch(term) {
    var products = this.originalProducts;

    let newProducts = products.filter(product =>
      product.name.toLowerCase().includes(term.toLowerCase())
    );

    this.setState({
      dataSource: newProducts
    });
  }
  onDefaultState() {
    this.setState({
      dataSource: this.originalProducts
    });
  }

  render() {
    return (
      <div>
        <Favicon url={favIconLink} />

        <ProductsDashboardComponent
          context={this}
          loadProducts={this.loadProducts}
          onBulkAction={this.goToBulkEditScreen}
          onCreateItem={this.openCreateProductScreen}
          onEditItem={this.onEditItem}
          onFilterSearch={this.onFilterSearch}
          onSideDrawerOpen={this.openDrawer}
        />
      </div>
    );
  }

  onEditItem(item) {
    this.setState({
      itemId: item.id,
      localRedirectEdit: true
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    // BackHandler.removeEventListener('hardwareBackPress', this.backPressed);
    StateSaverUtil.deleteSavedProps(this, this.propsToRestore);
  }

  backPressed = () => {
    //  BackHandler.exitApp();
    return true;
  };
}
