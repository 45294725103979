import React from "react";

import AuthFormComponent from "../../components/AuthFormComponent";
import AuthenticationComponent from "./AuthenticationComponent";
import {
  loginUrl,
  emailsCheckUrl,
  clientId,
  clientSecret,
  grantTypePassword,
  allScope,
  bearer,
  refreshToken
} from "./../../Constants";
import {
  accessToken,
  favIconLink,
  message,
  unauthenticated,
  userUrl
} from "../../Constants";
import Favicon from "react-favicon";
import { bake_cookie, read_cookie } from "sfcookies";
import NetworkConfig from "../../config/NetworkConfig";

export default class LogInScreen extends AuthenticationComponent {
  constructor(props) {
    super(props);
    this.state = {
      isErrorDialogOpen: false,
      email: "",
      password: ""
    };
    this.onLogin = this.onLogin.bind(this);
    this.getButtonText = this.getButtonText.bind(this);
    this.onLoadRegistration = this.onLoadRegistration.bind(this);
    this.onErrorDialogClose = this.onErrorDialogClose.bind(this);
  }
  onLogin() {
    console.log("log in: ", this.state.email, " ", this.state.password);
    //packing x-www-form-urlencoded data
    var details = {
      username: this.state.email,
      password: this.state.password,
      client_id: clientId,
      client_secret: clientSecret,
      grant_type: grantTypePassword,
      scope: allScope,
      isErrorDialogOpen: false
    };

    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    console.log("login url ", loginUrl);
    console.log("body login ", formBody);
    //packing x-www-form-urlencoded data finished

    return fetch(loginUrl, {
      method: "POST",
      body: formBody,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "https://coop.hrankoop.com",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "Access-Control-Allow-Methods":
          "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log("login response ", responseJson);
        if (responseJson.access_token && responseJson.refresh_token) {
          AuthenticationComponent.saveData(bearer + responseJson.access_token);
          AuthenticationComponent.saveRefreshToken(responseJson.refresh_token);

          this.getUserInfo();
        } else {
          var newDetails = {
            email: this.state.email
          };

          var newFormBody = [];
          for (var property in newDetails) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(newDetails[property]);
            newFormBody.push(encodedKey + "=" + encodedValue);
          }
          newFormBody = newFormBody.join("&");

          console.log("new form body ", newFormBody);

          return fetch(emailsCheckUrl, {
            method: "POST",
            body: JSON.stringify({
              email: this.state.email
            }),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          })
            .then(response => response.json())
            .then(responseJson => {
              console.log("check email response ", responseJson);

              const isEmailValid = responseJson.exists === 1 ? true : false;

              this.setState({
                isErrorDialogOpen: true,
                loginErrorMessage: isEmailValid
                  ? "Вие имате регистриран акаунт, но изглежда сте забравили паролата си"
                  : "Потребител с такъв имейл не съществува"
              });
            })
            .catch(error => {
              console.error(error);
            });
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  async getUserInfo() {
    let that = this;
    try {
      const value = read_cookie(accessToken);

      console.log("requesting ", userUrl);
      return fetch(userUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: value
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log("response json user ", responseJson);

          if (
            responseJson.hasOwnProperty(message) &&
            responseJson.message === unauthenticated
          ) {
            NetworkConfig.refreshRequest(this.getUserInfo, that);
          } else {
            if (
              (responseJson.is_active === true ||
                responseJson.is_active === 1) &&
              responseJson.producers.length > 0
            ) {
              this.setState({
                email: "",
                password: ""
              });

              console.log("going to products");
              console.log("url is   " + process.env.PUBLIC_URL + "/products");
              //history.push( process.env.PUBLIC_URL + '/products');

              that.setState({
                localRedirect: true
              });
            } else {
              bake_cookie(accessToken, "");
              bake_cookie(refreshToken, "");

              this.setState({
                isErrorDialogOpen: true,
                loginErrorMessage:
                  "Не сте регистриран като фермер или не сте свързан към ферма! Свържете се с администратора на Вашия кооператив!"
              });
            }
          }
        })
        .catch(error => {
          console.error(error);
        });
    } catch (e) {}
  }

  getButtonText() {
    return "Вход";
  }
  onLoadRegistration() {}

  onErrorDialogClose() {
    this.setState({
      isErrorDialogOpen: false
    });
  }

  componentDidMount() {
    document.title = "Вход - КООП";
  }

  render() {
    return (
      <div>
        <Favicon url={favIconLink} />
        <AuthFormComponent
          context={this}
          loginAction={this.onLogin}
          buttonText={this.getButtonText}
          onErrorDialogClose={this.onErrorDialogClose}
        />
      </div>
    );
  }
}
