import React from "react";

import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import NavBarComponent from "./NavBarComponent";
import SaveIcon from "@material-ui/icons/Save";
import Paper from "@material-ui/core/Paper";

import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField/TextField";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function descriptionContainer(props) {
  const { context, classes, onDescriptionSave, onDialogClose } = props;

  return (
    <Dialog
      fullScreen
      open={context.state.isDescriptionDialogOpen}
      TransitionComponent={Transition}
    >
      <NavBarComponent
        onCloseClicked={onDialogClose}
        onClickAction={onDescriptionSave}
        onClickText={"Потвърди"}
        title={"Промяна на описание"}
        isSideMenuNeeded={false}
        actionIcon={<SaveIcon />}
      />

      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <FormHelperText
            style={{ marginLeft: 10, marginRight: 10 }}
            htmlFor="description-input"
          >
            Въведете описание
          </FormHelperText>

          <TextField
            id="description-input"
            style={{ marginLeft: 10, marginRight: 10 }}
            value={context.state.newDescription}
            multiline
            fullWidth
            rowsMax="8"
            onChange={event =>
              context.setState({ newDescription: event.target.value })
            }
          />
        </Paper>
      </main>
    </Dialog>
  );
}

const styles = theme => ({
  layout: {
    width: "auto",
    display: "block", // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {
    margin: theme.spacing.unit
  },
  textField: {
    flexBasis: 200
  }
});

export default withStyles(styles)(descriptionContainer);
