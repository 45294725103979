import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import { Redirect } from "react-router-dom";
import ProducerDropDownComponent from "../ProducerDropDownComponent";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import Tabs from "@material-ui/core/Tabs/Tabs";
import SwipeableViews from "react-swipeable-views";
import Typography from "@material-ui/core/Typography/Typography";
import Tab from "@material-ui/core/Tab/Tab";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";
import { ALL_PRODUCTS_TAB } from "./../../Constants";

function productsTable(props) {
  const { context, theme, loadProducts, classes, onEditItem } = props;

  function TabContainer({ children, dir }) {
    return (
      <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
        {children}
      </Typography>
    );
  }

  let handleChange = (event, selectedTab) => {
    context.setState({ selectedTab });
  };

  let handleChangeIndex = index => {
    context.setState({
      selectedTab: index
    });
  };

  let data = !context.state.isLoaded ? (
    <LinearProgress />
  ) : (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Продукт</TableCell>
          {context.state.selectedTab === ALL_PRODUCTS_TAB && (
            <TableCell>Публикуван</TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {context.state.dataSource[context.state.selectedTab].map(row => (
          <TableRow
            key={row.id}
            style={{ cursor: "pointer" }}
            onClick={() => onEditItem(row)}
          >
            <TableCell>{row.name}</TableCell>
            {context.state.selectedTab === ALL_PRODUCTS_TAB && (
              <TableCell>{row.is_published ? "Да" : "Не"}</TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <main className={classes.layout}>
      {context.state.localRedirectEdit ? (
        <Redirect
          to={{
            pathname: process.env.PUBLIC_URL + "/products/edit",
            state: {
              producerId: context.state.producerId,
              itemId: context.state.itemId,
              selectedProducer: context.state.selectedProducer,
              producerPercent:
                context.state.producersAllData !== undefined &&
                context.state.producersAllData[
                  context.state.selectedProducer
                ] !== undefined
                  ? context.state.producersAllData[
                      context.state.selectedProducer
                    ].percent
                  : null
            }
          }}
        />
      ) : (
        <div />
      )}

      <ProducerDropDownComponent
        context={context}
        loadProducts={loadProducts}
        data={context.state.producersAllData}
        value={context.state.selectedProducer}
        itemCount={6}
        fontSize={15}
        style={{ marginBottom: 10 }}
      />

      <Paper className={classes.paper}>
        <Tabs
          value={context.state.selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Всички" />
          <Tab label="Публикувани" />
          <Tab label="Скрити" />
        </Tabs>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={context.state.selectedTab}
          onChangeIndex={handleChangeIndex}
        >
          <TabContainer dir={theme.direction}>{data}</TabContainer>
          <TabContainer dir={theme.direction}>{data}</TabContainer>
          <TabContainer dir={theme.direction}>{data}</TabContainer>
        </SwipeableViews>
      </Paper>
    </main>
  );
}

const styles = theme => ({
  rows: {
    display: "inline-block"
  },
  layout: {
    width: "auto",
    display: "block" // Fix IE11 issue.,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    minWidth: 250,
    maxWidth: 600,
    marginRight: 20,
    marginTop: 20
  },
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {
    margin: theme.spacing.unit
  },
  textField: {
    flexBasis: 200
  }
});

export default withStyles(styles, { withTheme: true })(productsTable);
