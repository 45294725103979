import React, { Component } from "react";
import NavBarComponent from "../NavBarComponent";
import ProductsTableComponent from "./ProductsTableComponent";
import EditIcon from "@material-ui/icons/Edit";
import { Redirect } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import Hidden from "@material-ui/core/Hidden/Hidden";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import { withStyles } from "@material-ui/core";
import PermamantDrawerComponent from "../PermamantDrawerComponent";
import LogOutRedirectComponent from "../redirects/LogOutRedirectComponent";
import Fab from "@material-ui/core/Fab";

class ProductsDashboardComponent extends Component {
  render() {
    const {
      context,
      loadProducts,
      classes,
      onCreateItem,
      onEditItem,
      onFilterSearch,
      onBulkAction,
      onSideDrawerOpen
    } = this.props;

    return (
      <div>
        {console.log(
          "dashboard state.localRedirectLogOut is now ",
          context.state.localRedirectLogOut
        )}
        <LogOutRedirectComponent context={context} />

        {context.state.isActive ? (
          <div className={classes.root}>
            {context.state.localRedirectBulk ? (
              <Redirect
                to={{
                  pathname: process.env.PUBLIC_URL + "/products/bulk"
                }}
              />
            ) : (
              <div />
            )}

            {context.state.localRedirectCreate ? (
              <Redirect
                to={{
                  pathname: process.env.PUBLIC_URL + "/products/create"
                }}
              />
            ) : (
              <div />
            )}

            <CssBaseline />
            <PermamantDrawerComponent context={context} />

            <main className={classes.content}>
              <Hidden mdUp implementation="css">
                <div style={{ marginLeft: 0 }}>
                  <NavBarComponent
                    searchEnabled={true}
                    onSideDrawerOpen={onSideDrawerOpen}
                    onClickAction={onBulkAction}
                    actionIcon={<EditIcon />}
                    onClickText={"Активиране на продукти"}
                    title={"Продукти"}
                    onFilterSearch={onFilterSearch}
                  />
                </div>
              </Hidden>

              <Hidden smDown implementation="css">
                <div style={{ marginLeft: 210 }}>
                  <NavBarComponent
                    searchEnabled={true}
                    onClickAction={onBulkAction}
                    actionIcon={<EditIcon />}
                    onClickText={"Активиране на продукти"}
                    title={"Продукти"}
                    onFilterSearch={onFilterSearch}
                  />
                </div>
              </Hidden>

              <Hidden mdUp implementation="css">
                <div style={{ marginLeft: 20 }}>
                  <ProductsTableComponent
                    context={context}
                    loadProducts={loadProducts}
                    onEditItem={onEditItem}
                  />
                </div>
              </Hidden>

              <Hidden smDown implementation="css">
                <div style={{ marginLeft: 230 }}>
                  <ProductsTableComponent
                    context={context}
                    loadProducts={loadProducts}
                    onEditItem={onEditItem}
                  />
                </div>
              </Hidden>

              <Fab
                style={{
                  background: "#e74c3d",
                  margin: 0,
                  top: "auto",
                  right: 40,
                  bottom: 40,
                  left: "auto",
                  position: "fixed"
                }}
                color="primary"
                onClick={() => onCreateItem()}
              >
                <AddIcon />
              </Fab>
            </main>
          </div>
        ) : (
          <div>
            <p>
              Моля, изчакайте за одобрение от страна на кооператив. Ще бъдете
              известен чрез имейл, когато това се случи.
            </p>
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
};

export default withStyles(styles, { withTheme: true })(
  ProductsDashboardComponent
);
