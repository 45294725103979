import React from "react";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";

export default class DatesFilterDropDown extends React.Component {
  render() {
    const { context, getDates } = this.props;

    var mapMonth = selectedMonth => {
      for (
        var i = 0;
        i <
        context.state.orderMonths[
          context.state.orderYears[context.state.selectedYear]
        ].length;
        i++
      ) {
        if (
          selectedMonth ===
          context.state.orderMonths[
            context.state.orderYears[context.state.selectedYear]
          ][i].localized_month
        ) {
          return i;
        }
      }
      return 0;
    };

    let unitsItems;
    if (
      context.state.orderMonths !== undefined &&
      context.state.orderMonths.length > 0
    ) {
      unitsItems = context.state.orderMonths[
        context.state.orderYears[context.state.selectedYear]
      ].map((s, i) => {
        if (s !== undefined) {
          return (
            <MenuItem value={s.localized_month} id={i}>
              {s.localized_month}
            </MenuItem>
          );
        } else {
          return (
            <MenuItem value={"кг"} id={1}>
              месец
            </MenuItem>
          );
        }
      });

      if (unitsItems.length === 0) {
        return (
          <MenuItem value={"кг"} id={1}>
            месец
          </MenuItem>
        );
      }
    }

    return (
      <FormControl>
        <InputLabel style={{ width: "160px" }}>Месец</InputLabel>
        <Select
          value={
            context.state.orderMonths !== undefined &&
            context.state.orderMonths.length > 0
              ? context.state.orderMonths[
                  context.state.orderYears[context.state.selectedYear]
                ].length > context.state.selectedMonth
                ? context.state.orderMonths[
                    context.state.orderYears[context.state.selectedYear]
                  ][context.state.selectedMonth].localized_month
                : "Месец"
              : "Месец"
          }
          style={{ width: "160px" }}
          displayEmpty
          onChange={event => {
            console.log("selected month event ", event);
            context.setState({ selectedMonth: mapMonth(event.target.value) });
            console.log(
              "selected month index is ",
              mapMonth(event.target.value)
            );
            getDates(mapMonth(event.target.value), context.state.selectedYear);
          }}
          name="unit"
          inputProps={{
            name: "month"
          }}
        >
          {unitsItems}
        </Select>
      </FormControl>
    );
  }
}
