import React from "react";
import { withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import BackRedirectComponent from "./redirects/BackRedirectComponent";
import { Redirect } from "react-router-dom";
import LogOutRedirectComponent from "./redirects/LogOutRedirectComponent";
import Table from "@material-ui/core/es/Table";
import TableHead from "@material-ui/core/es/TableHead";
import TableRow from "@material-ui/core/es/TableRow";
import Hidden from "@material-ui/core/es/Hidden";
import TableCell from "@material-ui/core/es/TableCell";
import TableBody from "@material-ui/core/es/TableBody";
import Button from "@material-ui/core/Button";

function questionsComponent(props) {
  const { context, classes, openQuestionAnswers } = props;

  return (
    <div className={classes.root}>
      <LogOutRedirectComponent context={context} />

      {context.state.localRedirectAnswer ? (
        <Redirect
          to={{
            pathname: process.env.PUBLIC_URL + "/products/questions/answers",
            state: {
              redirectFromList: context.state.redirectFromList,
              product_id: context.state.productId,
              productName: context.state.productName,
              questionId: context.state.questionId,
              navBarTitle: context.state.questionTitle
            }
          }}
        />
      ) : (
        <div />
      )}

      <BackRedirectComponent
        context={context}
        whereTo={"products/edit"}
        state={{ itemId: context.state.productId }}
      />

      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <React.Fragment>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <Hidden smDown>
                    <TableCell className={classes.tableCell} padding="checkbox">
                      Дата
                    </TableCell>
                  </Hidden>
                  <TableCell className={classes.tableCell} padding="checkbox">
                    Въпрос
                  </TableCell>
                  <Hidden smDown>
                    <TableCell
                      className={classes.tableCellWide}
                      padding="checkbox"
                    >
                      Потребител
                    </TableCell>
                  </Hidden>
                  <Hidden smDown>
                    <TableCell
                      className={classes.tableCell}
                      padding="checkbox"
                    />
                  </Hidden>
                </TableRow>
              </TableHead>

              <TableBody>
                {context.state.questionsData.map(item => (
                  <TableRow key={item.id} className="rows">
                    <Hidden smDown>
                      <TableCell
                        className={classes.tableCellDate}
                        padding="checkbox"
                      >
                        {new Date(
                          item.created_at.replace(/ /g, "T")
                        ).toLocaleDateString("bg-BG")}
                      </TableCell>
                    </Hidden>
                    <TableCell
                      className={classes.tableCellWide}
                      padding="checkbox"
                    >
                      {item.description}
                      <Hidden mdUp>
                        <br />
                        <br />
                        От {item.user.name} на{" "}
                        {new Date(
                          item.created_at.replace(/ /g, "T")
                        ).toLocaleDateString("bg-BG")}
                        <br />
                        <Button
                          style={{ marginTop: 10 }}
                          onClick={() => {
                            openQuestionAnswers(item.id, item.description);
                          }}
                          color="primary"
                          size="small"
                          variant="outlined"
                        >
                          Детайли
                        </Button>
                      </Hidden>
                    </TableCell>
                    <Hidden smDown>
                      <TableCell
                        className={classes.tableCell}
                        padding="checkbox"
                      >
                        {item.user.name}
                      </TableCell>
                    </Hidden>
                    <Hidden smDown>
                      <TableCell
                        className={classes.tableCellDetails}
                        padding="checkbox"
                      >
                        <Button
                          onClick={() => {
                            openQuestionAnswers(item.id, item.description);
                          }}
                          color="primary"
                          size="small"
                          variant="outlined"
                        >
                          Детайли
                        </Button>
                      </TableCell>
                    </Hidden>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </React.Fragment>
        </Paper>
      </main>
    </div>
  );
}
const styles = theme => ({
  layout: {
    width: "auto",
    display: "block" // Fix IE11 issue.
  },
  paper: {
    marginTop: 20,
    marginRight: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    maxWidth: 600,
    minWidth: 250
  },
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {
    margin: theme.spacing.unit
  },
  textField: {
    flexBasis: 200
  },
  table: {},
  tableCell: {
    width: "20%",
    paddingTop: 10,
    paddingBottom: 10
  },
  tableCellDate: {
    width: "20%",
    paddingTop: 10,
    paddingBottom: 10,
    whiteSpace: "nowrap"
  },
  tableAmount: {
    width: "15%"
  },
  tableCellWide: {
    width: "55%",
    paddingTop: 10,
    paddingBottom: 10
  },
  tableCellDetails: {
    width: "20%",
    paddingTop: 10,
    paddingBottom: 10,
    cursor: "pointer"
  }
});

export default withStyles(styles, { withTheme: true })(questionsComponent);
