import React from "react";
import DeleteSharpIcon from "@material-ui/icons/DeleteSharp";
import IconButton from "@material-ui/core/IconButton/IconButton";
export default class DeleteIconComponent extends React.Component {
  render() {
    const { classes, elements, onPressAction, index } = this.props;
    if (elements.length > 1) {
      return (
        <IconButton
          className={classes.deleteVariation}
          onClick={() => onPressAction(index)}
        >
          <DeleteSharpIcon />
        </IconButton>
      );
    } else {
      return <div />;
    }
  }
}
