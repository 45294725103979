import React from "react";
import Drawer from "@material-ui/core/Drawer/Drawer";
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from "@material-ui/core/Divider/Divider";
import List from "@material-ui/core/List/List";
import Hidden from "@material-ui/core/Hidden/Hidden";
import {
  sideMenuNavigationList,
  sideMenuUserDetails
} from "./ProductsComponents/sideDrawerData";
import OrdersDatesRedirectComponent from "./redirects/OrdersDatesRedirectComponent";
import PaymentsRedirectComponent from "./redirects/PaymentsRedirectComponent";
import QuestionsListRedirectComponent from "./redirects/QuestionsListRedirectComponent";

class PermamantDrawerComponent extends React.Component {
  onPushOrdersScreen(context) {
    context.setState({
      localRedirectPushOrders: true
    });
  }

  onPushPaymentsScreen(context) {
    context.setState({
      localRedirectPushPayments: true
    });
  }

  onPushQuestionsScreen(context) {
    context.setState({
      localRedirectPushQuestions: true
    });
  }

  render() {
    const {
      context,
      classes,
      onPushOrdersScreen,
      onPushPaymentsScreen,
      onPushQuestionsScreen
    } = this.props;

    const sideList = (
      <div className={styles.list}>
        <List>{sideMenuUserDetails(context)}</List>
        <Divider />
        {/*
                In order to add a new item in the side menu:
                1. Open sideMenuNavigationList function and add it. Also pass an action for the onClick here, if needed.
                    Here this.props.onPushOrdersScreen will be executed, if specified. Otherwise, this.onPushOrdersScreen
                    will be executed.
                 */}
        <List>
          {sideMenuNavigationList(
            context,
            onPushOrdersScreen || this.onPushOrdersScreen,
            onPushPaymentsScreen || this.onPushPaymentsScreen,
            onPushQuestionsScreen || this.onPushQuestionsScreen
          )}
        </List>
      </div>
    );

    const onSideDrawerOpen = () => {
      console.log("old state ", context.state.isDrawerOpen);

      var isDrawerOpen = false;
      if (context.state.isDrawerOpen === false) {
        isDrawerOpen = true;
      } else {
        isDrawerOpen = false;
      }

      context.setState({
        isDrawerOpen: isDrawerOpen
      });

      console.log("toggle drawer; now should be ", context.state.isDrawerOpen);
    };

    return (
      <nav className={classes.drawer}>
        {/* 2. Add redirects here, if needed. */}
        <OrdersDatesRedirectComponent context={context} />
        <PaymentsRedirectComponent context={context} />
        <QuestionsListRedirectComponent context={context} />
        {/* 3. That is all. */}

        {/* The implementation can be swap with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            container={this.props.container}
            variant="temporary"
            anchor={"left"}
            open={context.state.isDrawerOpen}
            onClose={() => onSideDrawerOpen()}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {sideList}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {sideList}
          </Drawer>
        </Hidden>
      </nav>
    );
  }
}
const drawerWidth = 210;
const styles = theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  }
});

export default withStyles(styles)(PermamantDrawerComponent);
