import React from "react";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";

export default class DropDownComponent extends React.Component {
  getUnitId(value, allUnits) {
    for (var i = 0; i < allUnits.length; i++) {
      if (allUnits[i].name === value) {
        return allUnits[i].id;
      }
    }
    return 1;
  }

  render() {
    const { context } = this.props;
    let unitsItems = undefined;
    if (
      context.state.units === undefined ||
      typeof context.state.units !== "object"
    ) {
      unitsItems = (
        <MenuItem key={1} value={"кг"} id={1}>
          кг
        </MenuItem>
      );
    } else {
      try {
        unitsItems = context.state.units.map((s, i) => {
          let unitName = s.name;
          let unitDescription = s.description;

          if (unitName !== undefined && unitDescription !== undefined) {
            return (
              <MenuItem key={s.id} value={unitName} id={s.id}>
                {unitDescription}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem key={1} value={"кг"} id={1}>
                кг
              </MenuItem>
            );
          }
        });

        if (unitsItems.length === 0) {
          return (
            <MenuItem key={1} value={"кг"} id={1}>
              кг
            </MenuItem>
          );
        }
      } catch (e) {
        unitsItems = (
          <MenuItem key={1} value={"кг"} id={1}>
            кг
          </MenuItem>
        );
      }
    }

    return (
      <FormControl style={{ width: "100%" }}>
        <InputLabel style={{ width: "100%" }} htmlFor="age-required">
          Мерна единица
        </InputLabel>
        <Select
          value={context.state.entity}
          style={{ width: "100%" }}
          displayEmpty
          onChange={event => {
            console.log("event", event);
            if (context.state.units !== undefined) {
            }
            context.setState({ entity: event.target.value });
            context.setState({
              entity_id: this.getUnitId(event.target.value, context.state.units)
            });
          }}
          name="unit"
          inputProps={{
            name: "entity"
          }}
        >
          {unitsItems}
        </Select>
        <FormHelperText>Задължително поле</FormHelperText>
      </FormControl>
    );
  }
}
