import React from "react";
import { Redirect } from "react-router-dom";

export default class LogOutRedirectComponent extends React.Component {
  render() {
    const { context } = this.props;

    return context.state.localRedirectLogOut ? (
      <Redirect
        to={{
          pathname: process.env.PUBLIC_URL + "/"
        }}
      />
    ) : (
      <div />
    );
  }
}
