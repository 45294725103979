import React from "react";
import UserImage from "./UserImage";

export default class ImagesHorizontalComponent extends React.Component {
  render() {
    const {
      context,
      onClick,
      onPictureTaken,
      onImageChosenAction
    } = this.props;

    var imagesArray = [];
    if (context.state.imagesSource !== undefined) {
      for (var i = 0; i < context.state.imagesSource.length; i++) {
        imagesArray.push({
          original: context.state.imagesSource[i]["source"],
          thumbnail: context.state.imagesSource[i]["source"]
        });
      }
    }

    return (
      <div className="rows">
        <div
          key={0}
          className="row"
          style={{
            width: 80,
            height: 80,
            backgroundColor: "#6bc0bb",
            marginLeft: 8
          }}
        >
          <UserImage
            editable={true}
            onPictureTaken={onPictureTaken}
            onImageChosenAction={onImageChosenAction}
            getUserImage={() => {}}
            setUserImage={() => {}}
          />
        </div>

        {context.state.imagesSource !== undefined ? (
          context.state.imagesSource.map(item => (
            <div
              key={item.id}
              className="row"
              onClick={() => onClick(item.id)}
              style={{
                width: 80,
                height: 80,
                backgroundColor: "#c0c0c0",
                marginLeft: 8
              }}
            >
              <img
                alt={""}
                style={{ width: 80, height: 80 }}
                src={item.source.uri || item.source.image}
              />
            </div>
          ))
        ) : (
          <div />
        )}
      </div>
    );
  }
}
