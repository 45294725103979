import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import CroppingDialog from "./CroppingDialog";
import CameraIcon from "@material-ui/icons/CameraEnhance";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getDataUrl } from "./common";

let styles = {
  progress: {
    flexGrow: 1
  },

  fabProgress: {
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1
  },

  bigAvatar: {
    width: 80,
    height: 80
  },
  avatarWrapper: {
    width: 80,
    height: 80,
    margin: "0px auto",
    display: "inline-block",
    position: "relative"
  },
  newImage: {
    display: "none !important"
  },
  newImageLabel: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    opacity: 0.7,
    cursor: "pointer"
  },
  icon: {
    color: "#fff",
    position: "absolute",
    top: "28px",
    left: "28px",
    "label:hover &": {
      color: "#ddd"
    }
  }
};

class UserImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imgSrc: require("./../img/spinner.gif"),
      open: false,
      userImage: "",
      theInputKey: "",
      isLoading: false,
      newWidth: 0,
      isProcessing: false
    };

    this.closeDialog = this.closeDialog.bind(this);
    this.processNewImage = this.processNewImage.bind(this);
  }

  closeDialog() {
    this.setState({ open: false });
  }

  componentWillReceiveProps(nextProps) {
    //   console.log("type ", typeof nextProps.user.data.userImage)
    //   if (
    //        (typeof nextProps.user.data.userImage !== "undefined") &&
    //        (this.state.userImage === '')
    //    ) {
    //    this.setState({userImage: nextProps.user.data.userImage});
    //   }
  }

  processNewImage(imgSrc) {
    let canvas = document.createElement("canvas");
    var img = new Image();
    img.onload = function() {
      const width = this.width;
      const height = this.height;
      canvas.setAttribute("width", width);
      canvas.setAttribute("height", height);
      canvas.getContext("2d").drawImage(this, 0, 0, width, height);
      imgSrc = canvas.toDataURL("image/jpeg", "0.8");

      console.log("imeg src ", imgSrc);
    };
    img.src = imgSrc;
  }

  componentDidMount() {
    if (this.state.userImage === "") {
      if (this.props.userId) {
        this.setState({ isLoading: true });
      }
    }
  }

  onFileChanged = event => {
    event.persist();
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > 6000000) {
        this.setState({
          isSnackBarOpen: true,
          snackBarMessage: "Снимката е твърде голяма!"
        });

        return;
      }

      const me = this;

      getDataUrl(event.target.files[0], function(imgBase64) {
        const randomString = Math.random().toString(36);
        const theImage = new Image();

        theImage.onload = function() {
          const width = this.width;
          const height = this.height;

          let windowWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
          let windowHeight =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;

          if (windowWidth > 599) {
            windowWidth = windowWidth / 2 - 10;
          }

          windowHeight = windowHeight - 140;
          const ratio = height / width;

          let newWidth = windowHeight / ratio;
          if (newWidth > windowWidth) {
            newWidth = windowWidth;
          }

          me.setState({
            imgSrc: imgBase64,
            newWidth,
            open: true,
            theInputKey: randomString
          });
        };

        theImage.src = imgBase64;
      });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div
        className={classNames(classes.avatarWrapper)}
        style={{
          width: this.props.width || styles.bigAvatar.width,
          height: this.props.width || styles.bigAvatar.width
        }}
      >
        <Avatar
          src={this.state.userImage}
          style={{
            width: this.props.width || styles.bigAvatar.width,
            height: this.props.width || styles.bigAvatar.height
          }}
        />
        {this.props.editable ? (
          <label
            htmlFor={"newImage"}
            className={classNames(classes.newImageLabel)}
          >
            <CameraIcon className={classNames(classes.icon)} />
            <input
              type="file"
              accept="image/*"
              onChange={this.onFileChanged.bind(this)}
              id={"newImage"}
              className={classNames(classes.newImage)}
              key={this.state.theInputKey || ""}
            />
            <CroppingDialog
              imgSrc={this.state.imgSrc}
              open={this.state.open}
              onClose={this.closeDialog}
              onSave={this.props.onImageChosenAction}
              newWidth={this.state.newWidth}
              isProcessing={this.state.isProcessing}
            />
          </label>
        ) : null}

        {this.state.isLoading && (
          <CircularProgress
            size={this.props.width || 100}
            className={classes.fabProgress}
          />
        )}
      </div>
    );
  }
}

UserImage.propTypes = {
  classes: PropTypes.object.isRequired,
  getUserImage: PropTypes.func.isRequired,
  setUserImage: PropTypes.func.isRequired
};

export default withStyles(styles)(UserImage);
