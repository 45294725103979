import React from "react";
import { Redirect } from "react-router-dom";

export default class OrdersDatesRedirectComponent extends React.Component {
  render() {
    const { context } = this.props;
    return context.state.localRedirectPushOrders ? (
      <Redirect
        to={{
          pathname: process.env.PUBLIC_URL + "/orders/dates"
        }}
      />
    ) : (
      <div />
    );
  }
}
