import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const styles = theme => ({
  close: {
    padding: theme.spacing.unit / 2
  }
});

var contextGlob;
class SnackBarComponent extends React.Component {
  handleClose = (event, reason) => {
    if (contextGlob !== undefined) {
      contextGlob.setState({
        isSnackBarOpen: false
      });
    }

    if (reason === "clickaway") {
      return;
    }
  };

  /*
    Set state:
        - isSnackBarOpen
        - snackBarMessage
     */
  render() {
    const { context, classes } = this.props;
    contextGlob = context;
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={context.state.isSnackBarOpen}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{context.state.snackBarMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}

SnackBarComponent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SnackBarComponent);
