import React from "react";
import Checkbox from "@material-ui/core/Checkbox";

export default class BulkEditProductActiveComponent extends React.Component {
  render() {
    const { item, onSave } = this.props;

    var s = {
      checked:
        item.is_published === 1 || item.is_published === true ? true : false
    };

    return (
      <div>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (item.is_published === 1) {
              item.is_published = 0;
            } else {
              item.is_published = 1;
            }
            var isChecked = item.is_published ? true : false;
            item.is_published = isChecked ? 1 : 0;
            this.setState({ checked: item.is_published });
            console.log("check is now " + item.is_published);
            if (onSave !== undefined) {
              onSave();
            }
          }}
        >
          <Checkbox checked={s.checked} style={{ padding: 6 }} />

          <label style={{ cursor: "pointer" }}>{item.name}</label>
        </span>
      </div>
    );
  }
}
