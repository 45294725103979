import React from "react";
import { Redirect } from "react-router-dom";

export default class BackRedirectComponent extends React.Component {
  render() {
    const { context, whereTo, state } = this.props;
    let whereToEndpoint;

    if (whereTo !== undefined) {
      whereToEndpoint = whereTo;
    } else {
      whereToEndpoint = "products";
    }

    console.log("going bavk to ", whereTo, " with state ", state);

    return context.state.localRedirectBack ? (
      <Redirect
        to={{
          pathname: process.env.PUBLIC_URL + "/" + whereToEndpoint,
          state: state
        }}
      />
    ) : (
      <div />
    );
  }
}
