import Grid from "@material-ui/core/Grid/Grid";
import React from "react";
import ArrowForward from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import EditIcon from "@material-ui/icons/Edit";

export default class QuestionsTextComponent extends React.Component {
  render() {
    const { onOpenQuestions } = this.props;

    return (
      <div
        onClick={() => {
          console.log("open questins clicked");
          onOpenQuestions();
        }}
        style={{ cursor: "pointer", marginTop: -12 }}
      >
        <Grid item xs={12}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={11}>
              <h3>Въпроси и Отговори</h3>
            </Grid>
            <Grid item xs={1}>
              <IconButton aria-label="Edit">
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
