export const appName = '@HranKOOP:';

export const accessToken = appName + 'accessToken';

export const refreshToken = appName + 'refreshToken';

export const accessTokenProperty = 'access_token';

export const refreshTokenProperty = 'refresh_token';

export const productionUrl = "https://coop.hrankoop.com/";

export const stagingUrl = "http://188.166.160.199/";

export const url = productionUrl;

export const api = "api/";

export const v2 = "v2/";

export const storageImages = "storage/images/";

export const products = "products";

export const payments = "payments";

export const years = "years";

export const categories = "categories";

export const units = "units";

export const states = "states";

export const user = "user";

export const orders = "orders";

export const emails = "emails";

export const check = "check";

export const date = "date";

export const questions = "questions";

export const answers = "answers";

export const deliveryDates = "delivery_dates";

export const userUrl = url + api + user;

export const productUrl = url + api + products;

export const paymentsUrl = url + api + payments;

export const paymentsYearsUrl = url + api + payments + '/' + years;

export const productV2Url = url + api + v2 + products;

export const questionsUrl = url + api + questions;

export const categoriesUrl = url + api + categories;

export const unitsUrl = url + api + units;

export const bulkEditUrl = url + api + products + "/" + states;

export const ordersUrl = url + api + orders;

export const ordersForDateUrl = url + api + date + "/" + orders;

export const ordersDatesUrl = url + api + orders + "/" + deliveryDates;

export const emailsCheckUrl = url + api + emails + "/" + check;

export const uploadImages = "upload_images";

export const reorderImages = "reorder_images";

export const deleteImages = "delete_image";

export const PRODUCT = 'p';

export const screenUpdate = "screen_update";

export const categoriesUpdate = "categories_update";

export const descriptionUpdate = "description_update";

export const message = "message";

export const oAuth = "oauth/";

export const token = "token";

export const register = "register";

export const loginUrl = url + oAuth + token;

export const registerUrl = url + api + register;

export const unauthenticated = "Unauthenticated.";

export const clientId = 2;

export const clientSecret = "JC8ZyR37pOLWG00a41yTYEhuKODPsA5oHwtHEdxC";

export const grantTypePassword = "password";

export const grantTypeRefreshToken = "refresh_token";

export const allScope = "*";

export const bearer = "Bearer ";

export const favIconLink = "http://www.iconj.com/ico/7/r/7rz3ah5nay.ico";

export const taxDiscount = 0.2;

export const autorefreshInterval = 30 * 1000; //30 seconds * 1000 miliseconds

export const SET_USER = "SET_USER";

export const SIGNOUT_REQUEST = "SIGN_OUT";

export const ALL_PRODUCTS_TAB = 0;