import { SET_USER } from "../../../Constants";

export const initialState = {
  producerId: undefined,
  selectedProducer: undefined,
  producersAllData: undefined,
  producersImages: undefined,
  userName: undefined,
  userEmail: undefined
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      console.log("redux setting state to ", action.user);
      return Object.assign({}, state, action.user);
    default:
      return state;
  }
}
