import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import NavBarComponent from "./NavBarComponent";
import Transition from "react-transition-group/Transition";
import Dialog from "@material-ui/core/Dialog/Dialog";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid/Grid";

export default class ImageGalleryFullScreenComponent extends Component {
  render() {
    const {
      context,
      onCloseAction,
      onPicturesReordered,
      onPictureDeleted
    } = this.props;

    var imageItems = [];

    if (context.state.imagesSource !== undefined) {
      imageItems = context.state.imagesSource.map((s, i) => {
        let imageUri = s.source.uri;

        if (imageUri !== undefined) {
          return (
            <div key={s.id}>
              <img alt={""} src={imageUri} />

              <Grid
                style={{ position: "absolute", bottom: 80, color: "#fff" }}
                container
                spacing={24}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item md={4} xs={4}>
                  <div onClick={() => onPicturesReordered(i, s.id)}>
                    <p
                      style={{
                        borderRadius: "10%",
                        background: "rgb(0, 0, 0) none repeat scroll 0% 0%",
                        color: "#fff",
                        padding: 10,
                        fontSize: 12,
                        textAlign: "center",
                        opacity: 0.45,
                        transition: "",
                        cursor: "pointer"
                      }}
                    >
                      Премести отпред
                    </p>
                  </div>
                </Grid>
                <Grid item md={4} xs={4}>
                  <div
                    onClick={() => {
                      onPictureDeleted(i, s.id);
                    }}
                  >
                    <p
                      style={{
                        borderRadius: "10%",
                        background: "rgb(0, 0, 0) none repeat scroll 0% 0%",
                        color: "#fff",
                        padding: 10,
                        fontSize: 12,
                        textAlign: "center",
                        opacity: 0.45,
                        transition: "",
                        cursor: "pointer"
                      }}
                    >
                      Изтрий
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          );
        } else {
          return (
            <div key={1}>
              <p className="legend">Photo 1</p>
            </div>
          );
        }
      });
    }

    return (
      <Dialog
        maxWidth={"md"}
        open={context.state.isGalleryOpen}
        TransitionComponent={Transition}
      >
        <NavBarComponent title={"Снимки"} onCloseClicked={onCloseAction} />

        <Carousel
          centerMode={imageItems.length > 1}
          centerSlidePercentage={imageItems.length > 1 ? 50 : 100}
          emulateTouch
          showArrows={true}
          showThumbs={true}
          showStatus={true}
          infiniteLoop
          useKeyboardArrows
          selectedItem={context.state.selectedItem}
          swipeable
        >
          {imageItems}
        </Carousel>
      </Dialog>
    );
  }
}

withStyles(document.querySelector(".demo-carousel"))(
  ImageGalleryFullScreenComponent
);
