import React from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import DeleteIconComponent from "./DeleteIconComponent";
import Grid from "@material-ui/core/Grid/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden/Hidden";
import VariationQuantityInput from "./Inputs/VariationQuantityInput";
import VariationPriceInput from "./Inputs/VariationPriceInput";
import VariationDeliveryPriceInput from "./Inputs/VariationDeliveryPriceInput";
import VariationDiscountPriceInput from "./Inputs/VariationDiscountPriceInput";
import DividerComponent from "./DividerComponent";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  inputs: {
    width: "100%"
  },
  deleteVariation: {
    cursor: "pointer",
    [theme.breakpoints.up("lg")]: {
      marginTop: 12
    }
  }
});

class VariantionsTableComponent extends React.Component {
  render() {
    const { classes, removeItem, state, context } = this.props;

    let count = 1;
    return context.state.dataSource.map(itemToMap => (
      <ValidatorForm
        key={itemToMap.id}
        ref="form"
        onSubmit={() => {
          console.log("sumbit");
        }}
        onError={errors => console.log(errors)}
      >
        <div key={itemToMap.id} style={{ margin: 12 }}>
          <Grid container styles={styles.root} direction={"row"} spacing={24}>
            <Hidden mdDown>
              <Grid item lg={1}>
                <p style={{ marginTop: 26, marginBottom: 0 }}>{count++}.</p>
              </Grid>
            </Hidden>

            <Grid item lg={10}>
              <Grid container spacing={24}>
                <Grid item lg={3} sm={6} xs={12}>
                  <VariationQuantityInput
                    context={context}
                    itemToMap={itemToMap}
                    classes={classes}
                    updateItem={this.updateItem}
                  />
                </Grid>

                <Grid item lg={3} sm={6} xs={12}>
                  <VariationPriceInput
                    context={context}
                    itemToMap={itemToMap}
                    classes={classes}
                    updatePrice={this.updatePrice}
                  />
                </Grid>

                <Grid item lg={3} sm={6} xs={12}>
                  <VariationDiscountPriceInput
                    context={context}
                    itemToMap={itemToMap}
                    classes={classes}
                    updateSalePrice={this.updateSalePrice}
                  />
                </Grid>

                <Grid item lg={3} sm={6} xs={12}>
                  <VariationDeliveryPriceInput
                    context={context}
                    itemToMap={itemToMap}
                    classes={classes}
                    updatePrice={this.updatePrice}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Hidden mdDown>
              <Grid item lg={1}>
                <DeleteIconComponent
                  classes={classes}
                  index={itemToMap.id}
                  elements={state.dataSource}
                  onPressAction={removeItem}
                />
              </Grid>
            </Hidden>

            <Hidden lgUp>
              <Grid item xs={8} sm={6} md={6}>
                <Grid
                  container
                  direction={"row"}
                  spacing={8}
                  alignItems="center"
                >
                  {state.dataSource.length > 1 && (
                    <Grid item>
                      <span>Изтриване:</span>
                    </Grid>
                  )}
                  <Grid item>
                    <DeleteIconComponent
                      classes={classes}
                      index={itemToMap.id}
                      elements={state.dataSource}
                      onPressAction={removeItem}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
          <Hidden lgUp implementation="css">
            <DividerComponent />
          </Hidden>
        </div>
      </ValidatorForm>
    ));
  }

  updateItem(item, num, state, context) {
    item["measurement"] = num;
    console.log("list updated ", state.dataSource);
    console.log("list updated item ", item);
    context.setState({ dataSource: state.dataSource });
  }

  updatePrice(item, num, state, context) {
    item["price"] = num;
    console.log("list updated ", state.dataSource);
    console.log("list updated item ", item);
    context.setState({ dataSource: state.dataSource });
  }

  updateSalePrice(item, num, state, context) {
    item["sale_price"] = num;
    console.log("list updated ", state.dataSource);
    console.log("list updated item ", item);
    context.setState({ dataSource: state.dataSource });
  }
}

export default withStyles(styles)(VariantionsTableComponent);
