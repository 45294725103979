import React from "react";
import Icon from "@material-ui/core/Icon/Icon";

export default class CategoriesDisplay extends React.Component {
  isSelected(id, selectedCategories) {
    var size = selectedCategories.length;

    for (var i = 0; i < size; i++) {
      if (id === selectedCategories[i]) {
        return true;
      }
    }

    return false;
  }

  render() {
    const { context, onOpenCategories } = this.props;

    var categories = "";
    if (context.state.bulkdDataSource !== undefined) {
      context.state.bulkdDataSource.forEach(category => {
        if (this.isSelected(category["id"], context.state.selectedCategories)) {
          categories += category["name"] + ", ";
        }
      });
    }

    categories = categories.substring(0, categories.length - 2);

    return (
      <div style={{ cursor: "pointer" }} onClick={() => onOpenCategories()}>
        <div>
          <h3 style={{ marginBottom: -9 }}>Категории</h3>

          <div style={{ flexDirection: "row" }}>
            <p style={{ flex: 0.95 }}>
              {categories !== ""
                ? categories
                : "Изберете категории за продукта"}
            </p>

            <Icon style={{ flex: 0.05 }} name="keyboard-arrow-right" />
          </div>
        </div>
      </div>
    );
  }
}
