import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Input from "@material-ui/core/Input/Input";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";

export default class VariationDeliveryPriceInput extends React.Component {
  calcPrice(context, itemToMap, paramName) {
    return (
      Number(itemToMap[[paramName]].toString().replace(",", ".")) *
      ((100 - context.state.producerPercent) / 100)
    ).toFixed(2);
  }

  render() {
    const { context, itemToMap, classes, updatePrice } = this.props;

    return (
      <div>
        <FormHelperText htmlFor="sale-price-input">
          Доставна цена
        </FormHelperText>

        <Input
          id="sale-price-input"
          value={
            itemToMap.sale_price === null ||
            itemToMap.sale_price === undefined ||
            itemToMap.sale_price === -1 ||
            itemToMap.sale_price === "-1" ||
            itemToMap.sale_price === ""
              ? //consider the normal price
                itemToMap.price !== undefined &&
                itemToMap.price !== -1 &&
                itemToMap.price !== "-1"
                ? !isNaN(itemToMap.price.toString().replace(",", "."))
                  ? this.calcPrice(context, itemToMap, "price")
                  : "NaN"
                : ""
              : itemToMap.sale_price !== -1 && itemToMap.sale_price !== "-1"
              ? !isNaN(itemToMap.sale_price.toString().replace(",", "."))
                ? this.calcPrice(context, itemToMap, "sale_price")
                : "NaN"
              : ""
          }
          name={"доставна цена"}
          className={classes.inputs}
          disabled
          validators={["minNumber:0.01", "maxNumber:999999"]}
          maxLength={6}
          onChange={event =>
            updatePrice(itemToMap, event.target.value, context.state, context)
          }
          endAdornment={
            <InputAdornment position="end">
              <p style={{ color: "gray" }}>лв</p>
            </InputAdornment>
          }
        />
      </div>
    );
  }
}
