import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { Cropper } from "react-image-cropper";
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = {
  appBar: {
    position: "relative"
  },

  flex: {
    flex: 1
  },

  cropImageDialog: {
    zIndex: 99999
  },

  mainWrapper: {
    marginTop: 30,
    marginBottom: 30
  }
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class CroppingDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imgSrc: "",
      image: "",
      imageLoaded: false,
      open: false,
      newWidth: 0,
      isProcessing: false
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    let state = {
      imgSrc: nextProps.imgSrc,
      newWidth: nextProps.newWidth,
      open: nextProps.open,
      isProcessing: nextProps.isProcessing
    };

    if (nextProps.imgSrc !== this.state.imgSrc) {
      state = { ...state, image: "" };
    }

    this.setState(state);
  }

  handleImageLoaded(state) {
    this.setState({
      [state + "Loaded"]: true
    });
  }

  handleClose() {
    if (this.props.onClose) {
      this.props.onClose();
    } else {
      this.setState({ image: "", open: false });
    }
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  handleSave() {
    if (this.props.onSave) {
      this.setState({ isProcessing: true });

      let node = this["image"];

      console.log("node ", node.crop());

      let canvas = document.createElement("canvas");
      var img = new Image();

      const me = this;

      img.onload = function() {
        const width = this.width;
        const height = this.height;
        canvas.setAttribute("width", width);
        canvas.setAttribute("height", height);
        canvas.getContext("2d").drawImage(this, 0, 0, width, height);
        me.props.onSave(canvas.toDataURL("image/jpeg", "0.8"));
        me.handleClose();
      };
      img.src = node.crop();
    } else {
      this.handleClose();
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Dialog
        fullScreen
        open={this.state.open}
        onClose={this.handleClose}
        TransitionComponent={Transition}
        className={classes.cropImageDialog}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={this.handleClose}
              className={"close-button"}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              Crop image
            </Typography>
            <Button
              color="inherit"
              onClick={this.handleSave}
              className={"save-button"}
            >
              Save
            </Button>
          </Toolbar>
        </AppBar>

        <Grid
          container
          alignItems="center"
          direction="row"
          justify="center"
          className={classes.mainWrapper}
        >
          <Grid item xs={12} className="linearProgressHeight">
            {this.state.isProcessing || !this.state.imageLoaded ? (
              <LinearProgress />
            ) : null}
          </Grid>

          <Grid item sm={6} align="center">
            <div style={{ width: this.state.newWidth }}>
              <Cropper
                src={this.state.imgSrc}
                ref={ref => {
                  this.image = ref;
                }}
                onImgLoad={() => this.handleImageLoaded("image")}
                allowNewSelection={false}
              />
            </div>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

CroppingDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  imgSrc: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func
};

export default withStyles(styles)(CroppingDialog);
