import React from "react";
import { Redirect } from "react-router-dom";

export default class PaymentsRedirectComponent extends React.Component {
  render() {
    const { context } = this.props;
    return context.state.localRedirectPushPayments ? (
      <Redirect
        to={{
          pathname: process.env.PUBLIC_URL + "/payments"
        }}
      />
    ) : (
      <div />
    );
  }
}
