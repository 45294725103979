import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import NavBarComponent from "./NavBarComponent";
import BulkEditProductsTableComponent from "./BulkEditComponents/BulkEditProductsTableComponent";
import SaveIcon from "@material-ui/icons/Save";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";

function categoriesDisplay(props) {
  const { context, classes, onDialogClose, onFilterSearch, onBulkSave } = props;

  return (
    <div>
      <Dialog
        fullScreen
        open={context.state.isCategoriesChooserOpen}
        TransitionComponent={Transition}
      >
        <div>
          <NavBarComponent
            onFilterSearch={onFilterSearch}
            isSideMenuNeeded={false}
            onCloseClicked={onDialogClose}
            onClickAction={onBulkSave}
            onClickText={"Потвърди"}
            title={"Категории"}
            actionIcon={<SaveIcon />}
          />
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <BulkEditProductsTableComponent state={context.state} />
            </Paper>
          </main>
        </div>
      </Dialog>
    </div>
  );
}

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => ({
  layout: {
    width: "auto",
    display: "block", // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {
    margin: theme.spacing.unit
  },
  textField: {
    flexBasis: 200
  }
});

export default withStyles(styles)(categoriesDisplay);
