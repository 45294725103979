import React from "react";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 160
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  }
});

class PaymentsDropDownComponent extends React.Component {
  handleChange = name => event => {
    const { context } = this.props;

    console.log("change", name, event.target.value);
    this.props.context.setState({ [name]: event.target.value });

    context.setState(
      {
        loadYears: false
      },
      () => {
        this.props.getData();
      }
    );
  };

  render() {
    const { classes, context } = this.props;

    let yearsMenuItems = context.state.years.map((year, index) => {
      return (
        <MenuItem key={index} value={year}>
          {year}
        </MenuItem>
      );
    });

    return context.state.year &&
      context.state.years &&
      context.state.years.length > 0 ? (
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple">Справка за година</InputLabel>
          <Select
            value={context.state.year}
            onChange={this.handleChange("year")}
          >
            {yearsMenuItems}
          </Select>
        </FormControl>
      </div>
    ) : (
      <div />
    );
  }
}

export default withStyles(styles)(PaymentsDropDownComponent);
