import React from "react";

import {
  accessToken,
  productUrl,
  unauthenticated,
  message,
  favIconLink,
  questions
} from "../Constants";
import NetworkConfig from "../config/NetworkConfig";
import { read_cookie } from "sfcookies";
import AuthUtil from "../utils/AuthUtil";
import Favicon from "react-favicon";
import QuestionsComponent from "../components/QuestionsComponent";
import Hidden from "@material-ui/core/Hidden/Hidden";
import NavBarComponent from "../components/NavBarComponent";
import PermamantDrawerComponent from "../components/PermamantDrawerComponent";
import SaveIcon from "@material-ui/icons/Save";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import BackButtonHandler from "../utils/BackButtonHandler";
import StateSaverUtil from "../utils/StateSaverUtil";
import { store } from "../store";

export default class QuestionsForProductScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectFromList: "products/questions",
      isLoaded: false,
      productId:
        this.props.location.state !== undefined
          ? this.props.location.state.product_id
          : undefined,
      questionsData: [],

      selectedProducer: store.getState().selectedProducer,
      producersImages: store.getState().producersImages,
      userName: store.getState().userName,
      userEmail: store.getState().userEmail,

      producerId: store.getState().producerId,
      productName:
        this.props.location.state !== undefined
          ? this.props.location.state.productName
          : undefined,
      producersAllData: store.getState().producersAllData
    };

    this.propsToRestore = ["productId", "productName"];

    AuthUtil.redirectUnauthenticated();

    this.getData = this.getData.bind(this);
    this.onBackArrowClicked = this.onBackArrowClicked.bind(this);
    this.getQuestions = this.getQuestions.bind(this);
    this.openDrawer = this.openDrawer.bind(this);
    this.openQuestionAnswers = this.openQuestionAnswers.bind(this);
  }

  componentDidMount() {
    BackButtonHandler.handle(this, this.onBackArrowClicked);
    StateSaverUtil.save(this, this.propsToRestore);
    StateSaverUtil.restoreState(this, this.propsToRestore);

    this.getData();
  }

  componentWillUnmount() {
    StateSaverUtil.deleteSavedProps(this, this.propsToRestore);
  }

  async getData() {
    this.getQuestions();
    console.log("state after being restored ", this.state);
  }

  onBackArrowClicked() {
    this.setState({
      localRedirectBack: true
    });
  }

  openQuestionAnswers(id, title) {
    this.setState({
      questionId: id,
      questionTitle: title,
      localRedirectAnswer: true
    });
  }

  async getQuestions() {
    let that = this;
    try {
      const value = read_cookie(accessToken);

      var questionsUrl =
        productUrl + "/" + that.state.productId + "/" + questions;

      console.log("requesting ", questionsUrl);
      return fetch(questionsUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: value
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log("response json user ", responseJson);

          if (
            responseJson.hasOwnProperty(message) &&
            responseJson.message === unauthenticated
          ) {
            NetworkConfig.refreshRequest(this.getUserInfo, that);
          } else {
            console.log("responseJson ", responseJson);
            that.setState({
              isLoaded: true,
              questionsData: responseJson
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    } catch (e) {}
  }

  openDrawer() {
    console.log("old state ", this.state.isDrawerOpen);

    var isDrawerOpen = false;
    if (this.state.isDrawerOpen === false) {
      isDrawerOpen = true;
    } else {
      isDrawerOpen = false;
    }

    this.setState({
      isDrawerOpen: isDrawerOpen
    });

    console.log("toggle drawer; now should be ", this.state.isDrawerOpen);
  }

  render() {
    let context = this;

    return (
      <div>
        <Favicon url={favIconLink} />
        <Hidden smDown implementation="css">
          <div style={{ marginLeft: 210 }}>
            <NavBarComponent
              onClickAction={this.onSaveItem}
              onClickText={"Запази"}
              title={"Въпроси за " + context.state.productName}
              onBackArrowClicked={this.onBackArrowClicked}
              isSideMenuNeeded={false}
              actionIcon={<SaveIcon />}
            />
          </div>
        </Hidden>

        <Hidden mdUp implementation="css">
          <div style={{ marginLeft: 0 }}>
            <NavBarComponent
              onClickAction={this.onSaveItem}
              title={"Въпроси за " + context.state.productName}
              onBackArrowClicked={this.onBackArrowClicked}
              isSideMenuNeeded={true}
              onSideDrawerOpen={this.openDrawer}
            />
          </div>
        </Hidden>

        {!context.state.isLoaded && <LinearProgress />}

        <PermamantDrawerComponent context={context} />

        <Hidden smDown implementation="css">
          <div style={{ marginLeft: 230 }}>
            <QuestionsComponent
              context={this}
              openQuestionAnswers={this.openQuestionAnswers}
            />
          </div>
        </Hidden>

        <Hidden mdUp implementation="css">
          <div style={{ marginLeft: 20 }}>
            <QuestionsComponent
              context={this}
              openQuestionAnswers={this.openQuestionAnswers}
            />
          </div>
        </Hidden>
      </div>
    );
  }
}
