import CreateProductScreen from "./CreateProductScreen";
import {
  productUrl,
  url,
  uploadImages,
  accessToken,
  message,
  unauthenticated,
  unitsUrl,
  deleteImages,
  reorderImages,
  productV2Url
} from "../Constants";
import NetworkConfig from "./../config/NetworkConfig";
import { read_cookie } from "sfcookies";
import StateSaverUtil from "../utils/StateSaverUtil";
import { store } from "../store";

export default class EditItemScreen extends CreateProductScreen {
  constructor(props) {
    super(props);

    this.idJustEdited = -1;
    this.globalAccessToken = "";

    this.id = 1;
    this.index = -1;
    this.list = [
      {
        id: this.id++,
        quantity: -1,
        measurement: -1,
        price: -1,
        sale_price: -1
      }
    ];

    this.selectedCategories = [];
    this.imagesSource = [];
    this.newImages = [];

    this.state = {
      isLoaded: false,
      description: "",
      imagesSource: this.imagesSource,
      units: ["kg", "l"],
      unitPrice: 0,
      unitSalePrice: 0,
      isProductLoading: true,
      isWaitingForProduct: true,
      dataSource: [],
      selectedCategories: [],
      isProductLoaded: false,
      isDrawerOpen: false,
      isGalleryOpen: false,
      isCategoriesChooserOpen: false,
      isDescriptionDialogOpen: false,
      selectedProducer: store.getState().selectedProducer,
      producerId: store.getState().producerId,
      navBarTitle: "Редакция на продукт",
      isReorderDialogOpen: false,
      closeReorderDialog: () => {},
      onReorderAction: () => {},
      isDeleteDialogOpen: false,
      closeDeleteDialog: () => {},
      onDeleteAction: () => {},
      isEditing: true,
      localRedirectQuestions: false,
      onOpenQuestions: () => {},
      producerPercent:
        store.getState() !== null &&
        store.getState().producersAllData !== undefined &&
        store.getState().producersAllData[store.getState().selectedProducer] !==
          undefined
          ? store.getState().producersAllData[store.getState().selectedProducer]
              .percent
          : null,
      itemId:
        this.props.location.state !== undefined
          ? this.props.location.state.itemId
          : undefined
    };

    this.propsToRestore = ["itemId"];

    this.getData = this.getData.bind(this);
    this.onSaveItem = this.onSaveItem.bind(this);
    this.loadProductInfo = this.loadProductInfo.bind(this);
    this.loadUnits = this.loadUnits.bind(this);
    this.onPictureDelete = this.onPictureDelete.bind(this);
    this.onImageDeleteRequest = this.onImageDeleteRequest.bind(this);
    this.reorderRequest = this.reorderRequest.bind(this);
    this.onPrepareUpload = this.onPrepareUpload.bind(this);
    this.onOpenQuestions = this.onOpenQuestions.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = "Редактирай продукт - КООП";

    this.setState({
      dataSource: this.list,
      entity: "кг"
    });

    StateSaverUtil.save(this, this.propsToRestore);

    this.getData();

    this.setState({
      onOpenQuestions: this.onOpenQuestions
    });
  }

  async getData() {
    await super.getData();
    await StateSaverUtil.restoreState(this, this.propsToRestore);
    this.loadUnits();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    StateSaverUtil.deleteSavedProps(this, this.propsToRestore);
  }

  onOpenQuestions() {
    this.setState({
      localRedirectQuestions: true
    });
    /*history.push({
                pathname:  process.env.PUBLIC_URL + '/products/questions',
                state: {product_id: this.props.location.state.itemId,
                    selectedProducer: this.state.selectedProducer,
                    producersImages: this.state.producersImages,
                    userName: this.state.userName,
                    userEmail: this.state.userEmail,
                    producerId: this.state.producersAllData[this.state.selectedProducer].producer_id,
                    productName: this.state.name
                }

            }
        ); */
  }

  async loadUnits() {
    let that = this;
    try {
      const value = read_cookie(accessToken);
      fetch(unitsUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: value
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          if (
            responseJson.hasOwnProperty(message) &&
            responseJson.message === unauthenticated
          ) {
            NetworkConfig.refreshRequest(this.loadUnits, that);
          } else {
            this.setState({
              units: responseJson
            });

            this.loadProductInfo();
          }
        })
        .catch(error => {
          console.error(error);
        });
    } catch (e) {}
  }

  async loadProductInfo() {
    let that = this;

    console.log("request to ", productUrl + "/" + this.state.itemId);

    //fetch product information
    try {
      const value = read_cookie(accessToken);
      return fetch(productUrl + "/" + this.state.itemId, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: value
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          //    this.selectedCategories.push(responseJson.category_id);

          if (
            responseJson.hasOwnProperty(message) &&
            responseJson.message === unauthenticated
          ) {
            NetworkConfig.refreshRequest(this.loadProductInfo, that);
          } else {
            console.log(
              "load product edit screen response json ",
              responseJson
            );

            for (var i = 0; i < responseJson.variations.length; i++) {
              if (
                responseJson.variations[i].sale_price === null ||
                responseJson.variations[i].sale_price === 0
              ) {
                responseJson.variations[i].sale_price = undefined;
              }
            }

            this.list = responseJson.variations;

            console.log("variations json response ", responseJson.variations);
            console.log(
              "selected_categories json response ",
              responseJson.selected_categories
            );
            this.imagesSource = [];
            this.setState({
              imagesSource: this.imagesSource
            });

            for (i = 0; i < responseJson.images.length; i++) {
              //TODO: Remove when on staging
              let link = url + responseJson.images[i];
              console.log("image link " + link);

              this.imagesSource.push({
                id: this.id++,
                source: { uri: link }
              });
              this.setState({ imagesSource: this.imagesSource });
            }

            /*if adding new variations, their local id will be unique as well
                        here we set the local id to be the id of the last item + 1 (api orders
                        them in an ascending order by id */
            this.id =
              responseJson.variations[responseJson.variations.length - 1].id +
              1;

            var unitIndex = 0;
            for (i = 0; i < this.state.units.length; i++) {
              if (this.state.units[i].id === responseJson.unit_id) {
                unitIndex = i;
              }
            }
            console.log("index unit ", unitIndex);
            var imageNames = [];
            try {
              for (const [key, value] of Object.entries(
                responseJson.image_path
              )) {
                console.log("key value image paths ", key, value);
                imageNames.push(value);
              }
            } catch (e) {
              console.log(
                "exception at parsing image paths, probably empty ",
                e
              );
            }

            let that = this;
            this.setState(
              {
                selectedCategories: responseJson.selected_categories,
                name: responseJson.name,
                promptVisible: false,
                description: responseJson.description,
                newDescription: responseJson.description,
                entity: responseJson.unit,
                entity_id: responseJson.unit_id,
                isPersonalisable: responseJson.custom_packaging,
                dataSource: responseJson.variations,
                unitPrice: responseJson.custom_price,
                unitSalePrice: responseJson.custom_sale_price,
                entityFull: this.state.units[unitIndex].description,
                isProductLoading: false,
                imagePaths: imageNames,
                isProductLoaded: true,
                isLoaded: true,
                isProductPublished: responseJson.is_published
              },
              function() {
                console.log("this state edit screen ", that.state);
              }
            );
            this.onCategoriesDialogClose();
          }
        })
        .catch(error => {
          console.error(error);
        });
    } catch (e) {}
  }

  async onSaveItem() {
    let that = this;

    var variationsNewList = [];

    //replace "," with ".", if any
    for (var i = 0; i < this.list.length; i++) {
      var variationObject = this.list[i];
      variationObject["measurement"] = variationObject["measurement"]
        .toString()
        .replace(",", ".");
      variationObject["price"] = variationObject["price"]
        .toString()
        .replace(",", ".");

      if (variationObject["sale_price"] === "") {
        variationObject["sale_price"] = 0;
      }

      variationsNewList.push(variationObject);
    }

    console.log("this.list variationsNewList ", variationsNewList);
    console.log(
      "this.list varaitions to delete ",
      this.state.variationsDeleted
    );

    console.log("Selected categories " + this.state.selectedCategories);
    console.log("Name " + this.state.name);
    console.log("Entity id " + this.state.entity_id);
    console.log("is product published " + this.state.isProductPublished);

    var unitPrice = "";
    console.log("this.state.unitPrice ", this.state.unitPrice);
    if (this.state.unitPrice !== undefined && this.state.unitPrice !== null) {
      unitPrice = this.state.unitPrice.toString().replace(",", ".");
    }

    var unitSalePrice = "";
    console.log("this.state.unitSalePrice ", this.state.unitSalePrice);
    if (
      this.state.unitSalePrice !== undefined &&
      this.state.unitSalePrice !== null
    ) {
      unitSalePrice = this.state.unitSalePrice.toString().replace(",", ".");
    }

    if (this.isValid(variationsNewList)) {
      try {
        const value = read_cookie(accessToken);
        this.globalAccessToken = accessToken;

        let data = {
          method: "PUT",
          credentials: "same-origin",
          body: JSON.stringify({
            name: this.state.name,
            description: this.state.description,
            entity_id: this.state.entity_id,
            categories: this.state.selectedCategories,
            custom_packaging: this.state.isPersonalisable,
            custom_price: unitPrice,
            custom_sale_price: unitSalePrice,
            slug: this.state.name,
            is_published: this.state.isProductPublished,
            variations: variationsNewList,
            deleteVariations: this.state.variationsDeleted
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: value
          }
        };

        console.log(
          "put request for prod sent to  " +
            productUrl +
            "/" +
            this.state.itemId +
            " with " +
            data
        );

        return fetch(productV2Url + "/" + this.state.itemId, data)
          .then(response => response.json())
          .then(responseJson => {
            console.log("refresh request");
            if (
              responseJson.hasOwnProperty(message) &&
              responseJson.message === unauthenticated
            ) {
              NetworkConfig.refreshRequest(this.onSaveItem, that);
            } else {
              console.log("resp after edit " + responseJson.name);
              this.idJustEdited = this.props.location.search.id;

              that.loadProductInfo();
              that.setState({
                variationsDeleted: []
              });
            }
          })
          .then(
            that.setState({
              isSnackBarOpen: true,
              snackBarMessage: "Променено успешно!"
            })
          );
      } catch (e) {}
    }
  }

  isValid(variations) {
    var isDataValid = true;
    var errorMessage = "";

    console.log("is personalisable " + this.state.isPersonalisable);
    console.log("unitPrice " + this.state.unitPrice);
    if (
      (this.state.isPersonalisable === 1 ||
        this.state.isPersonalisable === true) &&
      this.state.unitPrice <= 0
    ) {
      errorMessage = "Изберете единична цена";
      isDataValid = false;
    }

    console.log("unitSalePrice " + this.state.unitSalePrice);
    if (
      (this.state.isPersonalisable === 1 ||
        this.state.isPersonalisable === true) &&
      this.state.unitSalePrice < 0
    ) {
      errorMessage = "Изберете единична намалена цена";
      isDataValid = false;
    }

    if (variations.length <= 0) {
      errorMessage = "Въведете поне една разфасовка";
      isDataValid = false;
    }

    for (var i = 0; i < variations.length; i++) {
      if (
        Number(variations[i].measurement) <= 0.0 ||
        Number(variations[i].price) <= 0.0 ||
        Number(variations[i].measurement) > 999999 ||
        Number(variations[i].price) > 999999
      ) {
        errorMessage =
          "Количеството и цената трябва да са по-големи от 0 и по-малки от 999 999";
        isDataValid = false;
        break;
      }
    }

    for (i = 0; i < variations.length; i++) {
      if (isNaN(variations[i].measurement) || isNaN(variations[i].price)) {
        errorMessage =
          "Въвели сте навалидно число на ред " +
          (Number(i) + 1) +
          " за разфасовки.";
        isDataValid = false;
        break;
      }
    }

    try {
      if (
        this.state.description !== undefined &&
        this.state.description !== ""
      ) {
        var newDescription = this.state.description;
        var find = "\n";
        var re = new RegExp(find, "g");

        newDescription = newDescription.replace(re, "</br>");

        console.log("we are testing description ", newDescription);
        //var descrRegex = /^[A-Za-zА-Яа-я0-9 \/<>\-,.'\n]{1,60000}$/;
        //  if (this.state.description != undefined && !descrRegex.test(newDescription)) {
        //     errorMessage = "Описанието на продукт не може да е повече от 60000 символа и трябва да използвате само букви, цифри, .,-'";
        //     isDataValid = false;
        //  }
      }
    } catch (e) {
      console.log("exc in validation in CreateProductScreen ", e);
    }

    if (this.state.selectedCategories.length <= 0) {
      errorMessage = "Изберете категории";
      isDataValid = false;
    }

    console.log("name is now ", this.state.name);
    try {
      var regex = /^([A-Za-zА-Яа-я0-9 \-,.%']{1,60})$/;
      if (
        this.state.name !== undefined &&
        this.state.name !== "" &&
        !regex.test(this.state.name)
      ) {
        errorMessage =
          "За името на продукт може да използвате само букви, цифри, %.,-'";
        isDataValid = false;
      }

      if (this.state.name === undefined || this.state.name === "") {
        errorMessage = "Въведете име";
        isDataValid = false;
      }
    } catch (e) {
      errorMessage = "Въведете име";
      isDataValid = false;
      console.log(e);
    }

    console.log("checks passed ", isDataValid);

    if (!isDataValid) {
      this.setState({
        isSnackBarOpen: true,
        snackBarMessage: errorMessage
      });
    }
    return isDataValid;
  }

  onImageUpload(id, accessToken) {
    let that = this;
    if (id === undefined) {
      id = this.idJustEdited;
    }
    if (accessToken === undefined) {
      accessToken = this.globalAccessToken;
    }

    if (id !== undefined && this.newImages.length > 0) {
      var formData = new FormData();
      for (var i = 0; i < this.newImages.length; i++) {
        console.log("trying to convert to blob ", this.newImages[i].source.uri);
        //   var base64 = require('base-64');
        //  var decodedData = base64.decode(newImages[i].source.uri);
        // Split the base64 string in data and contentType
        var ImageURL = this.newImages[i].source.uri;

        var block = ImageURL.split(";");

        console.log("block ", block);
        // Get the content type of the image
        var contentType = block[0].split(":")[1]; // In this case "image/gif"
        // get the real base64 content of the file
        var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

        console.log("real data ", realData);

        // Convert it to a blob to upload
        var blob = this.b64toBlob(realData, contentType);

        console.log("blob ", blob);

        formData.append("image_path[]", blob);
      }

      console.log("request to " + productUrl + "/" + id + "/" + uploadImages);
      return fetch(productUrl + "/" + id + "/" + uploadImages, {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
          Authorization: accessToken
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          if (
            responseJson.hasOwnProperty(message) &&
            responseJson.message === unauthenticated
          ) {
            NetworkConfig.refreshRequest(this.onImageUpload, that);
          } else {
            console.log("!!!image upload api json ");
            console.log(responseJson);
            this.newImages = [];

            //write the new image name
            var imageNames = [];
            try {
              for (const [key, value] of Object.entries(responseJson.images)) {
                console.log("key value images after uploading ", key, value);
                imageNames.push(value);
              }
            } catch (e) {
              console.log(
                "exception at parsing image paths, probably empty ",
                e
              );
            }
            this.setState({
              imagePaths: imageNames
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  onPictureDelete(index, id) {
    const c = this;
    this.setState({
      isDeleteDialogOpen: true,

      closeDeleteDialog: () => {
        console.log("closing dialog");
        c.setState({ isDeleteDialogOpen: false });
      },

      onDeleteAction: async () => {
        console.log(this.newImages);
        console.log("this state images source ", this.state.imagesSource);

        var oldImages = this.state.imagesSource;
        if (oldImages === undefined) {
          oldImages = [];
        }

        console.log("index ", index);
        console.log("old images length ", oldImages.length);
        console.log("new images length ", this.newImages.length);

        //this is an old picture, and the delete api should be called
        console.log("an old image has been clicked ");
        //delete from the global array
        var newGlobalImages = [];
        for (var i = 0; i < oldImages.length; i++) {
          if (i !== index) {
            newGlobalImages.push(oldImages[i]);
          }
        }
        c.setState({
          imagesSource: newGlobalImages
        });

        this.newImages = newGlobalImages;

        this.index = index;

        await this.onImageDeleteRequest();
        c.setState({ isDeleteDialogOpen: false });
      }
    });
  }

  onPictureToFront(index, id) {
    const c = this;
    this.setState({
      isReorderDialogOpen: true,

      closeReorderDialog: () => {
        console.log("closing dialog");
        c.setState({ isReorderDialogOpen: false });
      },

      onReorderAction: async () => {
        console.log(this.newImages);
        console.log("this state images source ", this.state.imagesSource);
        console.log("this state images paths ", this.state.imagePaths);

        var oldImages = this.state.imagesSource;
        if (oldImages === undefined) {
          oldImages = [];
        }

        console.log("index ", index);
        console.log("old images length ", oldImages.length);
        console.log("new images length ", this.newImages.length);

        //this is an old picture, and the reorder single item api should be called
        console.log("an old image has been clicked ");
        //delete from the global array
        var images = this.state.imagesSource;
        images[0] = images.splice(index, 1, images[0])[0];

        var newPaths = this.state.imagePaths;
        var oldItem = newPaths[0];
        newPaths[0] = newPaths[index];
        newPaths[index] = oldItem;

        console.log("I have just swapped two items imagesSource: ", images);
        console.log("I have just swapped two items imagePaths: ", newPaths);

        this.setState({
          imagesSource: images,
          imagePaths: newPaths
        });

        //reorder to front
        console.log(
          "request to " + productUrl + "/" + id + "/" + reorderImages
        );

        await this.reorderRequest();
        c.setState({ isReorderDialogOpen: false });
      }
    });
  }

  async reorderRequest() {
    try {
      var reorderData = [];

      for (var i = 0; i < this.state.imagePaths.length; i++) {
        reorderData.push({
          id: i + 1,
          path: this.state.imagePaths[i]
        });
      }
    } catch (e) {
      console.log("exc ", e);
    }

    console.log("reorder data ", reorderData);

    let that = this;
    try {
      const value = read_cookie(accessToken);

      return fetch(productUrl + "/" + this.state.itemId + "/" + reorderImages, {
        method: "POST",
        body: JSON.stringify(reorderData),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: value
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          if (
            responseJson.hasOwnProperty(message) &&
            responseJson.message === unauthenticated
          ) {
            NetworkConfig.refreshRequest(this.reorderRequest, that);
          } else {
            console.log("!!!Reorder json ");
            console.log(responseJson);
          }
        })
        .catch(error => {
          console.error(error);
        });
    } catch (e) {
      console.log("exc ", e);
    }
  }

  async onImageDeleteRequest() {
    if (this.index >= 0) {
      var that = this;
      try {
        const value = read_cookie(accessToken);

        console.log(
          "request to " +
            productUrl +
            "/" +
            this.state.itemId +
            "/" +
            deleteImages
        );

        var tempIndex = Number(this.index);
        var sources = that.state.imagePaths;

        console.log("tempIndex ", tempIndex);
        console.log("sources ", sources);
        console.log("image path when clicked", sources[tempIndex]);
        //packing x-www-form-urlencoded data
        var details = {
          image_path: sources[tempIndex]
        };

        console.log("image delete urlencoded ", details);

        var formBody = [];
        for (var property in details) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        return fetch(
          productUrl + "/" + this.state.itemId + "/" + deleteImages,
          {
            method: "DELETE",
            body: formBody,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
              Authorization: value
            }
          }
        )
          .then(response => response.json())
          .then(responseJson => {
            console.log("!!!delete image response json ", responseJson);

            if (
              responseJson.hasOwnProperty(message) &&
              responseJson.message === unauthenticated
            ) {
              NetworkConfig.refreshRequest(this.onImageDelete, that);
            } else {
            }
          })
          .catch(error => {
            console.error(error);
          });
      } catch (e) {
        console.log("image delete getitng access tokene exception ", e);
      }
    }
  }

  onImageChosenAction(image) {
    // super.onImageChosenAction(image);
    var imagesSource = this.state.imagesSource;
    imagesSource.push({
      id: this.id++,
      source: { uri: image }
    });

    this.newImages.push({
      id: this.id++,
      source: { uri: image }
    });

    console.log("new images is now ", this.newImages);

    this.setState({
      imagesSource: imagesSource
    });

    this.onPrepareUpload();
  }

  async onPrepareUpload() {
    // try {
    const value = read_cookie(accessToken);
    this.onImageUpload(this.state.itemId, value);
    //  }
    //  catch (e) {
    //       console.log("exc ", e);
    //  }
  }
}
