import React from "react";

import {
  url,
  storageImages,
  accessToken,
  unauthenticated,
  message,
  userUrl,
  paymentsUrl,
  paymentsYearsUrl,
  favIconLink
} from "../Constants";
import Favicon from "react-favicon";
import PermamantDrawerComponent from "../components/PermamantDrawerComponent";
import Hidden from "@material-ui/core/Hidden/Hidden";
import NavBarComponent from "../components/NavBarComponent";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import BackButtonHandler from "../utils/BackButtonHandler";
import StateSaverUtil from "../utils/StateSaverUtil";
import { store } from "../store";
import PaymentsComponent from "../components/PaymentsComponent";
import { read_cookie } from "sfcookies";
import NetworkConfig from "../config/NetworkConfig";
import setUser from "../components/redux/actions/setUser";

export default class PaymentsScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      paymentsResult: {
        payments: []
      },
      years: [],
      year: null,
      loadYears: true,
      producersImages: store.getState().producersImages,
      producerId: store.getState().producerId,
      selectedProducer: store.getState().selectedProducer,
      producersAllData: store.getState().producersAllData,
      isDrawerOpen: false,
      userName: store.getState().userName,
      userEmail: store.getState().userEmail
    };

    this.propsToRestore = [];

    this.getData = this.getData.bind(this);
    this.getYears = this.getYears.bind(this);
    this.getPayments = this.getPayments.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
    this.openDrawer = this.openDrawer.bind(this);
    this.onBackArrowClicked = this.onBackArrowClicked.bind(this);
  }

  componentDidMount() {
    BackButtonHandler.handle(this, this.onBackArrowClicked);
    StateSaverUtil.save(this, this.propsToRestore);

    this.getUserInfo();
    document.title = "Плащания";

    this.initialDataLoad();
  }

  componentWillUnmount() {
    StateSaverUtil.deleteSavedProps(this, this.propsToRestore);
  }

  async initialDataLoad() {
    await StateSaverUtil.restoreState(this, this.propsToRestore);
    this.getData();
  }

  async getData() {
    this.setState({
      isLoaded: false
    });

    StateSaverUtil.save(this, this.propsToRestore);
    // console.log("state after being restored ", this.state);

    await this.getYears();
    await this.getPayments();

    this.setState({
      isLoaded: true
    });
  }

  openDrawer() {
    console.log("old state ", this.state.isDrawerOpen);

    var isDrawerOpen = false;
    if (this.state.isDrawerOpen === false) {
      isDrawerOpen = true;
    } else {
      isDrawerOpen = false;
    }

    this.setState({
      isDrawerOpen: isDrawerOpen
    });

    console.log("toggle drawer; now should be ", this.state.isDrawerOpen);
  }

  onBackArrowClicked() {
    console.log("back");
    this.setState({
      localRedirectBack: true
    });
  }

  render() {
    return (
      <div>
        <Favicon url={favIconLink} />
        <PermamantDrawerComponent
          context={this}
          onPushOrdersScreen={this.onPushOrdersScreen}
        />
        <Hidden mdUp implementation="css">
          <div style={{ marginLeft: 0 }}>
            <NavBarComponent
              title={"Плащания"}
              onSideDrawerOpen={this.openDrawer}
              onBackArrowClicked={this.onBackArrowClicked}
              isSideMenuNeeded={false}
            />
          </div>
        </Hidden>

        <Hidden smDown implementation="css">
          <div style={{ marginLeft: 210 }}>
            <NavBarComponent
              title={"Плащания"}
              onBackArrowClicked={this.onBackArrowClicked}
              isSideMenuNeeded={false}
            />
          </div>
        </Hidden>

        {!this.state.isLoaded && <LinearProgress />}

        <Hidden smDown implementation="css">
          <div style={{ marginLeft: 230 }}>
            <PaymentsComponent context={this} getData={this.getData} />
          </div>
        </Hidden>

        <Hidden mdUp implementation="css">
          <div style={{ marginLeft: 20 }}>
            <PaymentsComponent context={this} getData={this.getData} />
          </div>
        </Hidden>
      </div>
    );
  }

  getYears() {
    const that = this;

    const { producerId, loadYears } = this.state;

    if (loadYears) {
      try {
        const value = read_cookie(accessToken);

        return fetch(paymentsYearsUrl + "?producer_id=" + producerId, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: value
          }
        })
          .then(response => response.json())
          .then(responseJson => {
            if (
              responseJson.hasOwnProperty(message) &&
              responseJson.message === unauthenticated
            ) {
              NetworkConfig.refreshRequest(this.getYears, that);
            } else {
              StateSaverUtil.restoreState(this, this.propsToRestore);
              this.setState(
                {
                  years: responseJson.years,
                  year: responseJson.years[0]
                },
                function() {}
              );
            }
          })
          .catch(error => {
            // console.error(error);
          });
      } catch (e) {}
    }
  }

  getPayments() {
    const that = this;

    const { producerId, year } = this.state;

    try {
      const value = read_cookie(accessToken);

      return fetch(
        paymentsUrl + "?producer_id=" + producerId + "&year=" + year,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: value
          }
        }
      )
        .then(response => response.json())
        .then(responseJson => {
          if (
            responseJson.hasOwnProperty(message) &&
            responseJson.message === unauthenticated
          ) {
            NetworkConfig.refreshRequest(this.getPayments, that);
          } else {
            StateSaverUtil.restoreState(this, this.propsToRestore);
            this.setState(
              {
                paymentsResult: responseJson
              },
              function() {}
            );
          }
        })
        .catch(error => {
          // console.error(error);
        });
    } catch (e) {}
  }

  async getUserInfo() {
    const that = this;
    try {
      const value = read_cookie(accessToken);

      console.log("requesting ", userUrl);
      return fetch(userUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: value
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          // StateSaverUtil.restoreState(this, this.propsToRestore);
          // console.log("state after being restored! ", this.state);

          console.log("response json user ", responseJson);

          if (
            responseJson.hasOwnProperty(message) &&
            responseJson.message === unauthenticated
          ) {
            NetworkConfig.refreshRequest(this.getUserInfo, that);
          } else {
            console.log("is active? ", responseJson.is_active);
            that.setState({
              isActive: responseJson.is_active
            });

            //producers image paths
            var producersImages = [];

            if (
              responseJson.is_active === true ||
              responseJson.is_active === 1
            ) {
              var producers = [];
              for (var i = 0; i < responseJson.producers.length; i++) {
                console.log(responseJson.producers[i].name);
                producers.push(responseJson.producers[i].name);

                var image =
                  url +
                  storageImages +
                  responseJson.producers[i].logo_image_path;
                producersImages.push(image);
              }

              let that = this;
              console.log("producerId ds before", this.state.producerId);
              console.log(
                "selectedProducer ds before",
                this.state.selectedProducer
              );
              console.log(
                "selectedProducerName ds before",
                this.state.selectedProducerName
              );

              store.subscribe(() => console.log(store.getState()));

              const user = {
                userName: responseJson.name,
                userEmail: responseJson.email,
                producers: producers,
                producersImages: producersImages,
                producersAllData: responseJson.producers,
                producerId:
                  this.state.producerId === undefined ||
                  this.state.producerId === null
                    ? responseJson.producers[0].id
                    : this.state.producerId,
                selectedProducer:
                  this.state.selectedProducer === undefined ||
                  this.state.producerId === null
                    ? 0
                    : this.state.selectedProducer,
                selectedProducerName:
                  this.state.selectedProducerName === ""
                    ? responseJson.producers[0].name
                    : this.state.selectedProducerName
              };

              // Dispatch some actions
              store.dispatch(setUser(user));
              console.log("redux updated ", store.getState());
              console.log("producerId ds", this.state.producerId);
              console.log("selectedProducer ds", this.state.selectedProducer);
              console.log(
                "selectedProducerName ds",
                this.state.selectedProducerName
              );
            } else {
              this.setState({
                isLoading: false
              });
            }
          }
        })
        .catch(error => {
          // console.error(error);
        });
    } catch (e) {}
  }
}
