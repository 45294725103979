import { read_cookie } from "sfcookies";
import { accessToken, refreshToken } from "../Constants";
import history from "./../history";

export default class AuthUtil {
  static isAuthenticated() {
    const accessTokenFromServer = read_cookie(accessToken);
    const refreshTokenFromServer = read_cookie(refreshToken);

    return (
      accessTokenFromServer !== undefined &&
      accessTokenFromServer !== "" &&
      refreshTokenFromServer !== undefined &&
      refreshTokenFromServer !== ""
    );
  }

  static redirectUnauthenticated() {
    if (!this.isAuthenticated()) {
      alert("Моля, влзете с потребителското си име и парола.");
      history.push("/" + process.env.PUBLIC_URL);
    }
  }
}
