import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Input from "@material-ui/core/Input/Input";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";

export default class VariationDiscountPriceInput extends React.Component {
  render() {
    const { context, itemToMap, classes, updateSalePrice } = this.props;

    return (
      <div>
        <FormHelperText htmlFor="reduced-price-input">
          Намалена цена
        </FormHelperText>

        <Input
          id="reduced-price-input"
          value={
            itemToMap.sale_price !== -1 && itemToMap.sale_price !== "-1"
              ? itemToMap.sale_price
              : ""
          }
          name={"намалена Цена"}
          className={classes.inputs}
          validators={["minNumber:0.01", "maxNumber:999999"]}
          maxLength={6}
          onChange={event =>
            updateSalePrice(
              itemToMap,
              event.target.value,
              context.state,
              context
            )
          }
          endAdornment={
            <InputAdornment position="end">
              <p style={{ color: "gray" }}>лв</p>
            </InputAdornment>
          }
        />
      </div>
    );
  }
}
