import React from "react";
import { withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import BackRedirectComponent from "./redirects/BackRedirectComponent";
import { Redirect } from "react-router-dom";
import LogOutRedirectComponent from "./redirects/LogOutRedirectComponent";
import TableHead from "@material-ui/core/es/TableHead";
import TableRow from "@material-ui/core/es/TableRow";
import TableCell from "@material-ui/core/es/TableCell";
import TableBody from "@material-ui/core/es/TableBody";
import Table from "@material-ui/core/es/Table";
import LinearProgress from "@material-ui/core/LinearProgress";
import PaymentsProducerDropDownComponent from "./PaymentsProducerDropDownComponent";
import Hidden from "@material-ui/core/es/Hidden";
import Button from "@material-ui/core/Button";

function questionsListComponent(props) {
  const { context, classes, openQuestionAnswers, getData } = props;
  console.log(
    "context.state.redirectFromList in QLC",
    context.state.redirectFromList
  );

  return (
    <div className={classes.root}>
      <LogOutRedirectComponent context={context} />

      {context.state.localRedirectAnswer ? (
        <Redirect
          to={{
            pathname: process.env.PUBLIC_URL + "/products/questions/answers",
            state: {
              redirectFromList: context.state.redirectFromList,
              product_id: context.state.productId,
              productName: context.state.productName,
              questionId: context.state.questionId,
              navBarTitle: context.state.questionTitle
            }
          }}
        />
      ) : (
        <div />
      )}

      <BackRedirectComponent context={context} />

      <main className={classes.layout}>
        <PaymentsProducerDropDownComponent
          context={context}
          data={context.state.producersAllData}
          value={context.state.selectedProducer}
          getData={getData}
        />
        {!context.state.isLoaded && <LinearProgress />}

        <Paper className={classes.paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <Hidden smDown>
                  <TableCell className={classes.tableCell} padding="checkbox">
                    Дата
                  </TableCell>
                </Hidden>
                <TableCell className={classes.tableCell} padding="checkbox">
                  Въпрос
                </TableCell>
                <TableCell className={classes.tableAmount} padding="checkbox">
                  Продукт
                </TableCell>
                <Hidden smDown>
                  <TableCell
                    className={classes.tableCellWide}
                    padding="checkbox"
                  >
                    Потребител
                  </TableCell>
                </Hidden>
                <Hidden smDown>
                  <TableCell className={classes.tableCell} padding="checkbox" />
                </Hidden>
              </TableRow>
            </TableHead>

            <TableBody>
              {context.state.questionsData.map(item => (
                <TableRow key={item.id} className="rows">
                  <Hidden smDown>
                    <TableCell
                      className={classes.tableCellDate}
                      padding="checkbox"
                    >
                      {new Date(
                        item.created_at.replace(/ /g, "T")
                      ).toLocaleDateString("bg-BG")}
                    </TableCell>
                  </Hidden>
                  <TableCell
                    className={classes.tableCellWide}
                    padding="checkbox"
                  >
                    {item.description}
                    <Hidden mdUp>
                      <br />
                      <br />
                      От {item.user.name} на{" "}
                      {new Date(
                        item.created_at.replace(/ /g, "T")
                      ).toLocaleDateString("bg-BG")}
                      <br />
                      <Button
                        style={{ marginTop: 10 }}
                        onClick={() => {
                          openQuestionAnswers(item.id, item.description);
                        }}
                        color="primary"
                        size="small"
                        variant="outlined"
                      >
                        Детайли
                      </Button>
                    </Hidden>
                  </TableCell>
                  <TableCell className={classes.tableCell} padding="checkbox">
                    {item.product.name}
                  </TableCell>
                  <Hidden smDown>
                    <TableCell className={classes.tableCell} padding="checkbox">
                      {item.user.name}
                    </TableCell>
                  </Hidden>
                  <Hidden smDown>
                    <TableCell
                      className={classes.tableCellDetails}
                      padding="checkbox"
                    >
                      <Button
                        onClick={() => {
                          openQuestionAnswers(item.id, item.description);
                        }}
                        color="primary"
                        size="small"
                        variant="outlined"
                      >
                        Детайли
                      </Button>
                    </TableCell>
                  </Hidden>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </main>
    </div>
  );
}
const styles = theme => ({
  layout: {
    width: "auto",
    display: "block" // Fix IE11 issue.
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    maxWidth: 600,
    minWidth: 250,
    marginRight: 20,
    marginTop: 20,
    marginBottom: 20
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    maxWidth: 600,
    minWidth: 250
  },
  margin: {
    margin: theme.spacing.unit
  },
  textField: {
    flexBasis: 200
  },
  table: {},
  tableCell: {
    width: "20%",
    paddingTop: 10,
    paddingBottom: 10
  },
  tableCellDate: {
    width: "20%",
    paddingTop: 10,
    paddingBottom: 10,
    whiteSpace: "nowrap"
  },
  tableAmount: {
    width: "15%"
  },
  tableCellWide: {
    width: "55%",
    paddingTop: 10,
    paddingBottom: 10
  },
  tableCellDetails: {
    width: "20%",
    paddingTop: 10,
    paddingBottom: 10,
    cursor: "pointer"
  },
  credit: {
    backgroundColor: "#f1f8e9"
  }
});

export default withStyles(styles, { withTheme: true })(questionsListComponent);
