import React from "react";
import BulkEditProductsTableComponent from "./BulkEditProductsTableComponent";
import NavBarComponent from "../NavBarComponent";
import SaveIcon from "@material-ui/icons/Save";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import PermamantDrawerComponent from "../PermamantDrawerComponent";
import Hidden from "@material-ui/core/Hidden/Hidden";
import BackRedirectComponent from "../redirects/BackRedirectComponent";
import LogOutRedirectComponent from "../redirects/LogOutRedirectComponent";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";

function bulkEdit(props) {
  const {
    context,
    classes,
    state,
    onBulkSave,
    headerText,
    onFilterSearch,
    onBackArrowClicked,
    onOpenDrawer,
    closeSavedSnack
  } = props;

  return (
    <div>
      <LogOutRedirectComponent context={context} />

      <BackRedirectComponent context={context} />

      <PermamantDrawerComponent context={context} />

      <Hidden mdUp implementation="css">
        <div style={{ marginLeft: 0 }}>
          <NavBarComponent
            onFilterSearch={onFilterSearch}
            onClickAction={onBulkSave}
            onSideDrawerOpen={onOpenDrawer}
            onBackArrowClicked={onBackArrowClicked}
            title={"Активиране на продукти"}
            actionIcon={<SaveIcon />}
          />
        </div>
      </Hidden>

      <Hidden smDown implementation="css">
        <div style={{ marginLeft: 210 }}>
          <NavBarComponent
            onFilterSearch={onFilterSearch}
            onClickAction={onBulkSave}
            onBackArrowClicked={onBackArrowClicked}
            title={"Активиране на продукти"}
            actionIcon={<SaveIcon />}
          />
        </div>
      </Hidden>

      {!context.state.isLoaded && <LinearProgress />}

      <Hidden smDown implementation="css">
        <div style={{ marginLeft: 230 }}>
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <div>
                {headerText !== undefined ? (
                  <p style={{ textAlign: "center", margin: 6 }}>{headerText}</p>
                ) : (
                  <div />
                )}
                <BulkEditProductsTableComponent
                  state={state}
                  onSave={onBulkSave}
                />
              </div>
            </Paper>
          </main>
        </div>
      </Hidden>

      <Hidden mdUp implementation="css">
        <div style={{ marginLeft: 20 }}>
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <div>
                {headerText !== undefined ? (
                  <p style={{ textAlign: "center", margin: 6 }}>{headerText}</p>
                ) : (
                  <div />
                )}
                <BulkEditProductsTableComponent
                  state={state}
                  onSave={onBulkSave}
                />
              </div>
            </Paper>
          </main>
        </div>
      </Hidden>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={context.state.isSavedSnack}
        autoHideDuration={3000}
        onClose={closeSavedSnack}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">Продуктите са запазени</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={closeSavedSnack}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </div>
  );
}

const styles = theme => ({
  layout: {
    width: "auto",
    display: "block" // Fix IE11 issue.
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    maxWidth: 600,
    minWidth: 250,
    marginRight: 20,
    marginTop: 20
  },
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {
    margin: theme.spacing.unit
  },
  textField: {
    flexBasis: 200
  }
});

export default withStyles(styles)(bulkEdit);
