import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Input from "@material-ui/core/Input/Input";
import React from "react";

export default class PriceAutoCalculationComponent extends React.Component {
  render() {
    const { context, classes, item } = this.props;

    return (
      <Input
        value={
          item.price !== undefined
            ? item.price.toString() === ""
              ? ""
              : item.price !== -1
              ? !isNaN(item.price.toString().replace(",", "."))
                ? (
                    Number(item.price.toString().replace(",", ".")) *
                    ((100 - context.state.producerPercent) / 100)
                  ).toFixed(2)
                : "NaN"
              : ""
            : ""
        }
        name={"Доставна ед. цена"}
        style={{ width: "100%" }}
        disabled
        maxLength={6}
        onChange={event =>
          this.updatePrice(item, event.target.value, context.state, context)
        }
        endAdornment={
          <InputAdornment position="end">
            <p style={{ color: "gray", width: 110, textAlign: "right" }}>
              лв за 1 {context.state.entity}
            </p>
          </InputAdornment>
        }
      />
    );
  }
}
