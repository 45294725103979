import React from "react";

import {
  accessToken,
  unauthenticated,
  message,
  favIconLink,
  ordersUrl,
  ordersDatesUrl,
  autorefreshInterval
} from "../Constants";
import NetworkConfig from "../config/NetworkConfig";
import { read_cookie } from "sfcookies";
import Favicon from "react-favicon";
import OrdersDatesComponent from "../components/OrdersDatesComponent";
import Hidden from "@material-ui/core/Hidden/Hidden";
import NavBarComponent from "../components/NavBarComponent";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import BackButtonHandler from "../utils/BackButtonHandler";
import StateSaverUtil from "../utils/StateSaverUtil";
import { store } from "./../store";

export default class OrdersDatesScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      producerId: store.getState().producerId,
      selectedProducer: store.getState().selectedProducer,
      producersAllData: store.getState().producersAllData,
      orderMonths: [],
      orderYears: [],
      selectedMonth: 0,
      selectedYear: 0,
      ordersDates: [],
      isDrawerOpen: false,
      producersImages: store.getState().producersImages,
      userName: store.getState().userName,
      userEmail: store.getState().userEmail,
      isEmptyDialogOpen: false,
      autoUpdateInterval: undefined
    };
    this.propsToRestore = [];

    this.getData = this.getData.bind(this);
    this.getOrders = this.getOrders.bind(this);
    this.onBackArrowClicked = this.onBackArrowClicked.bind(this);
    this.getDates = this.getDates.bind(this);
    this.onDateClicked = this.onDateClicked.bind(this);
    this.openDrawer = this.openDrawer.bind(this);
    this.onPushOrdersScreen = this.onPushOrdersScreen.bind(this);
    this.autoRefresh = this.autoRefresh.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    document.title = "Поръчки";
    this.getData();
    this.autoRefresh();

    StateSaverUtil.save(this, this.propsToRestore);

    BackButtonHandler.handle(this, this.onBackArrowClicked);
  }

  async getData() {
    await StateSaverUtil.restoreState(this, this.propsToRestore);
    console.log("state after being restored ", this.state);

    await this.getOrders();
    if (this.state.orderYears.length > this.state.selectedYear) {
      await this.getDates(this.state.selectedMonth, this.state.selectedYear);
    }
  }

  render() {
    return (
      <div>
        <Favicon url={favIconLink} />

        <Hidden mdUp implementation="css">
          <div style={{ marginLeft: 0 }}>
            <NavBarComponent
              title={"Поръчки"}
              onSideDrawerOpen={this.openDrawer}
              onBackArrowClicked={this.onBackArrowClicked}
              isSideMenuNeeded={false}
            />
          </div>
        </Hidden>

        <Hidden smDown implementation="css">
          <div style={{ marginLeft: 210 }}>
            <NavBarComponent
              title={"Поръчки"}
              onBackArrowClicked={this.onBackArrowClicked}
              isSideMenuNeeded={false}
            />
          </div>
        </Hidden>

        {!this.state.isLoaded && <LinearProgress />}

        <Hidden smDown implementation="css">
          <div style={{ marginLeft: 230 }}>
            <OrdersDatesComponent
              context={this}
              loadProducts={this.loadProducts}
              getData={this.getData}
              onDrawerOpen={this.openDrawer}
              onDateClicked={this.onDateClicked}
              getDates={this.getDates}
              onBackArrowClicked={this.onBackArrowClicked}
              onPushOrdersScreen={this.onPushOrdersScreen}
            />
          </div>
        </Hidden>

        <Hidden mdUp implementation="css">
          <div style={{ marginLeft: 20 }}>
            <OrdersDatesComponent
              context={this}
              loadProducts={this.loadProducts}
              getData={this.getData}
              onDrawerOpen={this.openDrawer}
              onDateClicked={this.onDateClicked}
              getDates={this.getDates}
              onBackArrowClicked={this.onBackArrowClicked}
              onPushOrdersScreen={this.onPushOrdersScreen}
            />
          </div>
        </Hidden>
      </div>
    );
  }

  onPushOrdersScreen() {}

  onDateClicked(location) {
    console.log("going to location ", location);
    /* history.push({
            pathname: process.env.PUBLIC_URL +'/orders',
            state: {producerId: this.state.producerId,
                producersImages: this.state.producersImages,
                selectedProducer: this.state.selectedProducer,
                location_id: location.location_id,
                locationName: location.name,
                locationDate: location.delivery_date,
                delivery_start: location.delivery_start,
                delivery_end: location.delivery_end,
                userName: this.state.userName,
                userEmail: this.state.userEmail
            }
        });
        */
    this.setState({
      location: location,
      localRedirectPushOrdersForDate: true
    });
  }

  openDrawer() {
    console.log("old state ", this.state.isDrawerOpen);

    var isDrawerOpen = false;
    if (this.state.isDrawerOpen === false) {
      isDrawerOpen = true;
    } else {
      isDrawerOpen = false;
    }

    this.setState({
      isDrawerOpen: isDrawerOpen
    });

    console.log("toggle drawer; now should be ", this.state.isDrawerOpen);
  }

  async getOrders() {
    let that = this;
    try {
      const value = read_cookie(accessToken);

      console.log(
        "requesting ",
        ordersUrl + "?producer_id=" + that.state.producerId
      );
      console.log("token value ", value);

      return fetch(ordersUrl + "?producer_id=" + that.state.producerId, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: value
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log("response json orders dates ", responseJson);

          if (
            responseJson.hasOwnProperty(message) &&
            responseJson.message === unauthenticated
          ) {
            NetworkConfig.refreshRequest(this.getOrders, that);
          } else {
            if (
              responseJson.dates !== [] &&
              responseJson.distinct_years !== [] &&
              responseJson.dates.length > 0 &&
              responseJson.distinct_years.length > 0
            ) {
              var months = [];
              for (var i = 0; i < responseJson.distinct_years.length; i++) {
                months.push(responseJson.distinct_years[i].toString());
                months[responseJson.distinct_years[i].toString()] = [];
              }
              console.log("months ", months);

              for (i = 0; i < responseJson.dates.length; i++) {
                months[responseJson.dates[i].year.toString()].push(
                  responseJson.dates[i]
                );
              }

              console.log("months ", months);

              console.log(responseJson, responseJson.length);
              // responseJson = [];

              this.setState({
                orderMonths: months,
                orderYears: responseJson.distinct_years,
                isEmptyDialogOpen: false
              });

              console.log("my state ", this.state);
            } else {
              console.log("opeing emprty dialog");
              that.setState({
                isLoaded: true,
                isEmptyDialogOpen: true
              });
            }
          }
        })
        .catch(error => {
          console.error(error);
        });
    } catch (e) {}
  }

  getDates(monthIndex, yearIndex) {
    let that = this;
    try {
      that.setState({
        isLoaded: false
      });

      let year = that.state.orderYears[yearIndex];
      let month =
        that.state.orderMonths[that.state.orderYears[yearIndex]][monthIndex]
          .month;

      const value = read_cookie(accessToken);

      console.log("token value ", value);

      console.log("year to request index ", yearIndex);
      console.log("month to request index ", monthIndex);

      console.log("year to request ", year);
      console.log("month to request ", month);

      const url =
        ordersDatesUrl +
        "?producer_id=" +
        that.state.producerId +
        "&year=" +
        year +
        "&month=" +
        month;

      console.log("url to get delivery dates ", url);

      return fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: value
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log("orders for the given order ", responseJson);

          this.setState({
            isLoaded: true,
            ordersDates: responseJson
          });

          console.log("state after getting orders ", this.state);
        })
        .catch(error => {
          console.error(error);
        });
    } catch (e) {}
  }

  autoRefresh() {
    let that = this;
    let interval = setInterval(function() {
      that.setState({
        isLoaded: false
      });
      that.getData();
    }, autorefreshInterval);

    this.setState({
      autoUpdateInterval: interval
    });
  }

  onBackArrowClicked() {
    console.log("back");
    this.setState({
      localRedirectBack: true
    });
  }

  componentWillUnmount() {
    if (this.state.autoUpdateInterval !== undefined) {
      console.log("this.state.autoUpdateInterval clear interval");
      clearInterval(this.state.autoUpdateInterval);
    }
    StateSaverUtil.deleteSavedProps(this, this.propsToRestore);
  }
}
