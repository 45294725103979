import React from "react";
import Favicon from "react-favicon";
import { favIconLink } from "../Constants";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead/TableHead";
import Table from "@material-ui/core/Table/Table";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableBody from "@material-ui/core/TableBody/TableBody";
import DatesFilterDropDown from "./DatesFilterDropDown";
import YearsFilterDropDown from "./YearsFilterDropDown";
import BackRedirectComponent from "./redirects/BackRedirectComponent";
import { Redirect } from "react-router-dom";
import LogOutRedirectComponent from "./redirects/LogOutRedirectComponent";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import ProducerDropDownComponent from "./ProducerDropDownComponent";
import PermamantDrawerComponent from "./PermamantDrawerComponent";

function orders(props) {
  const {
    classes,
    context,
    loadProducts,
    getData,
    onDateClicked,
    getDates,
    onPushOrdersScreen
  } = props;

  return (
    <div>
      <Favicon url={favIconLink} />

      <BackRedirectComponent
        context={context}
        state={{
          producerId:
            context.state.producerId !== undefined
              ? context.state.producerId
              : null,
            producerPercent:
                context.state.producerPercent !== undefined
                    ? context.state.producerPercent
                    : null,
          producersAllData: context.state.producersAllData,
          selectedProducer:
            context.state !== null ? context.state.selectedProducer : null,
          producersImages: context.state.producersImages,
          userName: context.state.userName,
          userEmail: context.state.userEmail
        }}
      />

      <LogOutRedirectComponent context={context} />

      {context.state.localRedirectPushOrdersForDate ? (
        <Redirect
          to={{
            pathname: process.env.PUBLIC_URL + "/orders",
            state: {
              location_id:
                context.state.location !== undefined
                  ? context.state.location.location_id
                  : null,
              locationName:
                context.state.location !== undefined
                  ? context.state.location.name
                  : null,
              locationDate:
                context.state.location !== undefined
                  ? context.state.location.delivery_date
                  : null,
              delivery_start:
                context.state.location !== undefined
                  ? context.state.location.delivery_start
                  : null,
              delivery_end:
                context.state.location !== undefined
                  ? context.state.location.delivery_end
                  : null
            }
          }}
        />
      ) : (
        <div />
      )}

      <PermamantDrawerComponent
        context={context}
        onPushOrdersScreen={onPushOrdersScreen}
      />

      <main className={classes.layout}>
        {!context.state.isLoaded ? (
          <LinearProgress />
        ) : (
          <ProducerDropDownComponent
            context={context}
            loadProducts={loadProducts}
            getData={getData}
            data={context.state.producersAllData}
            value={context.state.selectedProducer}
            itemCount={6}
            fontSize={15}
            style={{ marginBottom: 10 }}
          />
        )}

        <Paper className={classes.paper}>
          {!context.state.isLoaded ? (
            <LinearProgress />
          ) : !context.state.isEmptyDialogOpen ? (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={5}>
                    <div style={{ marginTop: 15, marginBottom: 15 }}>
                      <span style={{ marginRight: 60 }}>
                        <YearsFilterDropDown
                          context={context}
                          getDates={getDates}
                        />
                      </span>

                      <span>
                        <DatesFilterDropDown
                          context={context}
                          getDates={getDates}
                        />
                      </span>
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Дата на доставка</TableCell>
                  <TableCell align={"right"}>Локация</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {context.state.ordersDates.map(row => {
                  //TODO: Remove when deployed
                  row.delivery_start = row.delivery_start.toString();
                  row.delivery_end = row.delivery_end.toString();
                  console.log("orders row ", row);

                  return (
                    <TableRow
                      key={row.id}
                      onClick={() => onDateClicked(row)}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell component="th" scope="row">
                        <b>
                          {new Date(
                            row.delivery_date.replace(/ /g, "T")
                          ).toLocaleDateString("bg-BG", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric"
                          })}{" "}
                          от{" "}
                          {row.delivery_start !== null
                            ? row.delivery_start.padStart(2, "0")
                            : ""}
                          :00 до{" "}
                          {row.delivery_end !== null
                            ? row.delivery_end.padStart(2, "0")
                            : ""}
                          :00
                        </b>
                      </TableCell>
                      <TableCell align={"right"}>{row.name}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <div>Нямате дати за доставка</div>
          )}
        </Paper>

        <div />
      </main>
    </div>
  );
}

const styles = theme => ({
  layout: {
    width: "auto",
    display: "block" // Fix IE11 issue.
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    maxWidth: 600,
    minWidth: 250,
    marginRight: 20,
    marginTop: 20,
    marginBottom: 20
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    maxWidth: 600,
    minWidth: 250
  },
  margin: {
    margin: theme.spacing.unit
  },
  textField: {
    flexBasis: 200
  },
  table: {}
});

export default withStyles(styles)(orders);
