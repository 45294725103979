import React from "react";

import {
  accessToken,
  categoriesUrl,
  productUrl,
  unitsUrl,
  unauthenticated,
  message,
  userUrl,
  url,
  storageImages
} from "../Constants";
import CreateProductFormComponent from "../components/CreateProductFormComponent";
import NetworkConfig from "./../config/NetworkConfig";
import { read_cookie } from "sfcookies";
import Hidden from "@material-ui/core/Hidden/Hidden";
import NavBarComponent from "../components/NavBarComponent";
import SaveIcon from "@material-ui/icons/Save";
import BackButtonHandler from "../utils/BackButtonHandler";
import StateSaverUtil from "../utils/StateSaverUtil";
import { store } from "../store";
var id = 1;

export default class CreateProductScreen extends React.Component {
  idJustAdded = -1;
  createProductAccessToken = "";
  newImagePosition = 0;

  originalCategories = [];

  list = [
    {
      id: id++,
      name: "",
      entity: "-1",
      measurement: -1,
      price: -1,
      sale_price: -1
    }
  ];

  categories = [
    {
      id: "1",
      name: "Млечни и яйца"
    },
    {
      id: "2",
      name: "Чай, билки, подправки"
    },
    {
      id: "3",
      name: "Зеленчуци"
    }
  ];

  selectedCategories = [];

  imagesSource = [];

  constructor(props) {
    super(props);

    this.state = {
      promptVisible: false,
      description: "",
      isLoading: true,
      imagesSource: [],
      entity: "-1",
      isPersonalisable: false,
      dataSource: this.list,
      selectedCategories: this.selectedCategories,
      categories: this.categories,
      units: ["kg", "l"],
      unitPrice: -1,
      unitSalePrice: -1,
      isWaitingForProduct: false,
      producerId: store.getState().producerId,
      isCategoriesChooserOpen: false,
      isDescriptionDialogOpen: false,
      isGalleryOpen: false,
      isLoaded: false,
      isProductLoaded: true,
      isDrawerOpen: false,
      selectedProducer: store.getState().selectedProducer,
      navBarTitle: "Създай продукт",
      isReorderDialogOpen: false,
      closeReorderDialog: () => {},
      onReorderAction: () => {},
      localRedirectQuestions: false,
      onOpenQuestions: () => {},
      isEditing: false,
      producerPercent:
        store.getState() !== null &&
        store.getState().producersAllData !== undefined &&
        store.getState().producersAllData[store.getState().selectedProducer] !==
          undefined
          ? store.getState().producersAllData[store.getState().selectedProducer]
              .percent
          : null,
      variationsDeleted: [],
      localRedirectBack: false
    };
    console.log("this.state ", this.state);

    this.propsToRestore = [];

    this.newImagePosition = 0;
    this.getData = this.getData.bind(this);
    this.addListItem = this.addListItem.bind(this);
    this.onSaveItem = this.onSaveItem.bind(this);
    this.onImageModalOpen = this.onImageModalOpen.bind(this);
    this.onImageChosenAction = this.onImageChosenAction.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onVariantDelete = this.onVariantDelete.bind(this);
    this.onPictureDelete = this.onPictureDelete.bind(this);
    this.onPictureToFront = this.onPictureToFront.bind(this);
    this.onLongClickPictureAction = this.onLongClickPictureAction.bind(this);
    this.onOpenCategories = this.onOpenCategories.bind(this);
    this.onCategoriesDialogClose = this.onCategoriesDialogClose.bind(this);
    this.onDescriptionOpen = this.onDescriptionOpen.bind(this);
    this.onBulkSave = this.onBulkSave.bind(this);
    this.onDescriptionSave = this.onDescriptionSave.bind(this);
    this.onDescriptionClose = this.onDescriptionClose.bind(this);
    this.onFilterSearch = this.onFilterSearch.bind(this);
    this.onGalleryClose = this.onGalleryClose.bind(this);
    this.openDrawer = this.openDrawer.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
    this.onBackArrowClicked = this.onBackArrowClicked.bind(this);
    this.onPushOrdersScreen = this.onPushOrdersScreen.bind(this);
  }

  componentDidMount() {
    document.title = "Създай продукт - КООП";

    //override the backButton
    BackButtonHandler.handle(this, this.onBackArrowClicked);

    StateSaverUtil.save(this, this.propsToRestore);

    this.getData();
  }

  componentWillUnmount() {
    StateSaverUtil.deleteSavedProps(this, this.propsToRestore);
  }

  async getData() {
    await StateSaverUtil.restoreState(this, this.propsToRestore);
    console.log("state after being restored ", this.state);

    this.getUserInfo();
    this.setState({
      variationsDeleted: []
    });
  }

  async loadData() {
    let that = this;
    try {
      const value = read_cookie(accessToken);
      //---fetch categories
      console.log("request to " + categoriesUrl);
      fetch(categoriesUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: value
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log("response json categories ", responseJson);

          if (
            responseJson.hasOwnProperty(message) &&
            responseJson.message === unauthenticated
          ) {
            NetworkConfig.refreshRequest(this.loadData, that);
          } else {
            //fetch units

            this.originalCategories = responseJson;
            this.setState({
              originalCategories: responseJson
            });

            fetch(unitsUrl, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: value
              }
            })
              .then(response => response.json())
              .then(responseJson => {
                console.log("response json units ", responseJson);
                if (
                  responseJson.hasOwnProperty(message) &&
                  responseJson.message === unauthenticated
                ) {
                  NetworkConfig.refreshRequest(this.loadData, that);
                } else {
                  var unitsDropDown = [];
                  for (var i = 0; i < responseJson.length; i++) {
                    unitsDropDown.push({
                      value: responseJson[i].description
                    });
                  }

                  this.setState({
                    unitsDropDown: unitsDropDown,
                    units: responseJson,
                    isLoading: false,
                    isLoaded: true
                  });
                  if (this.state.entity === "-1") {
                    this.setState({
                      entity: responseJson[0].name,
                      entityFull: responseJson[0].description
                    });
                  }
                }
              })
              .catch(error => {
                console.error(error);
              });
            //----units here

            var categoriesArrray = [];
            if (
              responseJson !== undefined &&
              this.state.selectedCategories !== undefined
            ) {
              for (var i = 0; i < responseJson.length; i++) {
                categoriesArrray.push({
                  id: responseJson[i].id,
                  name: responseJson[i].name,
                  is_published: this.isSelected(
                    responseJson[i].id,
                    this.state.selectedCategories
                  )
                });
              }
            }

            this.setState(
              {
                bulkdDataSource: categoriesArrray
              },
              function() {}
            );
          }
        })
        .catch(error => {
          console.error(error);
        });
      //---categories fetched
    } catch (e) {}
  }

  async getUserInfo() {
    let that = this;
    try {
      const value = read_cookie(accessToken);

      console.log("requesting ", userUrl);
      return fetch(userUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: value
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log("response json user ", responseJson);

          if (
            responseJson.hasOwnProperty(message) &&
            responseJson.message === unauthenticated
          ) {
            NetworkConfig.refreshRequest(this.getUserInfo, that);
          } else {
            console.log("is active? ", responseJson.is_active);
            that.setState({
              isActive: responseJson.is_active
            });

            //producers image paths
            var producersImages = [];

            if (
              responseJson.is_active === true ||
              responseJson.is_active === 1
            ) {
              var producers = [];
              for (var i = 0; i < responseJson.producers.length; i++) {
                console.log(responseJson.producers[i].name);
                producers.push(responseJson.producers[i].name);

                var image =
                  url +
                  storageImages +
                  responseJson.producers[i].logo_image_path;
                producersImages.push(image);
              }

              this.setState({
                userName: responseJson.name,
                userEmail: responseJson.email,
                producers: producers,
                producersImages: producersImages,
                producersAllData: responseJson.producers
              });
              this.loadData();
            } else {
              this.setState({
                isLoading: false
              });
            }
          }
        })
        .catch(error => {
          console.error(error);
        });
    } catch (e) {}
  }

  isSelected(id, selectedCategories) {
    var size = selectedCategories.length;

    for (var i = 0; i < size; i++) {
      if (id === selectedCategories[i]) {
        return true;
      }
    }

    return false;
  }

  async onSaveItem() {
    console.log("this.list  variations ", this.list);

    let that = this;

    var quantities = [];
    var prices = [];
    var reducedPrices = [];

    for (var i = 0; i < this.list.length; i++) {
      var measurementItem = this.list[i]["measurement"]
        .toString()
        .replace(",", ".");
      var priceItem = this.list[i]["price"].toString().replace(",", ".");
      var reducedPriceItem = this.list[i]["sale_price"]
        .toString()
        .replace(",", ".");

      quantities.push(measurementItem);
      prices.push(priceItem);
      reducedPrices.push(
        reducedPriceItem !== -1 && reducedPriceItem !== ""
          ? reducedPriceItem
          : null
      );
      console.log("quant measurement ", measurementItem, priceItem);
    }

    console.log("Selected categories " + this.state.selectedCategories);
    console.log("Name " + this.state.name);
    console.log("entity id " + this.state.entity_id);
    console.log("is persoanlisable " + this.state.isPersonalisable);
    console.log("reducedPrices " + reducedPrices);
    console.log("is published " + this.state.isProductPublished);

    // console.log("this.isValid(quantities, prices) ", this.isValid(quantities, prices));

    if (this.isValid(quantities, prices) === true) {
      try {
        const value = read_cookie(accessToken);
        this.idJustAdded = value;

        var newDescription = this.state.description;
        var find = "\n";
        var re = new RegExp(find, "g");

        newDescription = newDescription.replace(re, "</br>");

        var unitPrice = "";
        if (this.state.unitPrice !== undefined) {
          unitPrice = this.state.unitPrice.toString().replace(",", ".");
        }

        var unitSalePrice = "";
        if (this.state.unitSalePrice !== undefined) {
          unitSalePrice = this.state.unitSalePrice.toString().replace(",", ".");
        }

        let data = {
          method: "POST",
          body: JSON.stringify({
            name: this.state.name,
            description: newDescription,
            entity_id: this.state.entity_id,
            categories: this.state.selectedCategories,
            custom_packaging:
              this.state.isPersonalisable === true ||
              this.state.isPersonalisable === 1
                ? 1
                : 0,
            custom_price: unitPrice,
            is_published: this.state.isPublished,
            custom_sale_price: unitSalePrice,
            producer_id: this.state.producerId,
            slug: this.state.name,
            order_product_price: prices,
            order_product_quantity: quantities,
            order_sale_prices: reducedPrices
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: value
          }
        };
        console.log("post request to ", productUrl, " for prod sent ", data);

        return fetch(productUrl, data)
          .then(response => response.json())
          .then(responseJson => {
            console.log(responseJson);

            if (
              responseJson.hasOwnProperty(message) &&
              responseJson.message === unauthenticated
            ) {
              console.log("refresh request");
              NetworkConfig.refreshRequest(this.onSaveItem, that);
            } else {
              that.setState({
                isSnackBarOpen: true,
                snackBarMessage: "Добавено успешно!"
              });
              console.log("uploaded id " + responseJson.id);

              console.log("POST create prod ");
              console.log("--------------POST create prod ");
              this.idJustAdded = responseJson.id;
            }
          });
      } catch (e) {}
    }
  }

  /**
   * Convert a base64 string in a Blob according to the data and contentType.
   *
   * @param b64Data {String} Pure base64 string without contentType
   * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
   * @param sliceSize {Int} SliceSize to process the byteCharacters
   * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
   * @return Blob
   */
  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  isValid(quantities, prices) {
    var isDataValid = true;
    var errorMessage = "";

    console.log("is personalisable " + this.state.isPersonalisable);
    console.log("unitPrice " + this.state.unitPrice);
    if (
      (this.state.isPersonalisable === 1 ||
        this.state.isPersonalisable === true) &&
      this.state.unitPrice <= 0
    ) {
      errorMessage = "Изберете единична цена";
      isDataValid = false;
    }

    console.log("unitSalePrice " + this.state.unitSalePrice);
    if (
      (this.state.isPersonalisable === 1 ||
        this.state.isPersonalisable === true) &&
      this.state.unitSalePrice < 0
    ) {
      errorMessage = "Изберете единична намалена цена";
      isDataValid = false;
    }

    if (quantities.length <= 0) {
      errorMessage = "Въведете поне една разфасовка";
      isDataValid = false;
    }

    for (var i = 0; i < quantities.length; i++) {
      if (
        quantities[i] <= 0 ||
        prices[i] <= 0 ||
        quantities[i] > 999999 ||
        prices[i] > 999999
      ) {
        errorMessage =
          "Количеството и цената трябва да са по-големи от 0 и по-малки от 999 999";
        isDataValid = false;
        break;
      }
    }

    for (i = 0; i < quantities.length; i++) {
      if (isNaN(quantities[i]) || isNaN(prices[i])) {
        errorMessage =
          "Въвели сте навалидно число на ред " +
          (Number(i) + 1) +
          " за разфасовки.";
        isDataValid = false;
        break;
      }
    }

    if (quantities.length !== prices.length) {
      errorMessage = "Неочаквана грешка";
      isDataValid = false;
    }

    try {
      if (
        this.state.description !== undefined &&
        this.state.description !== ""
      ) {
        var newDescription = this.state.description;
        var find = "\n";
        var re = new RegExp(find, "g");

        newDescription = newDescription.replace(re, "</br>");

        console.log("we are testing description ", newDescription);
        // var descrRegex = /^[A-Za-zА-Яа-я0-9 \/<>\-,.'\n]{1,60000}$/;
        //  if (this.state.description != undefined && !descrRegex.test(newDescription)) {
        //     errorMessage = "Описанието на продукт не може да е повече от 60000 символа и трябва да използвате само букви, цифри, .,-'";
        //     isDataValid = false;
        //  }
      }
    } catch (e) {
      console.log("exc in validation in CreateProductScreen ", e);
    }

    if (this.state.selectedCategories.length <= 0) {
      errorMessage = "Изберете категории";
      isDataValid = false;
    }

    console.log("name is now ", this.state.name);
    try {
      var regex = /^([A-Za-zА-Яа-я0-9 \-,.%']{1,60})$/;
      if (
        this.state.name !== undefined &&
        this.state.name !== "" &&
        !regex.test(this.state.name)
      ) {
        errorMessage =
          "За името на продукт може да използвате само букви, цифри, %.,-'";
        isDataValid = false;
      }

      if (this.state.name === undefined || this.state.name === "") {
        errorMessage = "Въведете име";
        isDataValid = false;
      }
    } catch (e) {
      errorMessage = "Въведете име";
      isDataValid = false;
      console.log(e);
    }

    console.log("checks passed ", isDataValid);

    if (!isDataValid) {
      this.setState({
        isSnackBarOpen: true,
        snackBarMessage: errorMessage
      });
    }
    return isDataValid;
  }

  addListItem() {
    var item = {};
    item["id"] = id++;
    item["locallyAdded"] = true;
    item["entity"] = this.state.entity;
    item["measurement"] = -1;
    item["price"] = -1;
    item["sale_price"] = -1;

    this.list.push(item);

    console.log(this.list);
    console.log("adding item");
    this.setState({ dataSource: this.list, extraData: item });
  }

  onImageModalOpen(id) {
    //console.log("picking image");
    // history.push('products/picture');
    var index = 0;
    if (this.state.imagesSource !== undefined && id !== undefined) {
      for (var i = 0; i < this.state.imagesSource.length; i++) {
        if (this.state.imagesSource[i].id === id) {
          index = i;
        }
      }
    }

    console.log("opening gallry");
    this.setState({
      selectedItem: index,
      isGalleryOpen: true
    });
  }

  onImageChosenAction(image) {
    let source = image;

    console.log("putting an index of ", this.newImagePosition);

    let objectToAdd = {
      id: id++,
      source: { uri: image },
      imageJustTaken: true,
      positionInArray: this.newImagePosition++
    };

    console.log("object to add src ", objectToAdd);

    this.imagesSource.push(objectToAdd);

    console.log("this.image sources!!! ", this.imagesSource);
    //TODO: Critical!!! the id which yu put here should be equal to the size of the array of newImages

    // You can also display the image using data:
    // let source = { uri: 'data:image/jpeg;base64,' + response.data };

    this.setState({
      avatarSource: source,
      newObject: source,
      imagesSource: this.imagesSource
    });

    console.log("images source ", this.state.imagesSource);
  }

  onVariantDelete(deletedId) {
    console.log("Should delete item with index ", deletedId);
    let that = this;
    var variations = this.state.dataSource;
    var newVariations = [];
    for (var i = 0; i < variations.length; i++) {
      if (variations[i].id !== deletedId) {
        newVariations.push(variations[i]);
      } else {
        if (variations[i]["locallyAdded"] === undefined) {
          console.log(
            "that.state.variationsDeleted ",
            that.state.variationsDeleted
          );
          let varaitionsToDelete = that.state.variationsDeleted;
          varaitionsToDelete.push(variations[i]);
          that.setState({
            variationsDeleted: varaitionsToDelete
          });
        }
      }
    }
    this.setState({
      dataSource: newVariations
    });
    this.list = newVariations;
  }

  onLongClickPictureAction(index, id) {
    /* Alert.alert(
            'Снимка',
            'Какво действие желаете да извършите?',
            [
                {
                    text: 'Преместване отпред', onPress: () => {
                        this.onPictureToFront(index, id);
                    }
                },
                {
                    text: 'Изтриване', onPress: () => {
                        this.onPictureDelete(index, id);
                    }
                },
            ],
            {cancelable: true}
        ) */
  }

  onPictureDelete(index, id) {
    const c = this;
    this.setState({
      isDeleteDialogOpen: true,

      closeDeleteDialog: () => {
        console.log("closing dialog");
        c.setState({ isDeleteDialogOpen: false });
      },

      onDeleteAction: () => {
        if (this.state.imagesSource !== undefined) {
          var images = this.state.imagesSource;
          var newImages = [];
          for (var i = 0; i < images.length; i++) {
            if (i !== index) {
              newImages.push(images[i]);
            }
          }

          this.setState({
            imagesSource: newImages
          });
          this.imagesSource = newImages;
          c.setState({ isDeleteDialogOpen: false });
        }
      }
    });
  }

  onPictureToFront(index, id) {
    const c = this;
    this.setState({
      isReorderDialogOpen: true,

      closeReorderDialog: () => {
        console.log("closing dialog");
        c.setState({ isReorderDialogOpen: false });
      },

      onReorderAction: () => {
        console.log("----------------- ");

        console.log("images to front before ", this.state.imagesSource);

        console.log("index to front ", index);
        console.log("id to front ", id);

        var images = this.state.imagesSource;
        images[0] = images.splice(index, 1, images[0])[0];

        console.log("new images order ", images);

        this.setState({
          imagesSource: images
        });
        this.imagesSource = images;

        //this.state.imagesSource = images;
        c.setState({ isReorderDialogOpen: false });
      }
    });

    console.log("----------------- ");
  }

  onOpenCategories() {
    this.setState({
      isCategoriesChooserOpen: true
    });
    console.log("opening categories chooser");
    // const { navigate } = this.props.navigation;
    // navigate('CategoriesScreen', {selected_categories: this.state.selectedCategories});
  }

  onCategoriesDialogClose() {
    this.setState({
      isCategoriesChooserOpen: false
    });
    console.log(
      "closing categories chooser and data source set to ",
      this.originalCategories
    );

    var categoriesArrray = [];
    if (
      this.originalCategories !== undefined &&
      this.state.selectedCategories !== undefined
    ) {
      for (var i = 0; i < this.originalCategories.length; i++) {
        categoriesArrray.push({
          id: this.originalCategories[i].id,
          name: this.originalCategories[i].name,
          is_published: this.isSelected(
            this.originalCategories[i].id,
            this.state.selectedCategories
          )
        });
      }
    }

    this.setState({
      bulkdDataSource: categoriesArrray
    });
    // const { navigate } = this.props.navigation;
    // navigate('CategoriesScreen', {selected_categories: this.state.selectedCategories});
  }

  onDescriptionOpen() {
    this.setState({
      isDescriptionDialogOpen: true
    });
    console.log("opening description chooser");
    //const { navigate } = this.props.navigation;
    //  navigate('DescriptionScreen', {description: this.state.description});
  }

  onFilterSearch(term) {
    //    console.log("this ", this);
    //   console.log("this.originalProducts ", this.originalProducts);

    var categories = this.originalCategories;

    let newCategories = categories.filter(category =>
      category.name.toLowerCase().includes(term.toLowerCase())
    );

    this.setState({
      bulkdDataSource: newCategories
    });
  }

  async onBulkSave() {
    console.log("all products saved " + this.state.bulkdDataSource);
    var ids = [];
    var published = [];
    var onlySelectedIds = [];

    for (var i = 0; i < this.state.bulkdDataSource.length; i++) {
      ids.push(this.state.bulkdDataSource[i]["id"]);
      published.push(this.state.bulkdDataSource[i]["is_published"]);
      if (this.state.bulkdDataSource[i]["is_published"]) {
        onlySelectedIds.push(this.state.bulkdDataSource[i]["id"]);
      }

      console.log("id " + this.state.bulkdDataSource[i]["id"]);
      console.log(
        "is checked " + this.state.bulkdDataSource[i]["is_published"]
      );
      console.log("---------------");
    }

    this.originalCategories = this.state.bulkdDataSource;
    this.setState({
      originalCategories: this.state.bulkdDataSource
    });

    console.log("selectedCategories ", onlySelectedIds);

    this.setState({
      selectedCategories: onlySelectedIds
    });

    this.setState({
      isCategoriesChooserOpen: false
    });
  }

  onDescriptionSave() {
    this.setState({
      description: this.state.newDescription,
      isDescriptionDialogOpen: false
    });
    console.log("closing description chooser");
  }

  onDescriptionClose() {
    this.setState({
      isDescriptionDialogOpen: false,
      newDescription: this.state.description
    });
  }

  onGalleryClose() {
    this.setState({
      isGalleryOpen: false
    });
  }

  openDrawer() {
    console.log("old state ", this.state.isDrawerOpen);

    var isDrawerOpen = false;
    if (this.state.isDrawerOpen === false) {
      isDrawerOpen = true;
    } else {
      isDrawerOpen = false;
    }

    this.setState({
      isDrawerOpen: isDrawerOpen
    });

    console.log("toggle drawer; now should be ", this.state.isDrawerOpen);
  }

  onBackArrowClicked() {
    console.log("back");
    this.setState({ localRedirectBack: true });
  }

  onPushOrdersScreen() {
    console.log("going to orders screen");
    this.setState({
      localRedirectPushOrders: true
    });
  }

  render() {
    return (
      <div>
        <div>
          <Hidden smDown implementation="css">
            <div style={{ marginLeft: 210 }}>
              <NavBarComponent
                onClickAction={this.onSaveItem}
                onClickText={"Запази"}
                title={this.state.navBarTitle}
                onBackArrowClicked={this.onBackArrowClicked}
                isSideMenuNeeded={false}
                actionIcon={<SaveIcon />}
              />
            </div>
          </Hidden>

          <Hidden mdUp implementation="css">
            <div style={{ marginLeft: 0 }}>
              <NavBarComponent
                onClickAction={this.onSaveItem}
                onClickText={"Запази"}
                title={this.state.navBarTitle}
                onBackArrowClicked={this.onBackArrowClicked}
                isSideMenuNeeded={true}
                onSideDrawerOpen={this.openDrawer}
                actionIcon={<SaveIcon />}
              />
            </div>
          </Hidden>
        </div>

        <Hidden smDown implementation="css">
          <div style={{ marginLeft: 210, paddingLeft: 12, paddingRight: 12 }}>
            <CreateProductFormComponent
              context={this}
              onLongClickAction={this.onLongClickPictureAction}
              onVariantDelete={this.onVariantDelete}
              onPictureTaken={this.onImageModalOpen}
              onPictureDeleted={this.onPictureDelete}
              onImageChosenAction={this.onImageChosenAction}
              onPicturesReordered={this.onPictureToFront}
              onOpenCategories={this.onOpenCategories}
              onCategoriesDialogClose={this.onCategoriesDialogClose}
              onDescriptionOpen={this.onDescriptionOpen}
              categories={this.state.bulkDataSource}
              onSaveItem={this.onSaveItem}
              onFilterSearch={this.onFilterSearch}
              onBulkSave={this.onBulkSave}
              onBackArrowClicked={this.onBackArrowClicked}
              onDescriptionSave={this.onDescriptionSave}
              onDescriptionClose={this.onDescriptionClose}
              onPictureClicked={this.onImageModalOpen}
              onGalleryClose={this.onGalleryClose}
              onPushOrdersScreen={this.onPushOrdersScreen}
              onOpenQuestions={this.state.onOpenQuestions}
              isEditing={this.state.isEditing}
            />
          </div>
        </Hidden>

        <Hidden mdUp implementation="css">
          <div>
            <CreateProductFormComponent
              context={this}
              onLongClickAction={this.onLongClickPictureAction}
              onVariantDelete={this.onVariantDelete}
              onPictureTaken={this.onImageModalOpen}
              onPictureDeleted={this.onPictureDelete}
              onImageChosenAction={this.onImageChosenAction}
              onPicturesReordered={this.onPictureToFront}
              onOpenCategories={this.onOpenCategories}
              onCategoriesDialogClose={this.onCategoriesDialogClose}
              onDescriptionOpen={this.onDescriptionOpen}
              categories={this.state.bulkDataSource}
              onSaveItem={this.onSaveItem}
              onFilterSearch={this.onFilterSearch}
              onBulkSave={this.onBulkSave}
              onBackArrowClicked={this.onBackArrowClicked}
              onDescriptionSave={this.onDescriptionSave}
              onDescriptionClose={this.onDescriptionClose}
              onPictureClicked={this.onImageModalOpen}
              onGalleryClose={this.onGalleryClose}
              onPushOrdersScreen={this.onPushOrdersScreen}
              onOpenQuestions={this.state.onOpenQuestions}
              isEditing={this.state.isEditing}
            />
          </div>
        </Hidden>
      </div>
    );
  }
}
