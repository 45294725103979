import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Input from "@material-ui/core/Input/Input";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";

export default class VariationPriceInput extends React.Component {
  render() {
    const { context, itemToMap, classes, updatePrice } = this.props;

    return (
      <div>
        <FormHelperText htmlFor="price-input">Цена</FormHelperText>

        <Input
          id="price-input"
          value={
            itemToMap.price !== -1 && itemToMap.price !== "-1"
              ? itemToMap.price.toString()
              : ""
          }
          name={"Цена"}
          className={classes.inputs}
          validators={["minNumber:0.01", "maxNumber:999999"]}
          maxLength={6}
          onChange={event =>
            updatePrice(itemToMap, event.target.value, context.state, context)
          }
          endAdornment={
            <InputAdornment position="end">
              <p style={{ color: "gray" }}>лв</p>
            </InputAdornment>
          }
        />
      </div>
    );
  }
}
