import React from "react";
import Favicon from "react-favicon";
import { favIconLink } from "../Constants";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import BackRedirectComponent from "./redirects/BackRedirectComponent";
import LogOutRedirectComponent from "./redirects/LogOutRedirectComponent";
import PermamantDrawerComponent from "./PermamantDrawerComponent";
import Table from "@material-ui/core/es/Table/Table";
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import Grid from "@material-ui/core/es/Grid/Grid";
import PaymentsDropDownComponent from "./PaymentsDropDownComponent";
import PaymentsProducerDropDownComponent from "./PaymentsProducerDropDownComponent";
import LinearProgress from "@material-ui/core/es/LinearProgress/LinearProgress";
import Hidden from "@material-ui/core/es/Hidden/Hidden";

function payments(props) {
  const { classes, context, getData } = props;

  return (
    <div>
      <Favicon url={favIconLink} />

      <BackRedirectComponent context={context} />

      <LogOutRedirectComponent context={context} />

      <PermamantDrawerComponent context={context} />

      <main className={classes.layout}>
        <PaymentsProducerDropDownComponent
          context={context}
          data={context.state.producersAllData}
          value={context.state.selectedProducer}
          getData={getData}
        />
        {!context.state.isLoaded && <LinearProgress />}

        {context.state.isLoaded && !context.state.isEmptyDialogOpen ? (
          context.state.paymentsResult.payments.length > 0 ? (
            <Paper className={classes.paper}>
              <PaymentsDropDownComponent context={context} getData={getData} />
              <Grid container spacing={24}>
                <Grid item xs>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          className={classes.tableCell}
                          padding="checkbox"
                        >
                          Оборот
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          padding="checkbox"
                          align="right"
                        >
                          {context.state.paymentsResult.total}лв
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.tableCell}
                          padding="checkbox"
                        >
                          Процент
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          padding="checkbox"
                          align="right"
                        >
                          {context.state.paymentsResult.percent}%
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.tableCell}
                          padding="checkbox"
                        >
                          За изпащане
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          padding="checkbox"
                          align="right"
                        >
                          {context.state.paymentsResult.to_pay}лв
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          className={classes.tableCell}
                          padding="checkbox"
                        >
                          Изплатено
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          padding="checkbox"
                          align="right"
                        >
                          {context.state.paymentsResult.paid}лв
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.tableCell}
                          padding="checkbox"
                        >
                          Остатък за изпащане
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          padding="checkbox"
                          align="right"
                        >
                          {context.state.paymentsResult.remaining}лв
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
              <br />
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell} padding="checkbox">
                      Дата
                    </TableCell>
                    <TableCell className={classes.tableCell} padding="checkbox">
                      Статус
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.tableAmount}
                      padding="checkbox"
                    >
                      Сума
                    </TableCell>
                    <Hidden smDown>
                      <TableCell
                        className={classes.tableCellWide}
                        padding="checkbox"
                      >
                        Детайли
                      </TableCell>
                    </Hidden>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {context.state.paymentsResult.payments &&
                    context.state.paymentsResult.payments.map(row => (
                      <TableRow
                        key={row.id}
                        className={
                          row.credit_debit === "credit" ? classes.credit : ""
                        }
                      >
                        <TableCell
                          className={classes.tableCell}
                          padding="checkbox"
                        >
                          {new Date(
                            row.created_at.replace(/ /g, "T")
                          ).toLocaleDateString("bg-BG")}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          padding="checkbox"
                        >
                          {row.credit_debit === "debit"
                            ? "Доставка"
                            : "Плащане"}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.tableAmount}
                          padding="checkbox"
                        >
                          {row.amount}лв.
                        </TableCell>
                        <Hidden smDown>
                          <TableCell
                            className={classes.tableCellWide}
                            padding="checkbox"
                          >
                            {row.details}
                          </TableCell>
                        </Hidden>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>
          ) : (
            <div>Няма плащания</div>
          )
        ) : (
          <div />
        )}
      </main>
    </div>
  );
}

const styles = theme => ({
  layout: {
    width: "auto",
    display: "block" // Fix IE11 issue.
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    maxWidth: 600,
    minWidth: 250,
    marginRight: 20,
    marginTop: 20,
    marginBottom: 20
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    maxWidth: 600,
    minWidth: 250
  },
  margin: {
    margin: theme.spacing.unit
  },
  textField: {
    flexBasis: 200
  },
  table: {},
  tableCell: {
    width: "20%"
  },
  tableAmount: {
    width: "15%"
  },
  tableCellWide: {
    width: "45%"
  },
  credit: {
    backgroundColor: "#f1f8e9"
  }
});

export default withStyles(styles)(payments);
