import React from "react";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { store } from "../store";
import setUser from "./redux/actions/setUser";
import StateSaverUtil from "../utils/StateSaverUtil";

export default class ProducerDropDownComponent extends React.Component {
  getProducerId(value, allProducers) {
    for (var i = 0; i < allProducers.length; i++) {
      if (allProducers[i].name === value) {
        return allProducers[i].id;
      }
    }
    return 1;
  }

  getProducerPercent(value, allProducers) {
    for (var i = 0; i < allProducers.length; i++) {
      if (allProducers[i].name === value) {
        return allProducers[i].percent;
      }
    }
    return 1;
  }

  getProducerIndex(value, allProducers) {
    for (var i = 0; i < allProducers.length; i++) {
      if (allProducers[i].name === value) {
        return i;
      }
    }
    return 1;
  }

  render() {
    const { context } = this.props;

    let allProducers = undefined;
    if (
      context.state.producersAllData === undefined ||
      typeof context.state.producersAllData !== "object"
    ) {
      allProducers = (
        <MenuItem key={1} value={this.props.data[0]} id={1}>
          {this.props.data[0].name}
        </MenuItem>
      );
    } else {
      try {
        allProducers = context.state.producersAllData.map((s, i) => {
          let producerName = s.name;

          if (producerName !== undefined) {
            return (
              <MenuItem key={s.id} value={producerName} id={s.id}>
                {producerName}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem key={1} value={"producer"} id={1}>
                Производител1
              </MenuItem>
            );
          }
        });

        if (allProducers.length === 0) {
          return (
            <MenuItem key={1} value={"producer"} id={1}>
              Производител2
            </MenuItem>
          );
        }
      } catch (e) {
        allProducers = (
          <MenuItem key={1} value={"producer"} id={1}>
            Производител3
          </MenuItem>
        );
      }
    }

    return (
      <FormControl style={{ width: 600, marginTop: 20, marginBottom: 15 }}>
        <InputLabel htmlFor="age-required">Производител</InputLabel>
        <Select
          value={
            context.state.selectedProducer === undefined
              ? context.state.producersAllData === undefined
                ? "Producer"
                : context.state.producersAllData[0].name
              : this.props.value == null
              ? "Prod"
              : context.state.producersAllData[this.props.value].name
          }
          // style={{width: 260}}
          displayEmpty
          onChange={event => {
            console.log(
              "event",
              this.getProducerIndex(
                event.target.value,
                context.state.producersAllData
              )
            );
            if (context.state.selectedProducer !== undefined) {
            }
            const user = {
              selectedProducer: this.getProducerIndex(
                event.target.value,
                context.state.producersAllData
              ),
              producerId: this.getProducerId(
                event.target.value,
                context.state.producersAllData
              ),
              producerPercent: this.getProducerPercent(
                  event.target.value,
                  context.state.producersAllData
              ),
              selectedProducerName: event.target.value
            };

            store.dispatch(setUser(user));
            context.setState(user, () => {
              StateSaverUtil.save(context, context.propsToRestore);
            });

            let url = window.location.href;
            let lastPart = url.substr(url.lastIndexOf("/") + 1);

            if (lastPart === "products") {
              this.props.loadProducts(
                this.getProducerId(
                  event.target.value,
                  context.state.producersAllData
                )
              );
            } else {
              this.props.getData();
            }
          }}
          name="producer"
          inputProps={{
            name: "selectedProducer"
          }}
        >
          {allProducers}
        </Select>
      </FormControl>
    );
  }
}
