import React from "react";
import Favicon from "react-favicon";
import { favIconLink } from "../Constants";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead/TableHead";
import Table from "@material-ui/core/Table/Table";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableBody from "@material-ui/core/TableBody/TableBody";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Typography from "@material-ui/core/Typography/Typography";
import classNames from "classnames";
import { lighten } from "@material-ui/core/es/styles/colorManipulator";
import BackRedirectComponent from "./redirects/BackRedirectComponent";
import LogOutRedirectComponent from "./redirects/LogOutRedirectComponent";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import PermamantDrawerComponent from "./PermamantDrawerComponent";

let EnhancedTableToolbar = props => {
  const { numSelected, classes } = props;

  return (
    <Toolbar
      style={{ marginBottom: -20 }}
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} избрани
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Поръчки
          </Typography>
        )}
      </div>
    </Toolbar>
  );
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  }
});

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

function orders(props) {
  const { classes, context, onPushOrdersScreen } = props;

  const CustomTableCell = withStyles(theme => ({
    head: {
      backgroundColor: "#eaeaea",
      color: theme.palette.common.black
    },
    body: {
      fontSize: 14
    }
  }))(TableCell);

  console.log(
    "context.state.ordersData on component ",
    context.state.ordersData
  );

  return (
    <div>
      <Favicon url={favIconLink} />

      <BackRedirectComponent
        context={context}
        whereTo={"orders/dates"}
        state={{
            producerId:
                context.state.producerId !== undefined
                    ? context.state.producerId
                    : null,
            producerPercent:
                context.state.producerPercent !== undefined
                    ? context.state.producerPercent
                    : null,
            producersAllData: context.state.producersAllData,
            selectedProducer:
                context.state !== null ? context.state.selectedProducer : null,
            producersImages: context.state.producersImages,
            userName: context.state.userName,
            userEmail: context.state.userEmail
        }}
      />

      <LogOutRedirectComponent context={context} />

      <PermamantDrawerComponent
        context={context}
        onPushOrdersScreen={onPushOrdersScreen}
      />

      <main className={classes.layout}>
        <Paper className={classes.root}>
          <EnhancedTableToolbar
            numSelected={context.state.selectedToDelete.length}
          />

          {context.state.ordersData !== undefined &&
          context.state.ordersData.length > 0 ? (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {context.state.locationDate !== null &&
                  context.state.locationDate !== undefined ? (
                    <TableCell colSpan={5}>
                      <b>
                        Дата на доставка:{" "}
                        {new Date(
                          context.state.locationDate.replace(/ /g, "T")
                        ).toLocaleDateString("bg-BG", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric"
                        })}{" "}
                        от{" "}
                        {context.state.delivery_start !== null
                          ? context.state.delivery_start.padStart(2, "0")
                          : ""}
                        :00 до{" "}
                        {context.state.delivery_end !== null
                          ? context.state.delivery_end.padStart(2, "0")
                          : ""}
                        :00
                      </b>
                    </TableCell>
                  ) : (
                    <LinearProgress />
                  )}
                </TableRow>

                <TableRow>
                  <TableCell colSpan={5}>
                    <p>Локация:</p>
                    <p>{context.state.locationName}</p>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={2}>
                    <p align="right" style={{ color: "black" }}>
                      <b>Крайна сума: {context.state.totalToPay} лв</b>
                    </p>
                  </TableCell>
                </TableRow>

                {context.state.distinctProducts.map(row => {
                  console.log(
                    "product to display ",
                    context.state.productsData[row][0]
                  );

                  return (
                    <div>
                      <TableRow>
                        <CustomTableCell colSpan={5}>
                          <b>{row}</b>

                          <div style={{ marginTop: 6 }}>
                            <span style={{ color: "gray" }}>
                              Общо количество:{" "}
                              {context.state.sameProductPricesAndQuanitities[
                                row
                              ] !== undefined &&
                                context.state.sameProductPricesAndQuanitities[
                                  row
                                ].totalToDeliver}{" "}
                              {context.state.productsData[row][0] !== undefined
                                ? context.state.productsData[row][0].unit
                                : ""}
                            </span>{" "}
                            <span style={{ marginLeft: 10, color: "gray" }}>
                              Общ брой:{" "}
                              {context.state.sameProductPricesAndQuanitities[
                                row
                              ] !== undefined &&
                                context.state.sameProductPricesAndQuanitities[
                                  row
                                ].quantity}{" "}
                              бр.
                            </span>
                          </div>
                        </CustomTableCell>
                      </TableRow>

                      <TableRow>
                        <Table className={classes.table}>
                          <TableRow>
                            <TableCell>Клиент</TableCell>
                            <TableCell align={"right"}>Кол.</TableCell>
                            <TableCell align={"right"}>Брой</TableCell>
                            <TableCell align={"right"}>Ед. цена</TableCell>
                            <TableCell align={"right"}>Цена</TableCell>
                          </TableRow>

                          <TableBody>
                            {context.state.productsData[row] !== undefined &&
                              context.state.productsData[row].map(purchase => {

                                return (
                                  <TableRow key={purchase.id}>
                                    <TableCell component="th" scope="row">
                                      {purchase.username}
                                    </TableCell>
                                    <TableCell align={"right"}>
                                      {purchase.measurement} {purchase.unit}
                                    </TableCell>
                                    <TableCell align={"right"}>
                                      {purchase.quantity} бр.
                                    </TableCell>
                                    <TableCell align={"right"}>
                                      {Number(purchase.price).toFixed(2)} лв.
                                    </TableCell>
                                    <TableCell align={"right"}>
                                      {Number(purchase.total).toFixed(2)} лв.
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableRow>
                    </div>
                  );
                })}

                <TableRow>
                  <TableCell colSpan={5}>
                    <p align="right" style={{ color: "black" }}>
                      <b>Такса ({context.state.producerPercent}%): {context.state.tax} лв.</b>
                    </p>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={5}>
                    <p align="right" style={{ color: "black" }}>
                      <b>Крайна сума: {context.state.totalToPay} лв.</b>
                    </p>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          ) : (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <LinearProgress />
            </div>
          )}
        </Paper>
      </main>
    </div>
  );
}

const styles = theme => ({
  layout: {
    width: "auto",
    display: "block" // Fix IE11 issue.
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    maxWidth: 600,
    minWidth: 250,
    marginRight: 20,
    marginTop: 20
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    maxWidth: 600,
    minWidth: 250
  },
  margin: {
    margin: theme.spacing.unit
  },
  textField: {
    flexBasis: 200
  },
  table: {
    minWidth: 250
  }
});

export default withStyles(styles)(orders);
