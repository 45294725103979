import { accessToken, refreshToken } from "../Constants";
import { delete_cookie } from "sfcookies";
import { store } from "../store";
import setUser from "../components/redux/actions/setUser";
import { initialState } from "../components/redux/reducers/usersReducer";

export default class LogOut {
  static async logOut(context) {
    console.log("reseting state");
    /*  context.setState({
                isLoading: true,
                isSideMenuOpen: false,
                dataSource: [],
                bulkdDataSource: []
            }
        ); */

    console.log("removing access token");
    await this.removeAccessToken();

    console.log("removing refresh token");
    await this.removeRefreshToken();

    console.log("going to log out ", context);

    context.setState(
      {
        localRedirectLogOut: true
      },
      () => {
        console.log(
          "localRedirectLogOut is now ",
          context.state.localRedirectLogOut
        );
      }
    );

    store.dispatch(setUser(initialState));
  }

  static async removeAccessToken() {
    delete_cookie(accessToken);
    console.log("access token removed");
  }

  static async removeRefreshToken() {
    delete_cookie(refreshToken);

    console.log("refresh token removed");
  }
}
